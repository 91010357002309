<template>
  <div id="app">
<!--      <router-view/>-->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive">
        <!-- 这里是会被缓存的视图组件，比如 page1,page2 -->
      </router-view>
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive">
      <!-- 这里是不被缓存的视图组件，比如 page3 -->
    </router-view>
  </div>
</template>
<script>


export default{
  async created() {
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))));
    }

    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state));
    });

    // 获取系统信息并本地保存
    var u = navigator.userAgent;
    // eslint-disable-next-line no-unused-vars
    var app = navigator.appVersion;
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //Android终端
    var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
    let aType;
    if (isAndroid) {
      // 这个是安卓操作系统
      aType = 'android'
    }
    if (isIOS) {
      // 这个是ios操作系统
      aType = 'ios'
    }
    //将获取道德aType保存在本地中
    localStorage.setItem('helpCenterType',aType)
  },
}


</script>
<style lang="scss">
.el-scrollbar {
  > .el-scrollbar__bar {
    opacity: 1 !important;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0 auto;
  //overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none; /* IE 10+ */
}
#app::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
#app .container {
  width: 100%;
  height: 100%;
  position: relative;
  font-size: 14px;
}
//body html{
//  height: 100%;
//  width: 100%;
//  overflow-x: hidden;
//}
</style>
