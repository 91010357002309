<template>
  <div class="box">
    <div class="box-header">
      <el-select v-model="selectValue" style="width: 100%" placeholder="请选择">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </div>

    <div class="box-tags">
      <div class="box-tags-div">
        <span style="float: left;background-color: #EA9BFE;height: 100%;width: 15px;margin-right: 5px"></span>
        <span>
          <div style="color: #B9B9B9;margin-top: 3px;font-size: 11px;">
            观看总人次
          </div>
          <div style="margin-top: 10px;font-size: 21px">
            139843
          </div>
        </span>
      </div>
      <div class="box-tags-div">
        <span style="float: left;background-color: #F97150;height: 100%;width: 15px;margin-right: 5px"></span>
        <span>
          <div style="color: #B9B9B9;margin-top: 3px;font-size: 11px;">
            观看总人数
          </div>
          <div style="margin-top: 10px;font-size: 21px">
            139843
          </div>
        </span>
      </div>
      <div class="box-tags-div">
        <span style="float: left;background-color: #28ADDD;height: 100%;width: 15px;margin-right: 5px"></span>
        <span>
          <div style="color: #B9B9B9;margin-top: 3px;font-size: 11px;">
            峰值人数
          </div>
          <div style="margin-top: 10px;font-size: 21px">
            139843
          </div>
        </span>
      </div>
      <div class="box-tags-div">
        <span style="float: left;background-color: #27DD8A;height: 100%;width: 15px;margin-right: 5px"></span>
        <span>
          <div style="color: #B9B9B9;margin-top: 3px;font-size: 11px;">
            直播总时长(分钟)
          </div>
          <div style="margin-top: 10px;font-size: 21px">
            139843
          </div>
        </span>
      </div>
      <div class="box-tags-div">
        <span style="float: left;background-color: #FEC758;height: 100%;width: 15px;margin-right: 5px"></span>
        <span>
          <div style="color: #B9B9B9;margin-top: 3px;font-size: 11px;">
           观看总时长(分钟)
          </div>
          <div style="margin-top: 10px;font-size: 21px">
            139843
          </div>
        </span>
      </div>
      <div class="box-tags-div">
        <span style="float: left;background-color: #28DEC2;height: 100%;width: 15px;margin-right: 5px"></span>
        <span>
          <div style="color: #B9B9B9;margin-top: 3px;font-size: 11px;">
            观看总人次
          </div>
          <div style="margin-top: 10px;font-size: 21px">
            139843
          </div>
        </span>
      </div>
    </div>

<!--    各省份观看人数-->
    <div class="box-echartsOne">
      <div class="box-echartsOne-header">
        <div style="color: #45A5E6">各省份观看人数</div>
        <el-radio-group v-model="echartsOneRadio" size="mini">
          <el-radio-button label="图"></el-radio-button>
          <el-radio-button label="表"></el-radio-button>
        </el-radio-group>
      </div>

<!--      echarts-->
      <div class="box-echartsOne-echarts" style="margin-top:10px;width: 8.2rem;height: 8rem;" id="box-echartsOne-echarts" v-show="echartsOneRadio === '图'">

      </div>
<!--      table-->
      <div class="box-echartsOne-table" v-show="echartsOneRadio === '表'">
        <el-table border :data="echartsOneTableData" :header-cell-style="{ backgroundColor: '#5B9AD5',color:'white',}" :row-class-name="tableRowClassName" style="width: 100%;margin-top: 10px">
          <el-table-column
              prop="date"
              label="序号"
              align="center"
              width="50"
          >
          </el-table-column>
          <el-table-column
              prop="date"
              label="省份"
              align="center"
          >
          </el-table-column>
          <el-table-column
              prop="date"
              label="人数"
              align="center"
          >
          </el-table-column>
          <el-table-column
              prop="date"
              label="占比"
              align="center"
          >
          </el-table-column>
        </el-table>
        <p style="padding: 0;margin: 8px 0">注:主要分布地区总站比: 62%</p>
        <div style="height: 0.5rem">
          <el-pagination
              style="float: right"
              layout="prev, pager, next"
              :total="echartsOneTotal"
              @current-change="echartsOneTableChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>

<!--    各城市观看人数-->
    <div class="box-echartsOne">
      <div class="box-echartsOne-header">
        <div style="color: #45A5E6">各城市观看人数</div>
        <el-radio-group v-model="echartsTwoRadio" size="mini">
          <el-radio-button label="图"></el-radio-button>
          <el-radio-button label="表"></el-radio-button>
        </el-radio-group>
      </div>
      <!--      echarts-->
<!--      柱状图-->
      <div class="box-echartsOne-echarts" style="margin-top:10px;width: 8.2rem;height: 8rem;" id="box-echartsTwo-echarts" v-show="echartsTwoRadio === '图'">

      </div>
<!--      饼状图-->
      <div class="box-echartsOne-echarts" style="margin-top:10px;width: 8.2rem;height: 8rem;" id="box-echartsTwo-echarts-bing" v-show="echartsTwoRadio === '图'">

      </div>
<!--      table-->
      <div class="box-echartsOne-table" v-show="echartsTwoRadio === '表'">
        <el-table border :data="echartsTwoTableData" :header-cell-style="{ backgroundColor: '#5B9AD5',color:'white',}" :row-class-name="tableRowClassName" style="width: 100%;margin-top: 10px">

        </el-table>
      </div>

    </div>

      <!--    各等级医院观看人数-->
      <div class="box-echartsOne">
        <div class="box-echartsOne-header">
          <div style="color: #45A5E6">各等级医院观看人数</div>
          <el-radio-group v-model="echartsThreeRadio" size="mini">
            <el-radio-button label="图"></el-radio-button>
            <el-radio-button label="表"></el-radio-button>
          </el-radio-group>
        </div>
        <!--      echarts-->
        <!--      柱状图-->
        <div class="box-echartsOne-echarts" style="margin-top:10px;width: 8.2rem;height: 8rem;" id="box-echartsThree-echarts" v-show="echartsThreeRadio === '图'">

        </div>

      </div>

    <!--    各等级医院观看人数-->
    <div class="box-echartsOne">
      <div class="box-echartsOne-header">
        <div style="color: #45A5E6">各科室观看人数分布</div>
        <el-radio-group v-model="echartsFourRadio" size="mini">
          <el-radio-button label="图"></el-radio-button>
          <el-radio-button label="表"></el-radio-button>
        </el-radio-group>
      </div>

      <!--      echarts-->
      <!--      柱状图-->
      <div class="box-echartsOne-echarts" style="margin-top:10px;width: 8.2rem;height: 8rem;" id="box-echartsFour-echarts" v-show="echartsFourRadio === '图'">

      </div>
    </div>

    <!--    各等级医院观看人数-->
    <div class="box-echartsOne">
      <div class="box-echartsOne-header">
        <div style="color: #45A5E6">各科室观看人数分布</div>
        <el-radio-group v-model="echartsFiveRadio" size="mini">
          <el-radio-button label="图"></el-radio-button>
          <el-radio-button label="表"></el-radio-button>
        </el-radio-group>
      </div>

      <!--      echarts-->
      <!--      柱状图-->
      <div class="box-echartsOne-echarts" style="margin-top:10px;width: 8.2rem;height: 8rem;" id="box-echartsFive-echarts" v-show="echartsFiveRadio === '图'">

      </div>
    </div>
  </div>
</template>

<script>
import chinaJson from "../../json/china.json";

export default {
  name: "DataStatistics",
  data() {
    return {
      options: [{
        value: '选项1',
        label: '辉昂金',
      }],
      selectValue: '',
      echartsOneRadio: '图',
      echartsOneTableData: [
        {
          date: '111'
        },
        {
          date: '111'
        },
        {
          date: '111'
        },
        {
          date: '111'
        }
      ],
      echartsOneTotal: 0,
      echartsTwoRadio: '图',
      echartsTwoTableData: [],
      echartsThreeRadio: '图',
      echartsFourRadio: '图',
      echartsFiveRadio: '图',
    }// returnData
  },
  created() {

  },
  mounted() {
      setTimeout(() => {
        this.initEchartsOneMap() // 加载各省份观看人数中国地图
        this.initEchartsTwo() // 加载各城市观看人数-柱状图
        this.initEchartsTwoBing() // 加载各城市观看人数-饼状图
        this.initEchartsThree() // 各等级医院观看人数分布
        this.initEchartsFour() // 各科室观看人数分布
        this.initEchartsFive() // 各科室观看人数分布
      },200)
  },
  methods: {
    // 表格隔行变色
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'rowOne' //这是类名
      } else  {
        return 'rowTwo'
      }
    },
    //echartsOne分页切换
    echartsOneTableChange (a) {
      console.log(a)
    },
    // 初始化echartsOne 中国地图
    initEchartsOneMap () {
      let drawLine = this.$echarts.init(document.getElementById('box-echartsOne-echarts'))
      this.$echarts.registerMap('china',chinaJson)
      let option = {
        tooltip: {
          formatter:function(params){
            return params.seriesName+'<br />'+params.name+'：'+params.value
          }//数据格式化
        },
        visualMap: {
          min: 0,
          max: 1500,
          left: 'left',
          top: 'bottom',
          text: ['H','L'],

          inRange: {
            color: ['#e2fafc', '#0c73dc']
          },
          show:true
        },
        geo: {
          map: 'china',
          roam: true,
          zoom:1.23,
          label: {
            // normal: {
              show: false,
              fontSize:'8',
              color: 'rgba(0,0,0,0.7)'
            // }
          },
          itemStyle: {
            // normal:{
              borderColor: 'rgba(0, 0, 0, 0.2)',
              areaColor: '#fff',
            // },

          },
          emphasis:{
            itemStyle: {
              areaColor: '#F3B329',
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }

          }
        },
        series : [
          {
            name: '访问人数',
            type: 'map',
            geoIndex: 0,
            data:this.AddProTu,
          }
        ]
      };
      drawLine.setOption(option);
    },
    // 各城市访问人数柱状图
    initEchartsTwo () {
      let drawLine = this.$echarts.init(document.getElementById('box-echartsTwo-echarts'))
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
          }
        ],
        series: [
          {
            name: 'Direct',
            type: 'bar',
            barWidth: '60%',
            data: [10, 52, 200, 334, 390, 330, 220],
            itemStyle: {
              color: '#44A5E6'
            }
          }
        ]
      };
      drawLine.setOption(option)
    },
    //各城市访问人数饼状图
    initEchartsTwoBing () {
      let drawLine = this.$echarts.init(document.getElementById('box-echartsTwo-echarts-bing'))
      let option = {
        // title: {
        //   text: 'Referer of a Website',
        //   subtext: 'Fake Data',
        //   left: 'center'
        // },
        tooltip: {
          trigger: 'item'
        },
        // legend: {
        //   orient: 'vertical',
        //   left: 'left'
        // },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1048, name: 'Search Engine' },
              { value: 735, name: 'Direct' },
              { value: 580, name: 'Email' },
              { value: 484, name: 'Union Ads' },
              { value: 300, name: 'Video Ads' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      drawLine.setOption(option)
    },
    // 各等级医院观看人数分布
    initEchartsThree () {
      let drawLine = this.$echarts.init(document.getElementById('box-echartsThree-echarts'))
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
          }
        ],
        series: [
          {
            name: 'Direct',
            type: 'bar',
            barWidth: '60%',
            data: [10, 52, 200, 334, 390, 330, 220],
            itemStyle: {
              color: '#44A5E6'
            }
          }
        ]
      };
      drawLine.setOption(option)
    },
    // 各科室观看人数分布
    initEchartsFour () {
      let drawLine = this.$echarts.init(document.getElementById('box-echartsFour-echarts'))
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
          }
        ],
        series: [
          {
            name: 'Direct',
            type: 'bar',
            barWidth: '60%',
            data: [10, 52, 200, 334, 390, 330, 220],
            itemStyle: {
              color: '#44A5E6'
            }
          }
        ]
      };
      drawLine.setOption(option)
    },
    // 各科室观看人数分布
    initEchartsFive () {
      let drawLine = this.$echarts.init(document.getElementById('box-echartsFive-echarts'))
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
          }
        ],
        series: [
          {
            name: 'Direct',
            type: 'bar',
            barWidth: '60%',
            data: [10, 52, 200, 334, 390, 330, 220],
            itemStyle: {
              color: '#44A5E6'
            }
          }
        ]
      };
      drawLine.setOption(option)
    },
  }
}
</script>

<style lang="scss">
.rowOne {
  background-color: #BDD7EE !important;
}
.rowTwo {
  background-color: #DEECF7 !important;
}
</style>

<style lang="scss" scoped>
.box {
  width: 100%;
  //overflow: scroll;
  .box-header {
    padding: 10px;
  }
  .box-tags {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    .box-tags-div {
      margin: 10px 0px 10px 10px;
      width: 117px;
      height: 60px;
      border-radius: 6px;
      overflow: hidden;
      box-shadow: 2px 5px 7px rgb(0 0 0 / 15%);
    }
  }

}
.box-echartsOne {
  width: 8.2rem;
  height: auto;
  box-shadow: 0 0 23px rgb(10 132 255 / 15%);
  margin: 18px auto;
  padding: 16px;
  border-radius: 10px;

  .box-echartsOne-header {
    display: flex;
    justify-content: space-between;
  }
}
</style>