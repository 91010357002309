import {browseOperationH,browseOperationWX} from "@/service/document_api";

export function ListPushPro(list){
    let sum = 0;
    for(let i = 0;i < list.length;i++){
        sum = sum + list[i].value
    }
    return sum;
}
export function ListPushQiTa(list){
    let sum = 0;
    for(let i = 0;i < list.length;i++){
        sum = sum + list[i].count
    }
    return sum;
}
//记录用户操作
export async function recordOperation(id,type,location,os,member_id,version,channel){
    let data = {
        "contentId":id,
        "type":type,
        "location":location,
        "channel":channel,
        "os":os,
        "byId":member_id,
        "version":version,
    }
    return  await browseOperationH(data);
}
//文献指南记录用户操作
export async function recordOperationWX(id,type,location,os,member_id,version,channel){
    let data = {
        "contentId":id,
        "type":type,
        "location":location,
        "channel":channel,
        "os":os,
        "byId":member_id,
        "version":version,
    }
    return  await browseOperationWX(data);
}
