<template>
  <div>
<!--    <van-overlay :show="show" style="background-color: #fff"/>-->
    <van-overlay :show="loadedRatio!==1" style="background-color: #45A5E6" >
      <van-loading type="spinner" color="#fff" class="wrapper" />
    </van-overlay>
    <div class="pdfDiv" >
      <pdf
          class="pdf_main"
          :src="document.url"
          lazy
          @progress="loadedRatio = $event"
          @num-pages="pageCount = $event"
          :page="currentPage"
      ></pdf>
      <div class="buttonPdf">
<!--        <button @click="Up()">上一页</button>-->
<!--        <button @click="Down()">下一页</button>-->
        <van-button @click="Up()" type="info">
          上一页
        </van-button>
        <van-button @click="Down()" type="info">
          下一页
        </van-button>
      </div>

    </div>
  </div>
</template>

<script>

import pdf from 'vue-pdf'
import {getDocumentPdf} from "../service/api";

export default {
  components: {
    pdf
  },
  //mounted中async和await将加载设置为同步
  async mounted(){
    //获取URL地址中id的值
    const id = this.$route.query.pdf_id;
    console.log('id',id)
    //调取接口，通过id获取单个文献详情
    const  pdf = await getDocumentPdf(id)
    //将单个文献详情赋值
    this.document = pdf.data
    console.log(pdf.data)
  },
  data() {
    return {
      show:true,
      //当前页数
      currentPage: 1,
      //总页数
      pageCount: 0,
      //当前页面的加载进度，范围是0-1 ，等于1的时候代表当前页已经完全加载完成了
      loadedRatio:0,
      //获取到的文献详情
      document:[],
    }
  },
  methods:{
    //上一页
    Up(){
      if(this.currentPage === 1){
        this.currentPage = 1
      }else {
        this.currentPage--
      }
    },
    //下一页
    Down(){
      if(this.currentPage === this.pageCount){

        this.currentPage = this.pageCount
      }else {
        this.currentPage++
      }
    },
    // xiazai(){
    //   //window.open打开pdf
    //   //document是data中定义的数组
    //   window.open(this.document.url)
    // }
  },
}

</script>

<style lang="scss">
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
  .pdfDiv{
    position: fixed;
    bottom: 0;
    width: 100%;
    .pdf_main{
      width: 100%;
      height: auto;
      background: #000;
    }
  }
  .buttonPdf{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
</style>
