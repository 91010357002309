<template>
  <div class="watchBroadcast">
    <div class="watchBroadcast-top">
      <van-overlay class="overZhe" :show="show" @click="show" :custom-style="{backgroundColor: '#3278cd' }">
        <div class="wrapper">
          <van-loading color="#fff" />
        </div>
      </van-overlay>
    </div>
    <!--    观看直播总览-->
    <div class="watchBroadcast-all">
      <div class="watchBroadcast-one">
        <div class="watch-one-font">观看直播总览</div>
        <div class="watch-one-rad">
          <div class="watch-one-rad-one">{{VisitWatchSumPen.online_count}}</div>
          <div class="watch-one-rad-two">{{((watchSumShi.count)/3600).toFixed(0)}}</div>
          <div class="watch-one-rad-three">{{watchSumPon.count}}</div>
          <!--        类型转换，取整数部分-->
          <div class="watch-one-rad-four">{{((watchSumShi.count/watchSumPon.count)/60).toFixed(0)}}</div>
          <div class="watch-one-rad-five">{{maxPon.count}}</div>
        </div>
        <div class="watch-one-bottom">
          <div class="watch-one-bottom-one">访问总人次</div>
          <div class="watch-one-bottom-one">观看总时长(h)</div>
          <div class="watch-one-bottom-one">观看总人数</div>
          <div class="watch-one-bottom-one">平均观看时长(m)</div>
          <div class="watch-one-bottom-one">峰值人数</div>
        </div>
      </div>
      <!--    观看人数统计省份-->
      <div class="watchBroadcast-two">
        <div class="two-top">
          <div class="watch-two-font">观看人数统计(省份)</div>
          <div class="watch-two-btn">
            <select v-model="list" style="width: 70px;height: 25px;border: #0a84ff solid 1px;color: #888888">
              <option>5</option>
              <option>10</option>
            </select>
          </div>
        </div>
        <!--        中国地图-->
        <div id="watchShewing" style="width: 100%;height: 300px"></div>
        <!--      表格-->
        <el-table
            :data="AddPro"
            stripe
            style="width: 95%;border-radius: 20px;margin: 0 auto"
        >
          <el-table-column
              prop="name"
              label="省份"
              align="center"
          ></el-table-column>
          <el-table-column
              prop="value"
              label="人数"
              align="center"
          ></el-table-column>
          <el-table-column
              label="占比"
              align="center"
          >
            <template slot-scope="scope">
              <div>{{Math.round((scope.row.value/watchSumPon.count)*100) + '%'}}</div>
            </template>
          </el-table-column>
        </el-table>

      </div>
      <!--    观看人数统计医院-->
      <div class="watchBroadcast-three">
        <div class="three-top">
          <div class="watch-three-font">观看人数统计(医院)</div>
          <div class="watch-three-btn">
            <select v-model="list3" style="width: 70px;height: 25px;border: #0a84ff solid 1px;color: #888888">
              <option>5</option>
              <option>10</option>
            </select>
          </div>
        </div>
        <!--      折线图-->
        <div id="watchYi" style="width: 100%;height: 300px"></div>
        <!--      表格-->
        <el-table
            :data="AddDro"
            stripe
            style="width: 95%;border-radius: 20px;margin: 0 auto"
        >
          <el-table-column
              prop="value"
              label="医院"
              align="center"
          ></el-table-column>
          <el-table-column
              prop="count"
              label="人数"
              align="center"
          ></el-table-column>
          <el-table-column
              label="占比"
              align="center"
          >
            <template slot-scope="scope">
              <div>{{Math.round((scope.row.count/watchSumPon.count)*100) + '%'}}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--    观看人数统计科室-->
      <div class="watchBroadcast-four">
        <div class="four-top">
          <div class="watch-four-font">观看人数统计(科室)</div>
          <div class="watch-four-btn">
            <select v-model="list4" style="width: 70px;height: 25px;border: #0a84ff solid 1px;color: #888888">
              <option>5</option>
              <option>10</option>
            </select>
          </div>
        </div>
        <!--      折线图-->
        <div id="watchKe" style="width: 100%;height: 300px"></div>
        <!--   表格   -->
        <el-table
            :data="AddKe"
            stripe
            style="width: 95%;border-radius: 20px;margin: 0 auto"
        >
          <el-table-column
              prop="value"
              label="科室"
              align="center"
          >
            <template slot-scope="scope">
              <div>{{scope.row.value===""?"暂无":scope.row.value}}</div>
            </template>
          </el-table-column>
          <el-table-column
              prop="count"
              label="人数"
              align="center"
          ></el-table-column>
          <el-table-column
              label="占比"
              align="center"
          >
            <template slot-scope="scope">
              <div>{{Math.round((scope.row.count/watchSumPon.count)*100) + '%'}}</div>
            </template>
          </el-table-column>
        </el-table>
      </div >
      <!--    观看人数统计身份-->
      <div class="watchBroadcast-five">
        <div class="five-top">
          <div class="watch-five-font">观看人数统计(身份)</div>
          <div class="watch-five-btn">
            <select v-model="list5" style="width: 70px;height: 25px;border: #0a84ff solid 1px;color: #888888">
              <option>5</option>
              <option>10</option>
            </select>
          </div>
        </div>
        <!--      折线图-->
        <div id="watchShen" style="width: 100%;height: 300px"></div>
        <!-- 表格     -->
        <el-table
            :data="AddShen"
            stripe
            style="width: 95%;border-radius: 20px;margin: 0 auto"
        >
          <el-table-column
              prop="value"
              label="身份"
              align="center"
          >
            <template slot-scope="scope">
              <div>{{scope.row.value===""?"暂无":scope.row.value}}</div>
            </template>
          </el-table-column>
          <el-table-column
              prop="count"
              label="人数"
              align="center"
          ></el-table-column>
          <el-table-column
              label="占比"
              align="center"
          >
            <template slot-scope="scope">
              <div>{{Math.round((scope.row.count/watchSumPon.count)*100) + '%'}}</div>
            </template>
          </el-table-column>
        </el-table>
      </div >
      <!--    观看人数统计职称-->
      <div class="watchBroadcast-six">
        <div class="six-top">
          <div class="watch-six-font">观看人数统计(职称)</div>
          <div class="watch-six-btn">
            <select v-model="list6" style="width: 70px;height: 25px;border: #0a84ff solid 1px;color: #888888">
              <option>5</option>
              <option>10</option>
            </select>
          </div>
        </div>
        <!--      折线图-->
        <div id="watchZhi" style="width: 100%;height: 300px"></div>
        <!--  表格    -->
        <el-table
            :data="AddZhi"
            stripe
            style="width: 95%;border-radius: 20px;margin: 0 auto"
        >
          <el-table-column
              prop="value"
              label="职称"
              align="center"
          ></el-table-column>
          <el-table-column
              prop="count"
              label="人数"
              align="center"
          ></el-table-column>
          <el-table-column
              label="占比"
              align="center"
          >
            <template slot-scope="scope">
              <div>{{Math.round((scope.row.count/watchSumPon.count)*100) + '%'}}</div>
            </template>
          </el-table-column>
        </el-table>
      </div >
    </div>

  </div>

</template>

<script>

import {
  getMaxPons,
  getWatchSumPons,
  getWatchSumTime,
  watchPonAddDro, watchPonAddDroTu,
  watchPonAddKe, watchPonAddKeTu,
  watchPonAddPro, watchPonAddProTu,
  watchPonAddShen, watchPonAddShenTu,
  watchPonAddZhi, watchPonAddZhiTu
} from "../service/api";
import chinaJson from "../json/china.json";
import {ListPushPro, ListPushQiTa} from '../utils/myUtils'
import {getVisitWatchSumPens} from '../service/examApi'
export default {
  data(){
    return{
      //其他
      tableLose:[
        {name:'其他',num1:'',num2:''}
      ],
      watchSumShi:[],
      watchSumPon:[],
      maxPon:[],
      //平均观看时长
      savWatch:'',
      //占比
      zhanBi:'',
      //省份
      AddPro:[],
      //医院
      AddDro:[],
      //科室
      AddKe:[],
      //职称
      AddZhi:[],
      //身份
      AddShen:[],

      //省份
      AddProTu:[],
      //医院
      AddDroTu:[],
      //科室
      AddKeTu:[],
      //职称
      AddZhiTu:[],
      //身份
      AddShenTu:[],
      //地图滑动显示数据
      dataList:[
        { name: "广西", value: 1500},
        { name: "山东省", value: 59,},
        { name: "海南省", value: 14, }
      ],
      show:true,
       //  下拉菜单
       list:'5',
      list3:'5',
      list4:'5',
      list5:'5',
      list6:'5',
      VisitWatchSumPen:[],
    }
  },
  watch:{
    //省份
    async list(newVal){
      const limit = newVal
      const id = this.$route.query.id
      const AddPros = await watchPonAddPro(id,limit)
      if(AddPros.code === 0){
        let AddPro = AddPros.data
        if(AddPro === "" || AddPro === undefined || AddPro === null){
          alert('当前列表/ID没有内容，请重新选择！')
        }

        //ListPushQiTa重复利用，直接封装了遍历数组
        let sum = ListPushPro(AddPro);
        sum = this.watchSumPon.count - sum
        if (sum>0){
          //向集合中添加其他一行
          AddPro.push({name:"其他",value:sum})
        }
        this.AddPro = AddPro
      }else {
        alert('code不为0或者当前列表没有内容，请重新选择！')
        this.$router.back()
      }

    },
    //医院
    async list3(newVal){
      // console.log(newVal)
      const limit = newVal
      const id = this.$route.query.id
      const AddDros = await watchPonAddDro(id,limit)
      if(AddDros.code === 0){
        let AddDro = AddDros.data
        if(AddDro === "" || AddDro === undefined || AddDro === null){
          alert('当前列表/ID没有内容，请重新选择！')
        }
        //ListPushQiTa重复利用，直接封装了遍历数组
        let sum2 = ListPushQiTa(AddDro);
        //向集合中添加其他一行
        sum2 = this.watchSumPon.count - sum2
        if (sum2>0){
          //向集合中添加其他一行
          AddDro.push({value:"其他",count:sum2})
        }
        this.AddDro = AddDro
      }

    },
    //科室
    async list4(newVal){
      // console.log(newVal)
      const limit = newVal
      const id = this.$route.query.id
      const AddKes = await watchPonAddKe(id,limit)
      if(AddKes.code === 0){
        let AddKe = AddKes.data
        if(AddKe === "" || AddKe === undefined || AddKe === null){
          alert('当前列表/ID没有内容，请重新选择！')
        }
        //ListPushQiTa重复利用，直接封装了遍历数组
        let sum3 = ListPushQiTa(AddKe);
        //向集合中添加其他一行
        if (sum3>0){
          //向集合中添加其他一行
          sum3 = this.watchSumPon.count - sum3
        }
        this.AddKe = AddKe
      }

    },
    //身份
    async list5(newVal){
      // console.log(newVal)
      const limit = newVal
      const id = this.$route.query.id
      const AddShens = await watchPonAddShen(id,limit)
      if(AddShens.code === 0){
        let AddShen = AddShens.data
        if(AddShen === "" || AddShen === undefined || AddShen === null){
          alert('当前列表/ID没有内容，请重新选择！')
        }
        //ListPushQiTa重复利用，直接封装了遍历数组
        let sum = ListPushQiTa(AddShen);
        //向集合中添加其他一行
        sum = this.watchSumPon.count - sum
        if (sum>0){
          //向集合中添加其他一行
          AddShen.push({value:"其他",count:sum})
        }
        this.AddShen = AddShen
      }

    },
    //职称
    async list6(newVal){
      // console.log(newVal)
      const limit = newVal
      const id = this.$route.query.id
      const AddZhis = await watchPonAddZhi(id,limit)
      if(AddZhis.code === 0){
        let AddZhi = AddZhis.data
        if(AddZhi === "" || AddZhi === undefined || AddZhi === null){
          alert('当前列表/ID没有内容，请重新选择！')
        }
        //ListPushQiTa重复利用，直接封装了遍历数组
        let sum3 = ListPushQiTa(AddZhi);
        //向集合中添加其他一行
        sum3 = this.watchSumPon.count - sum3
        if (sum3>0){
          //向集合中添加其他一行
          AddZhi.push({value:"其他",count:sum3})
        }
        this.AddZhi = AddZhi
      }

    },
  },
  created() {

  },
    async mounted() {
    //观看总时长
      await this.watchSumTime()
    //观看总人数
      await this.watchSumPons()
    //峰值人数
       this.maxPons()

    //  访问总人数
      this.visitSumPens()

    //省份
       await this.watchAddPros()
      //判断页面数据全部加载完成
      this.$nextTick(() =>{
        this.show = false
      })
    //医院
       this.watchAddDro()
    //科室
       this.watchAddKe()
    //职称
       this.watchAddZhi()
    //身份
       this.watchAddShen()

    //省份图
      this.watchAddProsTu()

    //医院图
    this.watchAddDroTu()
    //科室图
    this.watchAddKeTu()
    //职称图
    this.watchAddZhiTu()
    //身份图
    this.watchAddShenTu()



  },
  methods:{
    //观看总时长
    async watchSumTime() {
      const id = this.$route.query.id
      const watchSumTimes = await getWatchSumTime(id)
      if(watchSumTimes.code === 0){
        this.watchSumShi = watchSumTimes.data[0]
        const watchSumShiA = this.watchSumShi
        if(watchSumShiA === "" || watchSumShiA === undefined || watchSumShiA === null){
          alert('当前列表/ID没有内容，请重新选择！')
        }

      }

    },
    //观看总人数
    async watchSumPons() {
      const id = this.$route.query.id
      const watchSumPons = await getWatchSumPons(id)
      if(watchSumPons.code === 0){
        this.watchSumPon = watchSumPons.data[0]
        const watchSumPonA = this.watchSumPon
        if(watchSumPonA === "" || watchSumPonA === undefined || watchSumPonA === null){
          alert('当前列表/ID没有内容，请重新选择！')
        }
      }

    },
    //峰值人数
    async maxPons() {
      const id = this.$route.query.id
      const maxPons = await getMaxPons(id)
      if(maxPons.code === 0){
        this.maxPon = maxPons.data[0]
        const maxPonA = this.maxPon
        if(maxPonA === "" || maxPonA === undefined || maxPonA === null){
          alert('当前列表/ID没有内容，请重新选择！')
        }
      }

    },
    //访问总人次
    async visitSumPens(){
      const id = this.$route.query.id
      const VisitWatchSumPens = await getVisitWatchSumPens(id)
      if(VisitWatchSumPens.code === 0){
        this.VisitWatchSumPen = VisitWatchSumPens.data
      }else {
        console.log('code不为零')
      }


    },
    //省份
    async watchAddPros() {
      const limit = 5
      const id = this.$route.query.id
      const AddPros = await watchPonAddPro(id,limit)
      if(AddPros.code === 0){
        let AddPro = AddPros.data
        if(AddPro === "" || AddPro === undefined || AddPro === null ){
          alert('当前列表/ID没有内容，请重新选择！')
        }
        //ListPushQiTa重复利用，直接封装了遍历数组
        let sum = ListPushPro(AddPro);
        //向集合中添加其他一行
        sum = this.watchSumPon.count - sum
        if (sum>0){
          //向集合中添加其他一行
          AddPro.push({name:"其他",value:sum})
        }
        this.AddPro = AddPro
      }

    },
    //医院
    async watchAddDro() {
      const limit = 5
      const id = this.$route.query.id
      const AddDros = await watchPonAddDro(id,limit)
      if(AddDros.code === 0){
        let AddDro = AddDros.data
        if(AddDro === "" || AddDro === undefined || AddDro === null){
          alert('当前列表/ID没有内容，请重新选择！')
        }
        //ListPushQiTa重复利用，直接封装了遍历数组
        let sum2 = ListPushQiTa(AddDro);
        //向集合中添加其他一行
        sum2 = this.watchSumPon.count - sum2
        if (sum2>0){
          //向集合中添加其他一行
          AddDro.push({value:"其他",count:sum2})
        }
        this.AddDro = AddDro
      }
    },
    //科室
    async watchAddKe() {
      const limit = 5
      const id = this.$route.query.id
      const AddKes = await watchPonAddKe(id,limit)
      if(AddKes.code === 0){
        let AddKe = AddKes.data
        if(AddKe === "" || AddKe === undefined || AddKe === null){
          alert('当前列表/ID没有内容，请重新选择！')
        }
        //ListPushQiTa重复利用，直接封装了遍历数组
        let sum3 = ListPushQiTa(AddKe);
        //向集合中添加其他一行
        sum3 = this.watchSumPon.count - sum3
        if (sum3>0){
          //向集合中添加其他一行
          AddKe.push({value:"其他",count:sum3})
        }
        this.AddKe = AddKe
      }

    },
    //职称
    async watchAddZhi() {
      const limit = 5
      const id = this.$route.query.id
      const AddZhis = await watchPonAddZhi(id,limit)
      if(AddZhis.code === 0){
        let AddZhi = AddZhis.data
        if(AddZhi === "" || AddZhi === undefined || AddZhi === null){
          alert('当前列表/ID没有内容，请重新选择！')
        }
        //ListPushQiTa重复利用，直接封装了遍历数组
        let sum3 = ListPushQiTa(AddZhi);
        //向集合中添加其他一行
        sum3 = this.watchSumPon.count - sum3
        if (sum3>0){
          //向集合中添加其他一行
          AddZhi.push({value:"其他",count:sum3})
        }
        this.AddZhi = AddZhi
      }

    },
    //身份
    async watchAddShen() {
      const limit = 5
      const id = this.$route.query.id
      const AddShens = await watchPonAddShen(id,limit)
      if(AddShens.code === 0){
        let AddShen = AddShens.data
        if(AddShen === "" || AddShen === undefined || AddShen === null){
          alert('当前列表/ID没有内容，请重新选择！')
        }
        //ListPushQiTa重复利用，直接封装了遍历数组
        let sum = ListPushQiTa(AddShen);
        //向集合中添加其他一行
        sum = this.watchSumPon.count - sum
        if (sum>0){
          //向集合中添加其他一行
          AddShen.push({value:"其他",count:sum})
        }
        this.AddShen = AddShen
      }

    },

  //  省份图
    async watchAddProsTu() {
      const id = this.$route.query.id
      const AddProsTu = await watchPonAddProTu(id)
      if(AddProsTu.code === 0){
        this.AddProTu = AddProsTu.data
        const AddProTuA = this.AddProTu
        if(AddProTuA === "" || AddProTuA === undefined || AddProTuA === null){
          alert('当前列表/ID没有内容，请重新选择！')
        }
        await this.rotationProsTu()
      }

    },
    rotationProsTu(){
      let drawLine = this.$echarts.init(document.getElementById('watchShewing'))
      this.$echarts.registerMap('china',chinaJson)
      let option = {
        tooltip: {
          formatter:function(params){
            return params.seriesName+'<br />'+params.name+'：'+params.value
          }//数据格式化
        },
        visualMap: {
          min: 0,
          max: 1500,
          left: 'left',
          top: 'bottom',
          text: ['H','L'],

          inRange: {
            color: ['#e2fafc', '#0c73dc']
          },
          show:true
        },
        geo: {
          map: 'china',
          roam: true,
          zoom:1.23,
          label: {
            normal: {
              show: false,
              fontSize:'8',
              color: 'rgba(0,0,0,0.7)'
            }
          },
          itemStyle: {
            normal:{
              borderColor: 'rgba(0, 0, 0, 0.2)',
              areaColor: '#fff'
            },
            emphasis:{
              areaColor: '#F3B329',
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        },
        series : [
          {
            name: '访问人数',
            type: 'map',
            geoIndex: 0,
            data:this.AddProTu,
          }
        ]
      };
      drawLine.setOption(option);
      // drawLine.on('click', function (params) {
      //   alert(params.name);
      // });

    },
    //医院图
    async watchAddDroTu() {
      const limit = 10
      const id = this.$route.query.id
      const AddDrosTu = await watchPonAddDroTu(id,limit)
      if(AddDrosTu.code === 0){
        this.AddDroTu = AddDrosTu.data
        const AddDroTuA = this.AddDroTu
        if(AddDroTuA === "" || AddDroTuA === undefined || AddDroTuA === null){
          alert('当前列表/ID没有内容，请重新选择！')
        }
        await this.rotationYiTu()
      }

    },
    rotationYiTu(){
      let drawLine = this.$echarts.init(document.getElementById('watchYi'))
      let option = {
        dataset:{
          source:this.AddDroTu
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            rotate:60,
          }
        },
        yAxis: {
          type: 'value'
        },
        series:{
          type:'bar'
        }
        // title: {
        //   left: 'center'
        // },
        // dataset: {
        //   source: this.AddDroTu
        // },
        // series: [
        //   {
        //     type: 'pie',
        //     radius: '80%'
        //   }
        // ]
        // dataset: {
        //   source: this.AddDroTu
        // },
        // grid: {
        //   left:'2%',
        //   right:'5%',
        //   bottom:'4%',
        //   containLabel: true
        // },
        // xAxis: {
        //   //显示横坐标线
        //   show:true,
        // },
        // yAxis: {
        //   type: 'category',
        //   axisLabel:{
        //     textStyle:{
        //       //柱子纵坐标字体大小
        //       fontSize:'8px'
        //     }
        //   }
        // },
        // series: [
        //   {
        //     type: 'bar',
        //     //主子宽度
        //     barWidth:'70%',
        //     //柱子颜色设置
        //     color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{
        //       offset: 0,
        //       color: "#72B2F3FF" // 100% 处的颜色
        //     },{
        //       offset: 1,
        //       color: "#0c73dc" // 0% 处的颜色
        //     }], false),
        //     itemStyle:{
        //       label:{
        //         show: true,
        //         position:'right',
        //         textStyle: {
        //           color:'black',
        //           fontSize: 16
        //         }
        //       }
        //     }
        //   }
        // ]
      };

      drawLine.setOption(option);
    },
    //科室图
    async watchAddKeTu() {
      const id = this.$route.query.id
      const AddKesTu = await watchPonAddKeTu(id)
      if(AddKesTu.code === 0){
        this.AddKeTu = AddKesTu.data
        const AddKeTuA = this.AddKeTu
        if(AddKeTuA === "" || AddKeTuA === undefined || AddKeTuA === null){
          alert('当前列表/ID没有内容，请重新选择！')
        }
        await this.rotationShengTu()
      }

    },
    rotationShengTu(){
      let drawLine = this.$echarts.init(document.getElementById('watchKe'))
      let option = {
        title: {
          left: 'center'
        },
        dataset: {
          source: this.AddKeTu
        },
        series: [
          {
            type: 'pie',
            radius: ['20%', '50%'],
          }
        ]
      };

      drawLine.setOption(option);
    },
    //职称图
    async watchAddZhiTu() {
      const id = this.$route.query.id
      const AddZhisTu = await watchPonAddZhiTu(id)
      if(AddZhisTu.code === 0){
        this.AddZhiTu = AddZhisTu.data
        const AddZhiTuA = this.AddZhiTu
        if(AddZhiTuA === "" || AddZhiTuA === undefined || AddZhiTuA === null){
          alert('当前列表/ID没有内容，请重新选择！')
        }
        await this.rotationZhiTu()
      }else {
        alert('code不为0或者当前列表没有内容，请重新选择！')
        this.$router.back()
      }

    },
    rotationZhiTu(){
      let drawLine = this.$echarts.init(document.getElementById('watchZhi'))
      let option = {
        title: {
          left: 'center'
        },
        dataset: {
          source: this.AddZhiTu
        },
        series: [
          {
            type: 'pie',
            radius: ['20%', '50%'],
          }
        ]
      };

      drawLine.setOption(option);
    },
    //身份图
    async watchAddShenTu() {
      const id = this.$route.query.id
      const AddShensTu = await watchPonAddShenTu(id)
      if(AddShensTu.code === 0){
        this.AddShenTu = AddShensTu.data
        const AddShenTuA = this.AddShenTu
        if(AddShenTuA === "" || AddShenTuA === undefined || AddShenTuA === null){
          alert('当前列表/ID没有内容，请重新选择！')
        }
        await this.rotationShenTu()
      }

    },
    rotationShenTu(){
      let drawLine = this.$echarts.init(document.getElementById('watchShen'))
      let option = {
        title: {
          left: 'center'
        },
        dataset: {
          source: this.AddShenTu
        },
        series: [
          {
            type: 'pie',
            radius: ['20%', '50%'],
          }
        ]
      };

      drawLine.setOption(option);
    },


  },
}
</script>


<style lang="scss" scoped>
.watchBroadcast{
  width: 100%;
  height: auto;
  .watchBroadcast-top{
    width: 100%;
    height: auto;
    position: absolute;
    z-index: 5555;
  }
  .watchBroadcast-all{
    width: 100%;
    height: auto;
    .overZhe{
      //height: 100%;
      //position: absolute;
      //z-index: 9999;
    }
    .watchBroadcast-one{
      width: 94%;
      height: 180px;
      margin: 15px auto;
      border-radius: 20px;
      box-shadow: 0 0 10px #45A5E6;
      .watch-one-font{
        width: 100%;
        height: 50px;
        line-height: 50px;
        margin-left: 20px;
        color: #45A5E6;
        font-size: 16px;
      }
      .watch-one-rad{
        width: 100%;
        height: 90px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .watch-one-rad-one{
          width: 60px;
          height: 60px;
          background-color: #a06ed1;
          border-radius: 60px;
          text-align: center;
          line-height: 60px;
          color: #fff;
          font-size: 18px;
        }
        .watch-one-rad-two{
          width: 60px;
          height: 60px;
          background-color: #419bfc;
          border-radius: 60px;
          text-align: center;
          line-height: 60px;
          color: #fff;
          font-size: 18px;
        }
        .watch-one-rad-three{
          width: 60px;
          height: 60px;
          background-color: #f34491;
          border-radius: 60px;
          text-align: center;
          line-height: 60px;
          color: #fff;
          font-size: 18px;
        }
        .watch-one-rad-four{
          width: 60px;
          height: 60px;
          background-color: #f3ab44;
          border-radius: 60px;
          text-align: center;
          line-height: 60px;
          color: #fff;
          font-size: 18px;
        }
        .watch-one-rad-five{
          width: 60px;
          height: 60px;
          background-color: #f34444;
          border-radius: 60px;
          text-align: center;
          line-height: 60px;
          color: #fff;
          font-size: 18px;
        }
      }
      .watch-one-bottom{
        width: 100%;
        height: 20px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .watch-one-bottom-one{
          font-size: 10px;
        }
      }
    }
    .watchBroadcast-two{
      width: 94%;
      height:auto;
      margin: 15px auto;
      border-radius: 20px;
      box-shadow: 0 0 10px #45A5E6;
      .two-top{
        display: flex;
        height: auto;
        .watch-two-font{
          width: 100%;
          height: 50px;
          line-height: 50px;
          margin-left: 20px;
          color: #45A5E6;
          font-size: 16px;
        }
        .watch-two-btn{
          margin: 15px;
        }
      }
    }
    .watchBroadcast-three{
      width: 94%;
      height: auto;
      margin: 15px auto;
      border-radius: 20px;
      box-shadow: 0 0 10px #45A5E6;
      .three-top{
        display: flex;
        height: auto;
        .watch-three-font{
          width: 100%;
          height: 50px;
          line-height: 50px;
          margin-left: 20px;
          color: #45A5E6;
          font-size: 16px;
        }
        .watch-three-btn{
          margin: 15px;
        }
      }
    }
    .watchBroadcast-four{
      width: 94%;
      height: auto;
      margin: 15px auto;
      border-radius: 20px;
      box-shadow: 0 0 10px #45A5E6;
      .four-top{
        display: flex;
        height: auto;
        .watch-four-font{
          width: 100%;
          height: 50px;
          line-height: 50px;
          margin-left: 20px;
          color: #45A5E6;
          font-size: 16px;
        }
        .watch-four-btn{
          margin: 15px;
        }
      }
    }
    .watchBroadcast-five{
      width: 94%;
      height: auto;
      margin: 15px auto;
      border-radius: 20px;
      box-shadow: 0 0 10px #45A5E6;
      .five-top{
        display: flex;
        height: auto;
        .watch-five-font{
          width: 100%;
          height: 50px;
          line-height: 50px;
          margin-left: 20px;
          color: #45A5E6;
          font-size: 16px;
        }
        .watch-five-btn{
          margin: 15px;
        }
      }

    }
    .watchBroadcast-six{
      width: 94%;
      height: auto;
      margin: 15px auto;
      border-radius: 20px;
      box-shadow: 0 0 10px #45A5E6;
      .six-top{
        display: flex;
        height: auto;
        .watch-six-font{
          width: 100%;
          height: 50px;
          line-height: 50px;
          margin-left: 20px;
          color: #45A5E6;
          font-size: 16px;
        }
        .watch-six-btn{
          margin: 15px;
        }
      }
    }
  }
}
</style>
