<template>
  <div style="background-color: #f5f5f5;height: 1000px">
    接收参数
    <p>code:{{code}}</p>
<!--    <p>access_token:{{access_token}}</p>-->
<!--    <p>openid:{{openid}}</p>-->
<!--    <p>用户信息:{{userMessage}}</p>-->




  </div>
</template>
<script>
// import axios from "axios";

export default {
  data(){
    return{
      // access_token:null,
      // openid:null,
      // userMessage:null,
      code:null,
    }
  },
  created() {
    // this.isWeixinBrowser()
    this.getUrlParam('code')
  },
  methods:{
    // 判断是否为微信浏览器
    // isWeixinBrowser() {
    //   let ua = navigator.userAgent.toLowerCase();
    //   if(/micromessenger/.test(ua) ? true : false){
    //     this.getUrlParam('code')
    //   }else {
    //     this.$message.warning("请在微信中打开")
    //   }
    // },
    // 获取页面路径的code参数

    getUrlParam(name) { // 获取URL指定参数
      alert('走了这个函数')
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)') // 构造一个含有目标参数的正则表达式对象

      var r = window.location.search.substr(1).match(reg) // 匹配目标参数

      this.code = unescape(r[2])

      alert('获取到的code: '+unescape(r[2]))
      // this.test(unescape(r[2]))

      if (r != null){
        return unescape(r[2])
      }else {
        return null
      }
    },
    // test(code){
    //   alert('111111111111111111111')
    //   axios.get('http://192.168.31.160:10086/api/weCom/weComLogInfo?code='+code).then((response) => {
    //     alert('获取信息'+response.data)
    //   }).catch((e)=>{
    //     alert(e+'出错了')
    //   })
    // }
  //   //  获取 access_token 参数
  //   getAccessToken(code) {
  //     alert('code'+code)
  //     axios({
  //       // 默认请求方式为get
  //       method: 'get',
  //       url: 'https://api.weixin.qq.com/sns/oauth2/access_token?appid=wxbe583750bee45b3a&secret=173f1b5900d175fc247d013cfc6a650e&code='+code+'&grant_type=authorization_code',
  //     }).then((response) => {
  //       let res = response.data;
  //       alert(res)
  //       // this.access_token = res.access_token
  //       // this.openid = res.openid
  //       // this.getUserMessage(res.access_token,res.openid)
  //     }).catch((error)=>{
  //       alert(error)
  //     })
  //   },
  // //  获取用户信息
  //   getUserMessage(access_token,openid){
  //     axios({
  //       // 默认请求方式为get
  //       method: 'get',
  //       url: 'https://api.weixin.qq.com/sns/userinfo?access_token='+ access_token +'&openid='+ openid +'&lang=zh_CN',
  //     }).then((response) => {
  //       let res = response.data;
  //       this.userMessage = res
  //       alert(res)
  //     }).catch((error)=>{
  //       alert(error)
  //     })
  //   }
  }
}
</script>
