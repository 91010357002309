<template>
  <div class="ToolContent">
    <div class="content-title" v-html="listContent.title">{{listContent.title}}</div>
    <div class="content-time" v-html="listContent.show_time">{{listContent.show_time}}</div>
    <div class="content-content" v-html="listContent.content">{{listContent.content}}</div>
  </div>
</template>
<script>
import {getInviteContent} from "../service/api";

export default {
  data(){
    return{
      listContent:[],
    }
  },
  mounted() {
    // document.title = this.$route.query.title
    this.getList()
  },
  methods:{
    async getList(){
      const r_id = this.$route.query.content_id
      const response = await getInviteContent(r_id)
      if(response.code === 0){
        this.listContent = response.data
        this.$nextTick(() => {
          let title = this.listContent.title
          let len = title.length;//总长度
          const size = 13; //展示的title长度
          // const rate = 500; //title滚动的频率，最小300ms
          if (len > size) {
            let num = 0;
            let tmpStr = title.substr(num, size);
            document.title = tmpStr+'...';
          }else {
            document.title = title;
          }
        });
      }else {
        this.$message({
          message: response.message,
          type: 'warning'
        });
      }
      // //埋点
      // let memberId = this.$store.state.memberId
      // //post要在获取接口之后写,判断member_id不为空
      // if(memberId !== null && memberId !== undefined  && memberId !== ""){
      //   //详情：TOOL_RECRUITMENT_DETAIL
      //   //调接口后都判断一下接口中的code是否为0，否时显示提示错误信息
      //   const response = await postCommunityView("TOOL_RECRUITMENT_DETAIL",memberId,r_id);
      //   if (response.code === 0){
      //     console.log("行为获取成功")
      //   }
      // }else {
      //   console.log('招聘内容没有post:type和member_id')
      // }
    }
  },
  created() {
    // this.$nextTick(() => {
    //
    //   // let title = this.$route.query.title
    //   let title = this.listContent.title
    //   console.log('title',title)
    //   let len = title.length;//总长度
    //   const size = 13; //展示的title长度
    //   // const rate = 500; //title滚动的频率，最小300ms
    //   if (len > size) {
    //     let num = 0;
    //     let tmpStr = title.substr(num, size);
    //     document.title = tmpStr+'...';
    //   }else {
    //     document.title = title;
    //   }
    // });
  }
}
</script>

<style lang="scss">
  .ToolContent{
    width: 100%;
    height: 100%;
    .content-title{
      width: 97%;
      height: auto;
      margin: 10px;
      font-size: 18px;
      font-weight: bold;
      color: #1a1a1a;
    }
    .content-time{
      width: 97%;
      height: auto;
      font-size: 14px;
      color: #a4a3a3;
      //border-bottom:1px solid #f1f1f1 ;
      padding-left: 10px;
      height: 25px;
    }
    .content-content{
      width: 97%;
      margin: auto;
      height: auto;
    }
  }
  .content-content img{
    width: 100%;
    height: auto;
    margin: auto;
  }
</style>
