import {get,put} from "./base";



import qs from 'qs'
export function getMemberList(status){
    return get('/v3/cretifys',{status})
}
export function getMemberInfo(user_id){
    return get('/v3/creatifys/' + user_id)
}
//保存
export function putMemberInfo(user_id,id,data){
    const url ='/v3/cretify/' + user_id + '/' +id ;
    return put(url ,data)
}
//驳回
export function putMemberRejected(id,data){
    return put('/v3/creatify/r/' +id ,qs.stringify(data))
}
//通过
export function putMemberInfoOK(id,sid){
    const  url1 = '/v3/creatify/' + id + '/' +sid ;
    return put(url1)
}
