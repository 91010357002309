import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import { Dialog } from 'vant';
import * as echarts from 'echarts';
import 'vant/lib/index.css';
import {Lazyload} from "vant";
import '@/assets/css/formulaTemplate.css'; // 引入公共样式
import '@vant/touch-emulator';
Vue.use(Lazyload,{
  lazyComponent:true,
})

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.prototype.$echarts = echarts
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.use(Vant);
Vue.use(Dialog);
//配置在微信跳开放标签
Vue.config.ignoredElements = ['wx-open-launch-app'];

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

// Vue.prototype.bus = new Vue();
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
