<template>
  <div class="televise">
    <div class="televise-top">
      <el-input
          style="width: 70%;margin-left: 10px;margin-top: 10px"
          placeholder="请输入直播间ID查询"
          v-model="value"
          clearable>
      </el-input>
      <router-link :to="{path:'/watchBroadcast',query:{id:this.value}}">
              <el-button @click="queryBtn" type="primary" style="margin-left: 10px;margin-top: 10px" >查 询</el-button>
      </router-link>
    </div>
    <div class="televise-top-row">
      <router-link
          tag="div"
          :to="{path:'/watchBroadcast',query:{id:list.id}}" class="televise-list"
          v-for="(list,index) in lists" :key="list.id">
        <div :id="'bio'+index" class="content" @mouseover="mouseOne('bio'+index)" @mouseleave="mouseTwo('bio'+index)">
          <div class="one">{{list.id + ':'}}</div>
          <div class="two">{{list.title}}</div>
          <div class="jianTou">></div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import {getAskList} from "../service/api";

export default {
  data(){
    return{
      value:'',
      //直播间数据
      lists:[],
    }
  },
  async mounted() {
    await this.AskList()
  },
  methods:{
    queryBtn(){
      console.log(this.value)
    },
    async AskList(){
      const result = await getAskList()
      if(result.code !== 0){
        alert('code不为0或者当前列表没有内容，请重新选择！')
      }
      this.lists = result.data
    },
    mouseOne(list){
      document.getElementById(list).style.backgroundColor = '#ecf6fa'
    },
    mouseTwo(list){
      document.getElementById(list).style.backgroundColor = 'white'
    }
  }
}
</script>

<style lang="scss" scoped>
.televise{
  height: auto;
  width: 100%;
  .televise-top{
    display: flex;
    margin-bottom: 10px;
  }
  .televise-top-row{
    width: 95%;
    height: auto;
    margin: 0 auto;
    .televise-list{
      width: 100%;
      display: flex;
      border-bottom: 1px solid #e1dcdc;
      .content{
        width: 100%;
        display: flex;
        .one{
          width: 10%;
          height: 35px;
          line-height: 35px;
          text-align: center;
          font-size: 14px;
        }
        .two{
          width: 78%;
          font-size: 12px;
          height: 35px;
          line-height: 35px;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow:ellipsis;
        }
        .jianTou{
          color: #888888;
          margin-left: 20px;
          height: 35px;
          line-height: 35px;
        }
      }
    }
  }
}
</style>
