<!--<template>-->
<!--  <div style="height: 100%;">-->
<!--    &lt;!&ndash;      当不是微信浏览器打开页面时提示微信打开&ndash;&gt;-->
<!--&lt;!&ndash;    <div v-if="!isWeixin">&ndash;&gt;-->
<!--&lt;!&ndash;      <div style="display: flex;justify-content: center;justify-items: center;font-size: 150px">&ndash;&gt;-->
<!--&lt;!&ndash;        <van-icon style="margin-top: 150px;margin-bottom: 20px" color="#45a5e6" name="info"/>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--&lt;!&ndash;      <p style="text-align: center;font-size: 18px">提示：请使用企业微信浏览器打开</p>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;    <div v-else>&ndash;&gt;-->
<!--      &lt;!&ndash; 输入任意文本 &ndash;&gt;-->
<!--      &lt;!&ndash; 输入手机号，调起手机号键盘 &ndash;&gt;-->
<!--      <van-field style="margin-bottom: 10px"  type="tel" label="手机号" />-->
<!--      &lt;!&ndash; 输入密码 &ndash;&gt;-->
<!--      <van-field style="margin-bottom: 10px" type="password" label="密码" />-->
<!--      <van-button style="width: 100%" @click="loginBtn">-->
<!--        登录-->
<!--      </van-button>-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--    </div>-->
<!--</template>-->
<!--<script>-->
<!--export default {-->
<!--  data(){-->
<!--    return{-->
<!--      url:'http://tool-h5-dev.yunicu.com/#/takeInVue',-->
<!--      isWeixin:false-->
<!--    }-->
<!--  },-->
<!--  created() {-->
<!--    // this.isWeixinBrowser()-->
<!--  },-->
<!--  methods:{-->
<!--    //企业微信-->
<!--    isWeixinBrowser() {-->
<!--      let ua = navigator.userAgent.toLowerCase();-->
<!--      if(/wxwork/.test(ua) ? true : false){-->
<!--        this.isWeixin = true-->
<!--      }else {-->
<!--        this.isWeixin = false-->
<!--      }-->
<!--    },-->
<!--    loginBtn(){-->
<!--      //微信-->
<!--      // window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbe583750bee45b3a&redirect_uri=http%3A%2F%2Ftool-h5-dev.yunicu.com%2F%23%2FtakeInVue&response_type=code&scope=snsapi_userinfo&state=STATE&forcePopup=true#wechat_redirect'-->

<!--      //企业微信-->
<!--      // window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww012c0718f5ab3989&redirect_uri=https%3A%2F%2Ftool-h5.yunicu.com%2F%23%2FmyYunicu&response_type=code&scope=snsapi_base&state=STATE&agentid=1000014#wechat_redirect'-->
<!--      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize' +-->
<!--          '?appid=ww012c0718f5ab3989' +-->
<!--          '&redirect_uri=https%3A%2F%2Fweb.yunicu.com%2FtestCode' +-->
<!--          '&response_type=code' +-->
<!--          '&scope=snsapi_privateinfo' +-->
<!--          '&agentid=1000016' +-->
<!--          '&state=STATE' +-->
<!--          '#wechat_redirect'-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</script>-->

<template>
  <div>
    <el-date-picker
        v-model="value1"
        @change="onChange"
        placeholder="选择日期">
    </el-date-picker>

    <div>
      <img style="width:40px;height: 40px" src="" alt="">
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data(){
    return{
      value1:null,
    }
  },
  methods:{
    moment,
    onChange(value){
      // this.value1 = value;
      console.log(moment(value).format('YYYY-MM-DD'))
    }
  }
}
</script>
