import  {get,post} from './base'
import  {getD,postTokenD,postD,delD,putD,postToken} from './baseDocument'
// 文献数据分析api



export function getHomeAccess(){
    return get('/v2/queryBgmHomeList')
}
export  function getContentAccess(){
    return get('/v2/queryBgmContentList')
}
export  function getHomeToday(){
    return get('/v2/queryBgmHometodayList')
}
export  function getContentToday(){
    return get('/v2/queryBgmContentTodayList')
}
export  function getContentTopTen(){
    return get('/v2/queryBgmContentTopTen')
}
export  function getDayTime(data){
    return get('/v2/queryBgmDateManTime',data)
}
export function getDayNumberTime(data){
    return get('/v2/queryBgmDateManTimeCount',data)
}
export function getMoonTime(data){
    return get('/v2/queryBgmMouthManTime',data)
}
export function getMoonNumberTime(data){
    return get('/v2/queryBgmMouthCount',data)
}
// export function browseOperationH(data) {
//     return postToken('/api/addReportContent',data)
// }
export function browseOperationH(data) {
    return postToken('/user/addReportContent',data)
}
// 文献指南埋点
export function browseOperationWX(data) {
    return postTokenD('/user/addReportContent',data)
}

//获取直播间列表
export function getRoomCount() {
    return get('/api/admin/selCanUpdViewersLiveStream')
}//获取单个直播间列表
export function getOneRoomCount(liveStreamId) {
    return get('/api/admin/selCanUpdViewersLiveStream?liveStreamId='+liveStreamId)
}
//添加刷观看人次记录
export function postLiveStreamViewers(liveStreamId,addCount,userId) {
    return post('/api/admin/addLiveStreamViewers?liveStreamId='+liveStreamId+'&addCount='+addCount+'&userId='+userId)
}
//
export function getUserInfo(code) {
    return get('/api/weCom/weComLogInfo?code='+code)
}
// pdf收藏 /user/userAction/collection
export function postCollection(data) {
    return postD(`/user/userAction/collection`, data);
}
// pdf取消收藏 
export function delCollection(contentType,contentId){
    return delD(`/user/userAction/collection/${contentType}/${contentId}`)
}
// 发送邮件 
export function postEmail(data) {
    return postTokenD(`/user/document/email`, data);
}
// 获取发送记录 
export function getEmailRecord(data) {
    return getD('/user/document/email',data)
}
// 获取收藏状态 
export function getIsCollection(data) {
    return getD('/user/userAction/collection/isCollection',data)
}
// 邮箱回显 
export function getEmailById() {
    return getD('/user/document/memberid')
}
// 修改邮箱  
export function putEmail(data) {
    return putD('/user/document/memberid',data)
}