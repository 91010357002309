<template>
  <div class="communityContentData">
    <!--    总览统计-->
    <div class="communityModule-one">
      <!--      总览统计顶部-->
      <div class="communityModule-one-top">
        <div class="communityModule-one-top-left">总览统计</div>
      </div>
      <div class="communityModule-one-second">
        <div class="second-module">
          <div class="second-module-top1">{{renNum.count}}</div>
          <div class="second-module-bottom">本月总访问人数</div>
        </div>
        <div class="second-module">
          <div class="second-module-top2">{{renCount.count}}</div>
          <div class="second-module-bottom">本月总访问人次</div>
        </div>
        <div class="second-module">
          <div class="second-module-top3">{{renVisit.count}}</div>
          <div class="second-module-bottom">今日访问人数</div>
        </div>
      </div>
    </div>
    <!--    访问量(日)-->
    <div class="communityModule-three">
      <div class="communityModule-three-font">访问量(日)</div>
      <!--      时间选择器-->
      <div class="communityModule-three-top">

        <div class="communityModule-three-top-time">
          <el-date-picker
              style="width: 90%"
              v-model="value1"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="选择开始时间"
              @change="getTime1"
              @blur="lose1"
          >
          </el-date-picker>
        </div>
        <div class="communityModule-three-top-time-heng">-</div>
        <div class="communityModule-three-top-time">
          <el-date-picker
              style="width: 90%"
              v-model="value2"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="选择结束时间"
              @change="getTime2"
              @blur="lose2"
          >
          </el-date-picker>
        </div>
      </div>
      <!--      数据折线图-->
      <div class="communityModule-three-middle">
        <div id="visitPensions" style="width: 100%;height: 300px"></div>
      </div>
      <!--      表格-->
      <div  style="display: flex">
        <div class="communityModule-three-bottom" style="width: 70%">
          <el-table
              :data="postData"
              stripe
              style="width: 100%;border-radius: 20px">
            <el-table-column
                prop="date_format"
                label="日期"
                align="center"
            ></el-table-column>
            <el-table-column
                prop="count"
                label="访问人数"
                align="center"
            ></el-table-column>
          </el-table>
        </div>
        <div class="communityModule-three-bottom" style="width: 30%">
          <el-table
              :data="responseCounts"
              stripe
              style="width: 100%;border-radius: 20px">
            <el-table-column
                prop="count"
                label="访问人次"
                align="center"
            ></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!--    访问人次(月)-->
    <div class="communityModule-five">
      <div class="communityModule-five-font">访问量(月)</div>
      <!--      时间选择器-->
        <div class="communityModule-five-top">

          <div class="communityModule-five-top-time">
            <el-date-picker
                style="width: 90%"
                v-model="value5"
                type="year"
                format="yyyy"
                value-format="yyyy"
                placeholder="请选择时间"
                @change="getTime5"
                @blur="lose5">
            </el-date-picker>
          </div>
        </div>
      <!--      数据折线图-->
      <div class="communityModule-five-middle">
          <div id="visitWeek" style="width: 95%;height: 300px"></div>
      </div>
      <!--      表格-->
      <div style="display: flex">
        <div class="communityModule-five-bottom" style="width: 70%">
          <el-table
              :data="visitMonthPen"
              stripe
              style="width: 100%;border-radius: 20px">
            <el-table-column
                prop="year"
                label="时间"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="count"
                label="访问人数"
                align="center"
            ></el-table-column>
          </el-table>
        </div>
        <div class="communityModule-five-bottom" style="width: 30%">
          <el-table
              :data="visitMonthCount"
              stripe
              style="width: 100%;border-radius: 20px">
            <el-table-column
                prop="count"
                label="访问人次"
                align="center"
            ></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!--    访问人次(时)-->
    <div class="communityModule-four">
      <div class="communityModule-four-font">访问人次(时)</div>
      <!--      时间选择器-->
      <div class="communityModule-four-top">

        <div class="communityModule-four-top-time">
          <el-date-picker
              style="width: 90%"
              v-model="value3"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="选择开始时间"
              @change="getTime3"
              @blur="lose3">
          </el-date-picker>
        </div>
        <div class="communityModule-three-top-time-heng">-</div>
        <div class="communityModule-four-top-time">
          <el-date-picker
              style="width: 90%"
              v-model="value4"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="选择开始时间"
              @change="getTime4"
              @blur="lose4">
          </el-date-picker>
        </div>
      </div>
      <!--      数据折线图-->
      <div class="communityModule-four-middle">
        <div id="visitCount" style="width: 100%;height: 300px"></div>
      </div>
      <!--      表格-->
      <div class="communityModule-four-bottom">
        <el-table
            :data="postDataTwo"
            stripe
            style="width: 100%;border-radius: 20px">
          <el-table-column
              prop="hour"
              label="时间"
              align="center"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.hour < 10">
                <span>{{'0' + scope.row.hour + ':00'}}</span>
              </div>
              <div v-if="scope.row.hour >= 10">
                <span>{{scope.row.hour + ':00'}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="count"
              label="访问人次"
              align="center"
          ></el-table-column>
        </el-table>
      </div>
    </div>

  </div>
</template>

<script>
import {
  getComChangeVisitCount,
  getComChangeVisitCountDay,
  getComChangeVisitPension, getComMonthCount, getComMonthPens,
  getCommunityContentDay,
} from '../service/api'
import {getComVisitPensions,getConVisitCount,getComDayVisitPension} from '../service/api'
import moment from "moment";

export default {
  data(){
    return{
      activeName: 'first',
      lists:[],
      renNum:[],
      renCount:[],
      renVisit:'',
      value1:'',
      value2:'',
      value3:'',
      value4:'',
      value5:'',
      changeDatePension:'',
      postData:[],
      postData1:[],
      postData2:[],
      postDataTwo:[],
      postDataTwo1:[],
      postDataTwo2:[],
      responseCounts:[],
      visitWeek:[],
      //社区每月访问人次
      visitMonthCount:[],
      //社区每月访问人次
      visitMonthPen:[]
    }
  },
  watch:{
    async value1(newVal){
      //1.判断前后日期都存   在并且后面大于前面
      //2.重新加载echart

      if(newVal !== '' && newVal !== undefined && newVal !== null &&
          this.value2 !== '' && this.value2 !== undefined && this.value2 !== null &&
          this.value2 > newVal){

        const start_time = newVal
        const end_time = this.value2
        // 社区(选择日期访问)访问人数
        // 调取接口
        const response =await getComChangeVisitPension(start_time,end_time)
        if(response.code === 0){
          this.postData = response.data
        }else {
          this.$message.warning("没有数据，" + response.message)
        }

        const responseCount = await getComChangeVisitCountDay(start_time,end_time)
        if(responseCount.code === 0){
          this.responseCounts = responseCount.data
        }else {
          this.$message.warning("没有数据，" + responseCount.message)
        }

        await this.rotation()
      }
    },
    async value2(newVal){
      //1.判断前后日期都存在并且后面大于前面
      //2.重新加载echart
      if(this.value1 !== '' && this.value1 !== undefined && this.value1 !== null &&
          newVal !== ''&& newVal !== undefined && newVal !== null &&
          newVal > this.value1){

        const start_time = this.value1
        const end_time = newVal
        const response = await getComChangeVisitPension(start_time,end_time)
        if(response.code === 0){
          this.postData = response.data
        }else {
          this.$message.warning("没有数据，" + response.message)
        }

        const responseCount = await getComChangeVisitCountDay(start_time,end_time)
        if(responseCount.code === 0){
          this.responseCounts = responseCount.data
        }else {
          this.$message.warning("没有数据，" + responseCount.message)
        }

        this.rotation()
      }

    },
    async value3(newVal){
      //1.判断前后日期都存   在并且后面大于前面
      //2.重新加载echart

      if(newVal !== '' && newVal !== undefined && newVal !== null &&
          this.value4 !== '' && this.value4 !== undefined && this.value4 !== null &&
          this.value4 > newVal){

        const start_time = newVal
        const end_time = this.value4
        //社区(选择日期访问)访问人次
        const visitCount = await getComChangeVisitCount(start_time,end_time)
        if(visitCount.code === 0){
          this.postDataTwo = visitCount.data
          this.rotations()
        }else {
          this.$message.warning("没有数据，" + visitCount.message)
        }
      }
    },
    async value4(newVal){
      //1.判断前后日期都存在并且后面大于前面
      //2.重新加载echart
      if(this.value3 !== '' && this.value3 !== undefined && this.value3 !== null &&
          newVal !== ''&& newVal !== undefined && newVal !== null &&
          newVal > this.value3){

        const start_time = this.value3
        const end_time = newVal
        //社区(选择日期访问)访问人次
        const visitCount = await getComChangeVisitCount(start_time,end_time)
        if(visitCount.code === 0){
          this.postDataTwo = visitCount.data
          this.rotations()
        }else {
          this.$message.warning("没有数据，" + visitCount.message)
        }
      }

    },
    async value5(newVal){
      //1.判断日期存在
      //2.重新加载echart

      if(newVal !== undefined && newVal !== null && newVal !== ''){

        const year = newVal
        const visitMonthCounts = await getComMonthCount(year)
        if(visitMonthCounts.code === 0){
          this.visitMonthCount = visitMonthCounts.data
          this.rotationMouths()
        }else {
          this.$message.warning("没有数据，" + visitMonthCounts.message)
        }

      }
    },

  },
  async mounted() {

    const result = await getCommunityContentDay()
    if(result.code === 0){
      this.lists = result.data
    }else {
      this.$message.warning(result.message)
    }

    //社区访问总人数
    const pensions = await getComVisitPensions()
    if(pensions.code === 0){
      this.renNum = pensions.data[0]
    }else {
      this.$message.warning(pensions.message)
    }

    //社区访问总人次
    const pensionCounts = await getConVisitCount()
    if(pensionCounts.code === 0){
      this.renCount = pensionCounts.data[0]
    }else {
      this.$message.warning(pensionCounts.message)
    }

    //社区今日访问人数
    const dayVisits = await getComDayVisitPension()
    if(dayVisits.code === 0){
      this.renVisit = dayVisits.data[0]
    }else {
      this.$message.warning(dayVisits.message)
    }
    await this.formData()
    //初始值,获取到的当前时间和七天前时间
    const start_time = this.afterData
    const end_time = this.nowData

    // 社区(选择日期访问)访问人数
    // 调取接口
    const response = await getComChangeVisitPension(start_time,end_time)
    if(response.code === 0){
      this.postData = response.data
    }else {
      this.$message.warning(response.message)
    }
    // 社区(选择日期访问)访问人次
    //新增人次人数在一个折线图上显示两条数据
    const responseCount = await getComChangeVisitCountDay(start_time,end_time)
    if(responseCount.code === 0){
      this.responseCounts = responseCount.data
    }else {
      this.$message.warning(responseCount.message)
    }


    //社区(选择日期访问)访问人次
    const visitCount = await getComChangeVisitCount(start_time,end_time)
    if(visitCount.code === 0){
      this.postDataTwo = visitCount.data
    }else {
      this.$message.warning(visitCount.message)
    }

    //社区每月访问人次
    this.getLastMonthDays()
    const year = this.startTime
    const visitMonthCounts = await getComMonthCount(year)
    if(visitMonthCounts.code === 0){
      this.visitMonthCount = visitMonthCounts.data
    }else {
      this.$message.warning(visitMonthCounts.message)
    }

    //社区每月访问人数
    const visitMonthPens = await getComMonthPens(year)
    if(visitMonthPens.code === 0){
      this.visitMonthPen = visitMonthPens.data
    }else {
      this.$message.warning(visitMonthPens.message)
    }

    this.rotation()
    this.rotations()
    this.rotationMouths()
  },
  methods: {
    //人数折线图
    rotation(){
      let drawLine = this.$echarts.init(document.getElementById('visitPensions'))
      let option = {
        legend: {},
        tooltip: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        dataset: [
          {
            source: this.postData
          },
          {
            source: this.responseCounts
          }
        ],
        xAxis: {
          type: 'category',
          axisLabel: {
            rotate:60,
            interval: 0,
          }
        },
        yAxis: {
          type: 'value',
        },
        series:[
          {
            datasetIndex:0,
            name:'访问人数',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:1,
            name:'访问人次',
            type:'line',
            smooth: true,
          }
        ]
      };

      drawLine.setOption(option);
    },
    //人次折线图
    rotations(){
      let drawLine = this.$echarts.init(document.getElementById('visitCount'))
      let option = {
        legend: {

        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        tooltip: {},
        dataset: {
          source: this.postDataTwo
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
        },
        series:[
          {
            name:'访问人次',
            type:'line',
            smooth: true,
          }
        ]
      };

      drawLine.setOption(option);
    },

    rotationMouths(){
      let drawLine = this.$echarts.init(document.getElementById('visitWeek'))
      let option = {
        legend: {
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        tooltip: {

        },
        dataset: [
          {
            source: this.visitMonthCount
          },
          {
            source: this.visitMonthPen
          }
        ],
        xAxis: {
          type: 'category',
          axisLabel: {
            rotate:60,
            interval: 0,
          }
        },
        yAxis: {
          type: 'value',
        },
        series:[
          {
            datasetIndex:0,
            name:'访问人次',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:1,
            name:'访问人数',
            type:'line',
            smooth: true,
          }
        ]
      };

      drawLine.setOption(option);
    },

    //得到开始时间
    getTime1(){
      console.log(this.value1)
    },

    //得到结束时间
    getTime2(){
      console.log(this.value2)
    },

    //时间选择器失去焦点，判断输入的开始时间是否为有效
    async lose1() {

    },

    //时间选择器失去焦点，判断输入的结束时间是否为有效
    async lose2(){
      // if(this.value2 === null || this.value2 === undefined || this.value2 === ''){
      //   alert('请选择结束时间')
      // }
      if(this.value2 < this.value1){
        alert('请选择有效时间')
        this.value2 = null
      }

    },

    //得到开始时间
    getTime3(){
      console.log(this.value3)
    },

    //得到结束时间
    getTime4(){
      console.log(this.value4)
    },

    //时间选择器失去焦点，判断输入的开始时间是否为有效
    async lose3(){

    },

    //时间选择器失去焦点，判断输入的结束时间是否为有效
    async lose4(){
      // if(this.value4 === null || this.value4 === undefined || this.value4 === ''){
      //   alert('请选择结束时间')
      // }
      if(this.value4 < this.value3){
        alert('请选择有效时间')
        this.value4 = null
      }

    },

    getTime5(){
      console.log(this.value5)
    },
    async lose5(){
      // if(this.value5 === null || this.value5 === undefined || this.value5 === ''){
      //   alert('请选择结束时间')
      // }
    },

    // 获取当前时间和七天前时间
    formData(){
      const t =moment().format('YYYY-MM-DD')
      this.nowData = t
      const t2 = moment()
      const ago = t2.subtract(7,'days').format('YYYY-MM-DD')
      this.afterData = ago
    },
    getLastMonthDays() {
      this.startTime = moment().format('YYYY')
    },
  },



}
</script>

<style lang="scss" scoped>
.communityContentData{
  width: 100%;
  height: auto;
  .communityModule-three-top-time-heng{
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .communityModule-one{
    width: 94%;
    height: 180px;
    margin: 15px auto;
    border-radius: 20px;
    box-shadow: 0 0 10px #0a84ff;
    .communityModule-one-top{
      width: 100%;
      height: 30px;
      display: flex;
      .communityModule-one-top-left{
        width: 40%;
        height: 30px;
        line-height: 30px;
        margin-top: 5px;
        margin-left: 10px;
        font-size: 16px;
        color: #0a84ff;
      }
    }
    .communityModule-one-second{
      width: 100%;
      height: 140px;
      margin-top: 30px;
      display: flex;
      .second-module{
        width: 33%;
        height: 100%;
        .second-module-top1{
          height: 70px;
          line-height:70px;
          width: 70px;
          background-color:#FF6161;
          border-radius: 50px;
          margin: 0 auto;
          text-align: center;
          color: white ;
          font-size: 18px;
        }
        .second-module-top2{
          height: 70px;
          line-height:70px;
          width: 70px;
          background-color:#58D0FF;
          border-radius: 50px;
          margin: 0 auto;
          text-align: center;
          color: white ;
          font-size: 18px;
        }
        .second-module-top3{
          height: 70px;
          line-height:70px;
          width: 70px;
          background-color:#FFC935;
          border-radius: 50px;
          margin: 0 auto;
          text-align: center;
          color: white ;
          font-size: 18px;
        }
        .second-module-bottom{
          font-size: 12px;
          text-align: center;
          height: 40px;
          line-height: 40px;
        }
      }
    }
  }
  .communityModule-three{
    width: 94%;
    height: auto;
    margin: 15px auto;
    border-radius: 20px;
    box-shadow: 0 0 10px #0a84ff;
    .communityModule-three-font{
      padding: 10px;
      color: #0a84ff;
    }
    .communityModule-three-top{
      width: 100%;
      display: flex;
      padding: 10px;
      .communityModule-three-top-time{

      }
    }
    .communityModule-three-middle{
      width: 100%;
      height: 300px;
    }
    .communityModule-three-bottom{
      width: 100%;
      height: auto;
    }


  }
  .communityModule-four{
    width: 94%;
    height: auto;
    margin: 15px auto;
    border-radius: 20px;
    box-shadow: 0 0 10px #0a84ff;
    .communityModule-four-font{
      padding: 10px;
      color: #0a84ff;
    }
    .communityModule-four-top{
      width: 100%;
      display: flex;
      padding: 10px;
      .communityModule-four-top-time{

      }
    }
    .communityModule-four-middle{
      width: 100%;
      height: 300px;
    }
    .communityModule-four-bottom{
      width: 100%;
      height: auto;
    }
  }
  .communityModule-five{
    width: 94%;
    height: auto;
    margin: 15px auto;
    border-radius: 20px;
    box-shadow: 0 0 10px #0a84ff;
    .communityModule-five-font{
      padding: 10px;
      color: #0a84ff;
    }
    .communityModule-five-top{
      width: 100%;
      display: flex;
      padding: 10px;
      .communityModule-five-top-time{

      }
    }
    .communityModule-five-middle{
      width: 100%;
      height: 300px;
    }
    .communityModule-five-bottom{
      width: 100%;
      height: auto;
    }
  }
}
</style>