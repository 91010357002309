<template>
  <!--  职场考试数据分析-->
  <div class="Magazine">
    <!--    数据总览统计-->
    <div class="head">
      <div style="padding: 10px">
        <span style="font-size: 18px;color: dodgerblue;">总览统计</span></div>
      <div class="balls">
        <div class="d1">
          <p class="ball" style="background-color: #FF6666">{{homeAccess}}</p>
          <span style="font-size: smaller">本月主页访问总人数</span>
        </div>
        <div class="d1">
          <p class="ball" style="background-color: #FF9966">{{contentAccess}}</p>
          <span style="font-size: smaller">本月内容访问总人数</span>
        </div>
        <div class="d1">
          <p class="ball" style="background-color: #00CCFF">{{homeToday}}</p>
          <span style="font-size: smaller">今日主页访问人数</span>
        </div>
        <div class="d1">
          <p class="ball">{{contentToday}}</p>
          <span style="font-size: smaller">今日内容访问人数</span>
        </div>
      </div>
    </div>
    <!--    访问内容Top10-->
    <div class="Magazine_table">
      <div style="border-bottom: dodgerblue 1px solid;text-align: center;font-size: large;color: dodgerblue;padding: 10px">
        <span>访问内容Top10</span>
      </div>
      <div>
        <el-table
            :data="contentTopTen"
            stripe
            fit
            style="width: 100%;margin: 0 auto">
          <el-table-column
              prop="title"
              label="标题"
              show-overflow-tooltip>
          </el-table-column>
          <el-table-column
              prop="num"
              label="人数"
              align="center"
              width="50%">
          </el-table-column>
          <el-table-column
              type="index"
              label="排行"
              width="50%"
              align="center">
            <template slot-scope="scope" >
              <div v-if="scope.$index === 0" style="width: 100%;background-color: #FF3333;color: white">
                {{ scope.$index+1 }}</div>
              <div v-if="scope.$index === 1" style="width: 100%;background-color: #FF6666;color: white">
                {{ scope.$index+1 }}</div>
              <div v-if="scope.$index === 2" style="width: 100%;background-color: #CCCC33;color: white">
                {{ scope.$index+1 }}</div>
              <div v-if="scope.$index >2" style="width: 100%;background-color: #DDDDDD;color: white">
                {{ scope.$index+1 }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--    每日访问-->
    <div class="Magazine_date">
      <p style="font-size: 18px;color: dodgerblue;padding: 10px">访问量(日)</p>
      <div style="display:flex;justify-content: center;padding: 10px;margin: 0 auto">
        <el-date-picker
            v-model="dayStart"
            type="date"
            size="small"
            @blur="lose1"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
        </el-date-picker> -
        <el-date-picker
            v-model="dayEnd"
            type="date"
            size="small"
            @blur="lose2"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
        </el-date-picker>
      </div>
      <div id="date_line" style="height: 350px;width: 95%;margin: 0 auto"></div>
      <div style="display:flex;width: 100%">
        <div style="width: 65%">
          <el-table
              :data="day"
              stripe
              fit
              empty-text=" "
              style="width: 100%;margin: 0 auto">
            <el-table-column
                prop="date_num"
                label="时间"
            >
            </el-table-column>
            <el-table-column
                prop="count"
                align="center"
                label="访问人次">
            </el-table-column>
          </el-table>
        </div>
        <div style="width: 35%">
          <el-table
              :data="day1"
              stripe
              fit
              empty-text=" "
              style="width: 100%;margin: 0 auto">
            <el-table-column
                prop="count"
                align="center"
                label="访问人数">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!--    每月访问-->
    <div class="Magazine_date">
      <p style="font-size: 18px;color: dodgerblue;padding: 10px">访问量(月)</p>
      <div style="display: flex;justify-content: center;padding: 10px">
        <el-date-picker
            v-model="moonStart"
            type="year"
            value-format="yyyy"
            placeholder="选择年">
        </el-date-picker>
      </div>
      <div id="moon_line" style="height: 350px;width: 95%;margin: 0 auto"></div>
      <div style="display:flex;width: 100%">
        <div style="width: 65%">
          <el-table
              :data="moon"
              stripe
              fit
              empty-text=" "
              style="width: 100%;margin: 0 auto">
            <el-table-column
                prop="date_num"
                label="时间">
            </el-table-column>
            <el-table-column
                prop="count"
                label="访问人次"
                align="center">
            </el-table-column>
<!--            <div slot="empty" style="text-align: right;">暂无数据</div>-->
          </el-table>
        </div>
        <div style="width: 35%">
          <el-table
              :data="moon1"
              stripe
              fit
              empty-text=" "
              style="width: 100%;margin: 0 auto">
            <el-table-column
                prop="count"
                align="center"
                label="访问人数">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import {
  getContentAccess,
  getContentToday,
  getContentTopTen,
  getDayNumberTime,
  getDayTime,
  getHomeAccess,
  getHomeToday,
  getMoonNumberTime,
  getMoonTime
} from "../../service/examAnalysis_api";

export default {
  name: "ExamAnalysis",
  data(){
    return{
      // 日期选择
      dayStart:'',
      dayEnd:'',
      moonStart:'',

      homeAccess:'',
      contentAccess:'',
      homeToday:'',
      contentToday:'',
      contentTopTen:[],
      // 访问量日，月表格
      day:[],
      day1:[],
      moon:[],
      moon1:[],
    }
  },
  async created() {
    const  HomeAccess = await getHomeAccess()
    this.homeAccess = HomeAccess.data
    const  ContentAccess = await getContentAccess()
    this.contentAccess = ContentAccess.data
    const  HomeToday = await getHomeToday()
    this.homeToday = HomeToday.data
    const  ContentToday = await getContentToday()
    this.contentToday = ContentToday.data
    const  ContentTopTen = await getContentTopTen()
    this.contentTopTen = ContentTopTen.data


    this.getQuerycheckList();
  },
  mounted() {
    this.myChart()
    this.getTime()
    this.getTime1()
    this.getMoonTime()
  },
  watch:{
    // 访问日
    async dayStart(newVal) {
      if(newVal !== '' && newVal !== undefined && newVal !== null &&
          this.dayEnd !== '' && this.dayEnd !== undefined && this.dayEnd !== null
          && newVal > this.dayEnd
      ){
        const data = {
          start_time: newVal,
          end_time: this.dayEnd,
        };
        const dayTime= await getDayTime(data)
        this.day =dayTime.data

        const dayNumber =await getDayNumberTime(data)
        this.day1 = dayNumber.data
        this.myChart()}


    },
    async dayEnd(newVal) {
      if (newVal !== '' && newVal !== undefined && newVal !== null && newVal > this.dayStart ){
        const data = {
          start_time: this.dayStart,
          end_time: newVal,
        };
        const dayTime= await getDayTime(data)
        this.day =dayTime.data

        const dayNumber =await getDayNumberTime(data)
        this.day1 = dayNumber.data
        this.myChart()}
    },
    // 访问月
    async moonStart(){
      const data = {
        year:this.moonStart,
      };

      const moonTime = await getMoonTime(data)
      this.moon = moonTime.data

      const moonNumber =await getMoonNumberTime(data)
      this.moon1 =moonNumber.data
      this.myChart()
    }
  },
  methods:{
    lose1(){
      // console.log(this.dayStart)
    },
    lose2(){
      // console.log(this.dayEnd)
      if(this.dayEnd < this.dayStart){
        alert('请选择有效日期！')
        this.dayEnd = ''
      }
    },
    // 默认日期
    getTime () {
      let date = new Date()
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m;
      let d = date.getDate()
      d = d < 10 ? ('0' + d) : d;
      this.dayEnd = y + '-' + m + '-' + d
    },
    getTime1 () {
      const now = new Date();
      const date = new Date(now.getTime() - 7 * 24 * 3600 * 1000);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      this.dayStart=year + '-' + month + '-' + day;
    },
    // 默认年份
    getMoonTime () {
      let date = new Date()
      this.moonStart = date.getFullYear().toString()
    },
    // 选择时间
    async getQuerycheckList() {
      const data = {
        start_time: this.dayStart,
        end_time: this.dayEnd,
        year: this.moonStart
      };
      const dayTime= await getDayTime(data)
      this.day =dayTime.data

      const dayNumber =await getDayNumberTime(data)
      this.day1 = dayNumber.data
      const moonTime = await getMoonTime(data)
      this.moon = moonTime.data

      const moonNumber =await getMoonNumberTime(data)
      this.moon1 =moonNumber.data
      // await getDayTime(data).then((res) => {
      //   console.log(res);
      //   this.day = res.data;
      //   console.log(this.day)
      // });
    },

    myChart(){
      const date_line = echarts.init(document.getElementById('date_line'));
      const moon_line = echarts.init(document.getElementById('moon_line'));
      // 访问日
      let day = {
        title:{
          show:Object.keys(this.day).length === 0 && Object.keys(this.day1).length === 0,
          text:'暂无数据',
          textStyle:{
            color:'grey',
            fontsize:14
          },
          left:'center',
          top:'center'
        },

        dataset:[
          {source:this.day},
          {source: this.day1}
        ],
        legend: {
          data: ['访问人次', '访问人数']
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            datasetIndex:0,
            name:'访问人次',
            type: 'line',
            smooth:true,
          },
          {
            name:'访问人数',
            datasetIndex:1,
            type: 'line',
            smooth:true
          }
        ]
      };
      // 访问月
      const moon = {
        title:{
          show:Object.keys(this.day).length === 0 && Object.keys(this.day1).length === 0,
          text:'暂无数据',
          textStyle:{
            color:'grey',
            fontsize:14
          },
          left:'center',
          top:'center'
        },
        dataset:[
          {source:this.moon},
          {source: this.moon1}
        ],
        legend: {
          data: ['访问人次', '访问人数']
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            datasetIndex:0,
            name:'访问人次',
            type: 'line',
            smooth:true,
          },
          {
            name:'访问人数',
            datasetIndex:1,
            type: 'line',
            smooth:true
          }
        ]
      };
      date_line.setOption(day);
      moon_line.setOption(moon);
    },

  }
}
</script>

<style lang="scss">
.Magazine{
  background-color: white;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
.head{
  width: 98%;
  margin: 0 auto;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 0 3px dodgerblue;
}
.balls{
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.d1{
  width:70px;
  text-align:center;
}
.ball{
  width: 100%;
  height: 70px;
  background-color: deepskyblue;
  border-radius: 90px;
  text-align: center;
  line-height: 70px;
  font-size:larger;
}
a{
  font-size: 0.1rem;
}

.Magazine_table{
  width: 98%;
  margin: 10px auto;
  padding-bottom: 5px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 0 3px dodgerblue;
}
.Magazine_date{
  width: 98%;
  margin: 0 auto;
  padding-bottom: 5px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 0 3px dodgerblue;
}
.el-year-table td .cell,.el-month-table td .cell{
  font-size: 14px;
}
//年月选中/滑过样式
.el-year-table td .cell:hover, .el-year-table td.current:not(.disabled) .cell,.el-year-table td.today .cell,
.el-month-table td .cell:hover, .el-month-table td.current:not(.disabled) .cell,.el-month-table td.today .cell{
  color: #fff !important;
  background-color: #01ccff !important;
  border-radius: 4px !important;
  font-size: 15px !important;
  width: 60px;
  text-align: center;
}
</style>
