<template>
  <div class="conferenceBroadcast">
    <div class="conferenceBroadcast-top">
      <van-overlay class="overZhe" :show="show" @click="show" :custom-style="{backgroundColor: '#3278cd' }">
        <div class="wrapper">
          <van-loading color="#fff" />
        </div>
      </van-overlay>
    </div>
    <!--    会议直播总览-->
    <div class="conferenceBroadcast-one">
      <div class="conference-one-font">会议直播总览</div>
      <div class="conference-one-rad">
<!--        <div class="conference-one-rad-one">0</div>-->
        <div class="conference-one-rad-two">{{((watchSumShi.count)/3600).toFixed(0)}}</div>
        <div class="conference-one-rad-three">{{watchSumPon.count}}</div>
        <!--        类型转换，取整数部分-->
        <div class="conference-one-rad-four">{{((watchSumShi.count/watchSumPon.count)/60).toFixed(0)}}</div>
        <div class="conference-one-rad-five">{{maxPon.count}}</div>
      </div>
      <div class="conference-one-bottom">
<!--        <div class="conference-one-bottom-one">访问总人数</div>-->
        <div class="conference-one-bottom-one">观看总时长(h)</div>
        <div class="conference-one-bottom-two">观看总人数</div>
        <div class="conference-one-bottom-three">平均观看时长(m)</div>
        <div class="conference-one-bottom-four">峰值人数</div>
      </div>
      <div class="watch-top">直播间列表</div>
      <div class="watch-content" v-for="(item,index) in items" :key="index">
        <router-link :to="{path:'watchBroadcast',query:{id:item.id}}" tag="div">
          <div class="watch-List">
            <div class="watch-List-one">{{item.id}}</div>
            <div class="watch-List-two">{{item.title}}</div>
            <div class="look">查看</div>
          </div>
        </router-link>
      </div>
    </div>
    <!--    观看人数统计省份-->
    <div class="conferenceBroadcast-two">
      <div class="two-top">
        <div class="conference-two-font">观看人数统计(省份)</div>
        <div class="conference-two-btn">
          <select v-model="list" style="width: 70px;height: 25px;border: #0a84ff solid 1px;color: #888888">
            <option>5</option>
            <option>10</option>
          </select>
        </div>
      </div>
      <!--        中国地图-->
      <div id="watchShewing" style="width: 100%;height: 300px"></div>
      <!--      表格-->
      <el-table
          :data="AddPro"
          stripe
          style="width: 95%;border-radius: 20px;margin: 0 auto"
      >
        <el-table-column
            prop="name"
            label="省份"
            align="center"
        ></el-table-column>
        <el-table-column
            prop="value"
            label="人数"
            align="center"
        ></el-table-column>
        <el-table-column
            label="占比"
            align="center"
        >
          <template slot-scope="scope">
            <div>{{Math.round((scope.row.value/watchSumPon.count)*100) + '%'}}</div>
          </template>
        </el-table-column>
      </el-table>

    </div>
    <!--    观看人数统计医院-->
    <div class="conferenceBroadcast-three">
      <div class="three-top">
        <div class="conference-three-font">观看人数统计(医院)</div>
        <div class="conference-three-btn">
          <select v-model="list3" style="width: 70px;height: 25px;border: #0a84ff solid 1px;color: #888888">
            <option>5</option>
            <option>10</option>
          </select>
        </div>
      </div>
      <!--      折线图-->
      <div id="watchYi" style="width: 100%;height: 300px"></div>
      <!--      表格-->
      <el-table
          :data="AddDro"
          stripe
          style="width: 95%;border-radius: 20px;margin: 0 auto"
      >
        <el-table-column
            prop="value"
            label="医院"
            align="center"
        ></el-table-column>
        <el-table-column
            prop="count"
            label="人数"
            align="center"
        ></el-table-column>
        <el-table-column
            label="占比"
            align="center"
        >
          <template slot-scope="scope">
            <div>{{Math.round((scope.row.count/watchSumPon.count)*100) + '%'}}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--    观看人数统计科室-->
    <div class="conferenceBroadcast-four">
      <div class="four-top">
        <div class="conference-four-font">观看人数统计(科室)</div>
        <div class="conference-four-btn">
          <select v-model="list4" style="width: 70px;height: 25px;border: #0a84ff solid 1px;color: #888888">
            <option>5</option>
            <option>10</option>
          </select>
        </div>
      </div>
      <!--      折线图-->
      <div id="watchKe" style="width: 100%;height: 300px"></div>
      <!--   表格   -->
      <el-table
          :data="AddKe"
          stripe
          style="width: 95%;border-radius: 20px;margin: 0 auto"
      >
        <el-table-column
            prop="value"
            label="科室"
            align="center"
        >
          <template slot-scope="scope">
            <div>{{scope.row.value===""?"暂无":scope.row.value}}</div>
          </template>
        </el-table-column>
        <el-table-column
            prop="count"
            label="人数"
            align="center"
        ></el-table-column>
        <el-table-column
            label="占比"
            align="center"
        >
          <template slot-scope="scope">
            <div>{{Math.round((scope.row.count/watchSumPon.count)*100) + '%'}}</div>
          </template>
        </el-table-column>
      </el-table>
    </div >
    <!--    观看人数统计身份-->
    <div class="conferenceBroadcast-five">
      <div class="five-top">
        <div class="conference-five-font">观看人数统计(身份)</div>
        <div class="conference-five-btn">
          <select v-model="list5" style="width: 70px;height: 25px;border: #0a84ff solid 1px;color: #888888">
            <option>5</option>
            <option>10</option>
          </select>
        </div>
      </div>
      <!--      折线图-->
      <div id="watchShen" style="width: 100%;height: 300px"></div>
      <!-- 表格     -->
      <el-table
          :data="AddShen"
          stripe
          style="width: 95%;border-radius: 20px;margin: 0 auto"
      >
        <el-table-column
            prop="value"
            label="身份"
            align="center"
        ></el-table-column>
        <el-table-column
            prop="count"
            label="人数"
            align="center"
        ></el-table-column>
        <el-table-column
            label="占比"
            align="center"
        >
          <template slot-scope="scope">
            <div>{{Math.round((scope.row.count/watchSumPon.count)*100) + '%'}}</div>
          </template>
        </el-table-column>
      </el-table>
    </div >
    <!--    观看人数统计职称-->
    <div class="conferenceBroadcast-six">
      <div class="six-top">
        <div class="conference-six-font">观看人数统计(职称)</div>
        <div class="conference-six-btn">
          <select v-model="list6" style="width: 70px;height: 25px;border: #0a84ff solid 1px;color: #888888">
            <option>5</option>
            <option>10</option>
          </select>
        </div>
      </div>
      <!--      折线图-->
      <div id="watchZhi" style="width: 100%;height: 300px"></div>
      <!--  表格    -->
      <el-table
          :data="AddZhi"
          stripe
          style="width: 95%;border-radius: 20px;margin: 0 auto"
      >
        <el-table-column
            prop="value"
            label="职称"
            align="center"
        ></el-table-column>
        <el-table-column
            prop="count"
            label="人数"
            align="center"
        ></el-table-column>
        <el-table-column
            label="占比"
            align="center"
        >
          <template slot-scope="scope">
            <div>{{Math.round((scope.row.count/watchSumPon.count)*100) + '%'}}</div>
          </template>
        </el-table-column>
      </el-table>
    </div >
  </div>
</template>

<script>

import {
  getLookMeetWithSeed,
  getMeetBigPen,
  getMeetWatchSumDro, getMeetWatchSumDroTu,
  getMeetWatchSumKe, getMeetWatchSumKeTu, getMeetWatchSumPen,
  getMeetWatchSumPro, getMeetWatchSumProTu, getMeetWatchSumShen, getMeetWatchSumShenTu,
  getMeetWatchSumTime, getMeetWatchSumZhi, getMeetWatchSumZhiTu,

} from "../service/api";
import chinaJson from "../json/china.json";
import {ListPushPro, ListPushQiTa} from '../utils/myUtils'
export default {
  data(){
    return{
      //其他
      tableLose:[
        {name:'其他',num1:'',num2:''}
      ],
      watchSumShi:[],
      watchSumPon:[],
      maxPon:[],
      //查询会议对应的直播间
      items:[],
      //平均观看时长
      savWatch:'',
      //占比
      zhanBi:'',
      //省份
      AddPro:[],
      //医院
      AddDro:[],
      //科室
      AddKe:[],
      //职称
      AddZhi:[],
      //身份
      AddShen:[],

      //省份
      AddProTu:[],
      //医院
      AddDroTu:[],
      //科室
      AddKeTu:[],
      //职称
      AddZhiTu:[],
      //身份
      AddShenTu:[],
      //地图滑动显示数据
      dataList:[
        { name: "广西", value: 1500},
        { name: "山东省", value: 59,},
        { name: "海南省", value: 14, }
      ],
      show:true,
      //  下拉菜单
      list:'5',
      list3:'5',
      list4:'5',
      list5:'5',
      list6:'5'
    }
  },
  watch:{
    //省份
    async list(newVal){
      const limit = newVal
      const id = this.$route.query.id
      const AddPros = await getMeetWatchSumPro(id,limit)
      if(AddPros.code === 0){
        let AddPro = AddPros.data
        if(AddPro === "" || AddPro === undefined || AddPro === null ){
          alert('当前列表/ID没有内容，请重新选择！')
          this.$router.back()
        }
        //ListPushQiTa重复利用，直接封装了遍历数组
        let sum = ListPushPro(AddPro);
        sum = this.watchSumPon.count - sum
        if (sum>0){
          //向集合中添加其他一行
          AddPro.push({name:"其他",value:sum})
        }
        this.AddPro = AddPro
      }else {
        alert('code不为0或者当前列表没有内容，请重新选择！')
        this.$router.back()
      }
    },
    //医院
    async list3(newVal){
      // console.log(newVal)
      const limit = newVal
      const id = this.$route.query.id
      const AddDros = await getMeetWatchSumDro(id,limit)
      let AddDro = AddDros.data
      if(AddDro === "" || AddDro === undefined || AddDro === null ){
        alert('当前列表/ID没有内容，请重新选择！')
        this.$router.back()
      }
      //ListPushQiTa重复利用，直接封装了遍历数组
      let sum2 = ListPushQiTa(AddDro);
      //向集合中添加其他一行
      sum2 = this.watchSumPon.count - sum2
      if (sum2>0){
        //向集合中添加其他一行
        AddDro.push({value:"其他",count:sum2})
      }
      this.AddDro = AddDro
    },
    //科室
    async list4(newVal){
      // console.log(newVal)
      const limit = newVal
      const id = this.$route.query.id
      const AddKes = await getMeetWatchSumKe(id,limit)
      let AddKe = AddKes.data
      if(AddKe === "" || AddKe === undefined || AddKe === null ){
        alert('当前列表/ID没有内容，请重新选择！')
        this.$router.back()
      }
      //ListPushQiTa重复利用，直接封装了遍历数组
      let sum3 = ListPushQiTa(AddKe);
      //向集合中添加其他一行
      if (sum3>0){
        //向集合中添加其他一行
        sum3 = this.watchSumPon.count - sum3
      }
      this.AddKe = AddKe
    },
    //身份
    async list5(newVal){
      // console.log(newVal)
      const limit = newVal
      const id = this.$route.query.id
      const AddShens = await getMeetWatchSumShen(id,limit)
      let AddShen = AddShens.data
      if(AddShen === "" || AddShen === undefined || AddShen === null ){
        alert('当前列表/ID没有内容，请重新选择！')
        this.$router.back()
      }
      //ListPushQiTa重复利用，直接封装了遍历数组
      let sum = ListPushQiTa(AddShen);
      //向集合中添加其他一行
      sum = this.watchSumPon.count - sum
      if (sum>0){
        //向集合中添加其他一行
        AddShen.push({value:"其他",count:sum})
      }
      this.AddShen = AddShen
    },
    //职称
    async list6(newVal){
      // console.log(newVal)
      const limit = newVal
      const id = this.$route.query.id
      const AddZhis = await getMeetWatchSumZhi(id,limit)
      let AddZhi = AddZhis.data
      if(AddZhi === "" || AddZhi === undefined || AddZhi === null ){
        alert('当前列表/ID没有内容，请重新选择！')
        this.$router.back()
      }
      //ListPushQiTa重复利用，直接封装了遍历数组
      let sum3 = ListPushQiTa(AddZhi);
      //向集合中添加其他一行
      sum3 = this.watchSumPon.count - sum3
      if (sum3>0){
        //向集合中添加其他一行
        AddZhi.push({value:"其他",count:sum3})
      }
      this.AddZhi = AddZhi
    },
  },
  created() {

  },
  async mounted() {
    //观看总时长
    await this.watchSumTime()
    //观看总人数
    await this.watchSumPons()
    //峰值人数
    this.maxPons()

    //查询会议对应的直播间
    this.LookMeetWithSeed()
    //省份
    await this.watchAddPros()
    //判断页面数据全部加载完成
    this.$nextTick(() =>{
      this.show = false
    })
    //医院
    this.watchAddDro()
    //科室
    this.watchAddKe()
    //职称
    this.watchAddZhi()
    //身份
    this.watchAddShen()

    //省份图
    this.watchAddProsTu()

    //医院图
    this.watchAddDroTu()
    //科室图
    this.watchAddKeTu()
    //职称图
    this.watchAddZhiTu()
    //身份图
    this.watchAddShenTu()



  },
  methods:{
    //观看总时长
    async watchSumTime() {
      const id = this.$route.query.id
      const watchSumTimes = await getMeetWatchSumTime(id)
      this.watchSumShi = watchSumTimes.data[0]
      let watchSumShi = this.watchSumShi
      if(watchSumShi === "" || watchSumShi === undefined || watchSumShi === null ){
        alert('当前列表/ID没有内容，请重新选择！')
        this.$router.back()
      }
    },
    //观看总人数
    async watchSumPons() {
      const id = this.$route.query.id
      const watchSumPons = await getMeetWatchSumPen(id)
      this.watchSumPon = watchSumPons.data[0]
      let watchSumPon = this.watchSumPon
      if(watchSumPon === "" || watchSumPon === undefined || watchSumPon === null ){
        alert('当前列表/ID没有内容，请重新选择！')
        this.$router.back()
      }
    },
    //峰值人数
    async maxPons() {
      const id = this.$route.query.id
      const maxPons = await getMeetBigPen(id)
      this.maxPon = maxPons.data[0]
      let maxPon = this.maxPon
      if(maxPon === "" || maxPon === undefined || maxPon === null ){
        alert('当前列表/ID没有内容，请重新选择！')
        this.$router.back()
      }
    },
    //查询会议对应的直播间
    async LookMeetWithSeed(){
      const id = this.$route.query.id
      const meetWithSeeds = await getLookMeetWithSeed(id)
      this.items = meetWithSeeds.data
      let items = this.items
      if(items === "" || items === undefined || items === null ){
        alert('当前列表/ID没有内容，请重新选择！')
        this.$router.back()
      }
    },
    //省份
    async watchAddPros() {
      const limit = 5
      const id = this.$route.query.id
      const AddPros = await getMeetWatchSumPro(id,limit)
      let AddPro = AddPros.data
      if(AddPro === "" || AddPro === undefined || AddPro === null ){
        alert('当前列表/ID没有内容，请重新选择！')
        this.$router.back()
      }
      //ListPushQiTa重复利用，直接封装了遍历数组
      let sum = ListPushPro(AddPro);
      //向集合中添加其他一行
      sum = this.watchSumPon.count - sum
      if (sum>0){
        //向集合中添加其他一行
        AddPro.push({name:"其他",value:sum})
      }
      this.AddPro = AddPro
      //加载echart
    },
    //医院
    async watchAddDro() {
      const limit = 5
      const id = this.$route.query.id
      const AddDros = await getMeetWatchSumDro(id,limit)
      let AddDro = AddDros.data
      if(AddDro === "" || AddDro === undefined || AddDro === null ){
        alert('当前列表/ID没有内容，请重新选择！')
        this.$router.back()
      }
      //ListPushQiTa重复利用，直接封装了遍历数组
      let sum2 = ListPushQiTa(AddDro);
      //向集合中添加其他一行
      sum2 = this.watchSumPon.count - sum2
      if (sum2>0){
        //向集合中添加其他一行
        AddDro.push({value:"其他",count:sum2})
      }
      this.AddDro = AddDro
    },
    //科室
    async watchAddKe() {
      const limit = 5
      const id = this.$route.query.id
      const AddKes = await getMeetWatchSumKe(id,limit)
      let AddKe = AddKes.data
      if(AddKe === "" || AddKe === undefined || AddKe === null ){
        alert('当前列表/ID没有内容，请重新选择！')
        this.$router.back()
      }
      //ListPushQiTa重复利用，直接封装了遍历数组
      let sum3 = ListPushQiTa(AddKe);
      //向集合中添加其他一行
      sum3 = this.watchSumPon.count - sum3
      if (sum3>0){
        //向集合中添加其他一行
        AddKe.push({value:"其他",count:sum3})
      }
      this.AddKe = AddKe
    },
    //职称
    async watchAddZhi() {
      const limit = 5
      const id = this.$route.query.id
      const AddZhis = await getMeetWatchSumZhi(id,limit)
      let AddZhi = AddZhis.data
      if(AddZhi === "" || AddZhi === undefined || AddZhi === null ){
        alert('当前列表/ID没有内容，请重新选择！')
        this.$router.back()
      }
      //ListPushQiTa重复利用，直接封装了遍历数组
      let sum3 = ListPushQiTa(AddZhi);
      //向集合中添加其他一行
      sum3 = this.watchSumPon.count - sum3
      if (sum3>0){
        //向集合中添加其他一行
        AddZhi.push({value:"其他",count:sum3})
      }
      this.AddZhi = AddZhi
    },
    //身份
    async watchAddShen() {
      const limit = 5
      const id = this.$route.query.id
      const AddShens = await getMeetWatchSumShen(id,limit)
      let AddShen = AddShens.data
      if(AddShen === "" || AddShen === undefined || AddShen === null ){
        alert('当前列表/ID没有内容，请重新选择！')
        this.$router.back()
      }
      //ListPushQiTa重复利用，直接封装了遍历数组
      let sum = ListPushQiTa(AddShen);
      //向集合中添加其他一行
      sum = this.watchSumPon.count - sum
      if (sum>0){
        //向集合中添加其他一行
        AddShen.push({value:"其他",count:sum})
      }
      this.AddShen = AddShen
    },

    //  省份图
    async watchAddProsTu() {
      const id = this.$route.query.id
      const AddProsTu = await getMeetWatchSumProTu(id)
      this.AddProTu = AddProsTu.data
      let AddProTu = this.AddProTu
      if(AddProTu === "" || AddProTu === undefined || AddProTu === null ){
        alert('当前列表/ID没有内容，请重新选择！')
        this.$router.back()
      }
      await this.rotationProsTu()
    },
    rotationProsTu(){
      let drawLine = this.$echarts.init(document.getElementById('watchShewing'))
      this.$echarts.registerMap('china',chinaJson)
      let option = {
        tooltip: {
          formatter:function(params){
            return params.seriesName+'<br />'+params.name+'：'+params.value
          }//数据格式化
        },
        visualMap: {
          min: 0,
          max: 1500,
          left: 'left',
          top: 'bottom',
          text: ['H','L'],

          inRange: {
            color: ['#e2fafc', '#0c73dc']
          },
          show:true
        },
        geo: {
          map: 'china',
          roam: true,
          zoom:1.23,
          label: {
            normal: {
              show: false,
              fontSize:'8',
              color: 'rgba(0,0,0,0.7)'
            }
          },
          itemStyle: {
            normal:{
              borderColor: 'rgba(0, 0, 0, 0.2)',
              areaColor: '#fff'
            },
            emphasis:{
              areaColor: '#F3B329',
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        },
        series : [
          {
            name: '访问人数',
            type: 'map',
            geoIndex: 0,
            data:this.AddProTu,
          }
        ]
      };
      drawLine.setOption(option);
      // drawLine.on('click', function (params) {
      //   alert(params.name);
      // });

    },
    //医院图
    async watchAddDroTu() {
      const limit = 10
      const id = this.$route.query.id
      const AddDrosTu = await getMeetWatchSumDroTu(id,limit)
      this.AddDroTu = AddDrosTu.data
      let AddDroTu = this.AddDroTu
      if(AddDroTu === "" || AddDroTu === undefined || AddDroTu === null ){
        alert('当前列表/ID没有内容，请重新选择！')
        this.$router.back()
      }
      await this.rotationYiTu()
    },
    rotationYiTu(){
      let drawLine = this.$echarts.init(document.getElementById('watchYi'))
      let option = {
        dataset:{
          source:this.AddDroTu
        },
        xAxis: {
          type: 'category',
          // textStyle:{
          //   fontSize: 5
          // },
          axisLabel: {
            rotate:60,
          }
        },
        yAxis: {
          type: 'value'
        },
        series:{
          type:'bar'
        }
        // title: {
        //   left: 'center'
        // },
        // dataset: {
        //   source: this.AddDroTu
        // },
        // series: [
        //   {
        //     type: 'pie',
        //     radius: '80%'
        //   }
        // ]
        // dataset: {
        //   source: this.AddDroTu
        // },
        // grid: {
        //   left:'2%',
        //   right:'5%',
        //   bottom:'4%',
        //   containLabel: true
        // },
        // xAxis: {
        //   //显示横坐标线
        //   show:true,
        // },
        // yAxis: {
        //   type: 'category',
        //   axisLabel:{
        //     textStyle:{
        //       //柱子纵坐标字体大小
        //       fontSize:'8px'
        //     }
        //   }
        // },
        // series: [
        //   {
        //     type: 'bar',
        //     //主子宽度
        //     barWidth:'70%',
        //     //柱子颜色设置
        //     color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{
        //       offset: 0,
        //       color: "#72B2F3FF" // 100% 处的颜色
        //     },{
        //       offset: 1,
        //       color: "#0c73dc" // 0% 处的颜色
        //     }], false),
        //     itemStyle:{
        //       label:{
        //         show: true,
        //         position:'right',
        //         textStyle: {
        //           color:'black',
        //           fontSize: 16
        //         }
        //       }
        //     }
        //   }
        // ]
      };

      drawLine.setOption(option);
    },
    //科室图
    async watchAddKeTu() {
      const id = this.$route.query.id
      const AddKesTu = await getMeetWatchSumKeTu(id)
      this.AddKeTu = AddKesTu.data
      let AddKeTu = this.AddKeTu
      if(AddKeTu === "" || AddKeTu === undefined || AddKeTu === null ){
        alert('当前列表/ID没有内容，请重新选择！')
        this.$router.back()
      }
      await this.rotationShengTu()
    },
    rotationShengTu(){
      let drawLine = this.$echarts.init(document.getElementById('watchKe'))
      let option = {
        title: {
          left: 'center'
        },
        dataset: {
          source: this.AddKeTu
        },
        series: [
          {
            type: 'pie',
            radius: ['20%', '50%'],
          }
        ]
      };

      drawLine.setOption(option);
    },
    //职称图
    async watchAddZhiTu() {
      const id = this.$route.query.id
      const AddZhisTu = await getMeetWatchSumZhiTu(id)
      this.AddZhiTu = AddZhisTu.data
      let AddZhiTu = this.AddZhiTu
      if(AddZhiTu === "" || AddZhiTu === undefined || AddZhiTu === null ){
        alert('当前列表/ID没有内容，请重新选择！')
        this.$router.back()
      }
      await this.rotationZhiTu()
    },
    rotationZhiTu(){
      let drawLine = this.$echarts.init(document.getElementById('watchZhi'))
      let option = {
        title: {
          left: 'center'
        },
        dataset: {
          source: this.AddZhiTu
        },
        series: [
          {
            type: 'pie',
            radius: ['20%', '50%'],
          }
        ]
      };

      drawLine.setOption(option);
    },
    //身份图
    async watchAddShenTu() {
      const id = this.$route.query.id
      const AddShensTu = await getMeetWatchSumShenTu(id)
      this.AddShenTu = AddShensTu.data
      let AddShenTu = this.AddShenTu
      if(AddShenTu === "" || AddShenTu === undefined || AddShenTu === null ){
        alert('当前列表/ID没有内容，请重新选择！')
        this.$router.back()
      }
      await this.rotationShenTu()
    },
    rotationShenTu(){
      let drawLine = this.$echarts.init(document.getElementById('watchShen'))
      let option = {
        title: {
          left: 'center'
        },
        dataset: {
          source: this.AddShenTu
        },
        series: [
          {
            type: 'pie',
            radius: ['20%', '50%'],
          }
        ]
      };

      drawLine.setOption(option);
    },


  },
}
</script>


<style lang="scss" scoped>
.conferenceBroadcast{
  width: 100%;
  height: auto;
  .overZhe{
    //height: 100%;
    //position: absolute;
    //z-index: 9999;
  }
  .conferenceBroadcast-top{
    height: auto;
    width: 100%;
    position: absolute;
    z-index: 5555;
  }
  .conferenceBroadcast-one{
    width: 94%;
    height: auto;
    margin: 15px auto;
    border-radius: 20px;
    box-shadow: 0 0 10px #45A5E6;
    .conference-one-font{
      width: 100%;
      height: 50px;
      line-height: 50px;
      margin-left: 20px;
      color: #45A5E6;
      font-size: 16px;
    }
    .conference-one-rad{
      width: 100%;
      height: 90px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .conference-one-rad-one{
        width: 70px;
        height: 70px;
        background-color: #a06ed1;
        border-radius: 70px;
        text-align: center;
        line-height: 70px;
        color: #fff;
        font-size: 18px;
      }
      .conference-one-rad-two{
        width: 70px;
        height: 70px;
        background-color: #419bfc;
        border-radius: 70px;
        text-align: center;
        line-height: 70px;
        color: #fff;
        font-size: 18px;
      }
      .conference-one-rad-three{
        width: 70px;
        height: 70px;
        background-color: #f34491;
        border-radius: 70px;
        text-align: center;
        line-height: 70px;
        color: #fff;
        font-size: 18px;
      }
      .conference-one-rad-four{
        width: 70px;
        height: 70px;
        background-color: #f3ab44;
        border-radius: 70px;
        text-align: center;
        line-height: 70px;
        color: #fff;
        font-size: 18px;
      }
      .conference-one-rad-five{
        width: 70px;
        height: 70px;
        background-color: #f34444;
        border-radius: 70px;
        text-align: center;
        line-height: 70px;
        color: #fff;
        font-size: 18px;
      }
    }
    .conference-one-bottom{
      width: 100%;
      height: 20px;
      display: flex;
      .conference-one-bottom-one{
        font-size: 12px;
        width: 25%;
        height: 20px;
        line-height: 20px;
        text-align: center;
      }
      .conference-one-bottom-two{
        font-size: 12px;
        width: 25%;
        height: 20px;
        line-height: 20px;
        text-align: center;
      }
      .conference-one-bottom-three{
        font-size: 12px;
        width: 28%;
        height: 20px;
        line-height: 20px;
        text-align: center;
      }
      .conference-one-bottom-four{
        font-size: 12px;
        width: 22%;
        height: 20px;
        line-height: 20px;
        //border: black 1px solid;
        text-align: center;
      }
      //.conference-one-bottom-two{
      //  font-size: 12px;
      //  width: 24%;
      //  height: 20px;
      //  line-height: 20px;
      //  text-align: center;
      //
      //}
    }
    .watch-top{
      width: 95%;
      height: 35px;
      background-color: #f6f6f6;
      line-height: 35px;
      text-align: center;
      font-size: 14px;
      margin: 10px auto;
    }
    .watch-content{
      width: 95%;
      margin-left: 10px;
      .watch-List{
        display: flex;
        width: 100%;
        height: auto;
        border-radius: 20px;
        .watch-List-one{
          width: 8%;
          height: 35px;
          line-height: 35px;
          margin-left: 10px;
          font-size: 12px;
          border-bottom: 1px solid #f6f6f6;

        }
        .watch-List-two{
           width: 80%;
           height: 35px;
          line-height: 35px;
          font-size: 12px;
          border-bottom: 1px solid #f6f6f6;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
         }
        .look{
          width: 8%;
          height: 35px;
          line-height: 35px;
          color: #45A5E6;
          font-size: 12px;
          margin-right: 10px;
        }

      }
    }
  }
  .conferenceBroadcast-two{
    width: 94%;
    height:auto;
    margin: 15px auto;
    border-radius: 20px;
    box-shadow: 0 0 10px #45A5E6;
    .two-top{
      display: flex;
      height: auto;
      .conference-two-font{
        width: 100%;
        height: 50px;
        line-height: 50px;
        margin-left: 20px;
        color: #45A5E6;
        font-size: 16px;
      }
      .conference-two-btn{
        margin: 15px;
      }
    }
  }
  .conferenceBroadcast-three{
    width: 94%;
    height: auto;
    margin: 15px auto;
    border-radius: 20px;
    box-shadow: 0 0 10px #45A5E6;
    .three-top{
      display: flex;
      height: auto;
      .conference-three-font{
        width: 100%;
        height: 50px;
        line-height: 50px;
        margin-left: 20px;
        color: #45A5E6;
        font-size: 16px;
      }
      .conference-three-btn{
        margin: 15px;
      }
    }
  }
  .conferenceBroadcast-four{
    width: 94%;
    height: auto;
    margin: 15px auto;
    border-radius: 20px;
    box-shadow: 0 0 10px #45A5E6;
    .four-top{
      display: flex;
      height: auto;
      .conference-four-font{
        width: 100%;
        height: 50px;
        line-height: 50px;
        margin-left: 20px;
        color: #45A5E6;
        font-size: 16px;
      }
      .conference-four-btn{
        margin: 15px;
      }
    }
  }
  .conferenceBroadcast-five{
    width: 94%;
    height: auto;
    margin: 15px auto;
    border-radius: 20px;
    box-shadow: 0 0 10px #45A5E6;
    .five-top{
      display: flex;
      height: auto;
      .conference-five-font{
        width: 100%;
        height: 50px;
        line-height: 50px;
        margin-left: 20px;
        color: #45A5E6;
        font-size: 16px;
      }
      .conference-five-btn{
        margin: 15px;
      }
    }

  }
  .conferenceBroadcast-six{
    width: 94%;
    height: auto;
    margin: 15px auto;
    border-radius: 20px;
    box-shadow: 0 0 10px #45A5E6;
    .six-top{
      display: flex;
      height: auto;
      .conference-six-font{
        width: 100%;
        height: 50px;
        line-height: 50px;
        margin-left: 20px;
        color: #45A5E6;
        font-size: 16px;
      }
      .conference-six-btn{
        margin: 15px;
      }
    }
  }
}
</style>