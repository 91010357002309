
import {get} from "@/service/base";

/**
 * 时间: 2023-06-14
 * 作者: 韩广志
 * 获取直播间数据
 */
export function getMeetingData (data) {
    return get('/api/admin/liveMettingSpecials',data)
}