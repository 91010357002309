import  {get} from './base'
// 职称考试数据分析api
export function getHomeAccess(){
    return get('/v2/queryTemHomeList')
}
export  function getContentAccess(){
    return get('/v2/queryTemContentList')
}
export  function getHomeToday(){
    return get('/v2/queryTemHometodayList')
}
export  function getContentToday(){
    return get('/v2/queryTemContentTodayList')
}
export  function getContentTopTen(){
    return get('/v2/queryTemContentTopTen')
}
export  function getDayTime(data){
    return get('/v2/queryTemDateManTime',data)
}
export function getDayNumberTime(data){
    return get('/v2/queryTemDateManTimeCount',data)
}
export function getMoonTime(data){
    return get('/v2/queryTemMouthManTime',data)
}
export function getMoonNumberTime(data){
    return get('/v2/queryTemMouthCount',data)
}
