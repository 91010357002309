
//记录用户信息
export async function getAdmins(member_id,channel){
    let type = {
        byId: member_id,
        channel:channel ? channel:'',
        os:'',
        ip:'',
        version:'',
    }
    //取本地中的数据
    type.ip = localStorage.getItem('ip')

    //获取系统版本
    const u = navigator.userAgent;
    // eslint-disable-next-line no-unused-vars
    const app = navigator.appVersion;

    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //Android终端
    const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
    if (isAndroid) {
        // 这个是安卓操作系统
        type.os = 'android'
    }
    if (isIOS) {
        // 这个是ios操作系统
        type.os = 'ios'
    }
    //获取系统版本号
    var ua = navigator.userAgent.toLowerCase();
    var version = null;
    if (ua.indexOf("android") > 0) {
        var reg = /android [\d._]+/gi;
        var v_info = ua.match(reg);
        // version = parseInt(version.split('.')[0]); // 得到版本号第一位
        version = (v_info + "").replace(/[^0-9|_.]/ig, "").replace(/_/ig, "."); //得到版本号
        type.version = version
    }
    if (ua.indexOf("like mac os x") > 0) {
        var regOs = /os [\d._]+/gi;
        var v_infoOs = ua.match(regOs);
        // version = parseInt(version.split('.')[0]); // 得到版本号第一位
        version = (v_infoOs + "").replace(/[^0-9|_.]/ig, "").replace(/_/ig, "."); //得到版本号
        type.version = version
    }
    return type;
}
