<template>
  <div class="compass" ref="personDom">
    <van-nav-bar
      left-arrow
      title="我收藏的文献指南"
      @click-left="onClickLeft"
    />
    <!-- 列表 -->
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getList()"
    >
      <div
        v-for="(list, index) in lists"
        :key="index"
        class="document-compass"
        :class="{ expanded: list.expanded }"
        @click="DocumentDiv(list.content_id, list.url, list.title, list.status)"
      >
        <div class="document-word" style="position:relative">
          <div :style="{ opacity: list.status !== 'normal' ? '0.3' : '1' }">
            <!-- 题目全部显示 -->
            <div class="document-word-title">
              {{ list.title }}
            </div>
            <div class="document-word-content">
              <template v-if="list.describe.length <= 42">{{
                list.describe
              }}</template>
              <template v-else>
                <template v-if="!list.expanded">
                  {{ list.describe.slice(0, 42) }}
                  <!-- 显示部分文本 -->
                  <span v-if="list.describe.length > 42">...</span>
                  <!-- 如果文本长度超过40，显示省略号 -->
                  <span
                    v-if="!list.expanded"
                    class="expand-btn"
                    @click.stop="toggleExpand(index)"
                  >
                    展开
                  </span>
                  <span
                    v-else
                    class="expand-btn"
                    @click.stop="toggleExpand(index)"
                  >
                    收起
                  </span>
                </template>

                <template v-else>
                  {{ list.describe }}
                  <span
                    v-if="!list.expanded"
                    class="expand-btn"
                    @click.stop="toggleExpand(index)"
                  >
                    展开
                  </span>
                  <span
                    v-else
                    class="expand-btn"
                    @click.stop="toggleExpand(index)"
                  >
                    收起
                  </span>
                  <!-- 显示完整文本 -->
                </template>
              </template>
            </div>
            <!-- 专家组超出两行。。。。 -->
            <div
              class="document-word-expert"
              v-if="list.expertList.length !== 0"
            >
              <template v-if="list.expertList.length <= 13">
                专家组：<span v-for="item in list.expertList" :key="item.id"
                  >{{ item.name }}
                </span>
              </template>
              <template v-else>
                <template v-if="!list.export">
                  专家组:<span
                    v-for="item in list.expertList.slice(0, 13)"
                    :key="item.id"
                    >{{ item.name }}&ensp;</span
                  >
                  <span v-if="list.expertList.length > 13">...</span>
                  <span
                    v-if="!list.export"
                    class="expand-btn"
                    @click.stop="toggleExpandExport(index)"
                  >
                    展开
                  </span>
                  <span
                    v-else
                    class="expand-btn"
                    @click.stop="toggleExpandExport(index)"
                  >
                    收起
                  </span>
                </template>
                <template v-else>
                  专家组：<span v-for="item in list.expertList" :key="item.id"
                    >{{ item.name }}&ensp;
                  </span>
                  <span
                    v-if="!list.export"
                    class="expand-btn"
                    @click.stop="toggleExpandExport(index)"
                  >
                    展开
                  </span>
                  <span
                    v-else
                    class="expand-btn"
                    @click.stop="toggleExpandExport(index)"
                  >
                    收起
                  </span>
                </template>
              </template>
            </div>
            <div class="document-word-date">
              <div>{{ list.show_date }} 发布</div>
              <div
                style="color: #199bfb"
                v-if="list.is_read == 0"
                @click.stop="record(list.id, 'click', index)"
              >
                标为已读
              </div>
            </div>
          </div>
          <div class="black" v-if="list.status !== 'normal'"></div>
          <div class="title1" v-if="list.status !== 'normal'">
            内容已下架
          </div>
          <div class="icon" v-if="list.status 
          !== 'normal'">
            <van-icon name="ellipsis" @click.stop="cancel(list)" />
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { getCollect } from "../service/documentCompass2.0_api";
import { delCollection } from "../service/document_api";
import { recordOperation } from "@/utils/myUtils";
import { getAdmins } from "@/utils/adminDate";
import { setById } from "@/service/baseDocument";
import moment from "moment";
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  name: "documentCompassCollect",
  data() {
    return {
      lists: [],
      loading: false,
      finished: false,
      page_no: 1,
      channel: null,
      version: null,
      ip: null,
      count: 0,
      aType: "",
    };
  },
  created() {
    this.aType = localStorage.getItem("helpCenterType");
    if (this.aType === "android" || this.aType === "ios") {
      this.heights = window.innerHeight;
    }
    if(this.$route.query.memberId){
          this.$store.commit('memberIdDocument', this.$route.query.memberId)  
    }
    this.getById()
  },
  activated() {
    document.querySelector(".compass").scrollTop =
      sessionStorage.getItem("scrollTopCollect") || 0;
  },
  methods: {
    toggleExpand(index) {
      this.lists[index].expanded = !this.lists[index].expanded;
    },
    toggleExpandExport(index) {
      this.lists[index].export = !this.lists[index].export;
    },
    handlerScroll() {
      var scrollTop = this.$refs.personDom.scrollTop; //滑入屏幕上方的高度
      var windowHeitht = this.$refs.personDom.clientHeight; //能看到的页面的高度
      var scrollHeight = this.$refs.personDom.scrollHeight; //监控的整个div的高度（包括现在看到的和上下隐藏起来看不到的）
      let total = scrollTop + windowHeitht;
      if (total == scrollHeight) {
        this.page_no++;
        this.getList();
      }
    },
    async getById() {
      //像接口头部传用户信息
      let arrays = await getAdmins(
        this.$store.state.memberIdDocument,
        this.$route.query.channel
      );
      this.channel = this.$route.query.channel;
      this.version = arrays.version;
      this.ip = arrays.ip;
      await setById(arrays);
    },
    async getList() {
      await this.getById();
      const result = await getCollect(this.page_no, 10);
      if (result.code === 0) {
        this.loading = false;
        this.count = result.count;
        let list = result.data;
        list.forEach((item) => {
          item.show_date = item.show_date
            ? moment(item.show_date).format("YYYY年MM月DD日")
            : null;

          item.expanded = false;
          item.export = false;
        });
        this.lists = this.lists.concat(list);

        if (list.length <= this.page_size) {
          this.finished = true;
        }
        if (this.lists.length >= result.count) {
          this.finished = true;
        } else {
          this.finished = false;
          this.page_no += 1;
        }
      }
    },
    async DocumentDiv(id, pdf_url, title, status) {
      await this.getById();
      if (status !== "normal") {
        return;
      }
      //记录点击操作 埋点
      const response = await recordOperation(
        id,
        "DOCUMENT",
        "DOCUMENT_COLLECT_LIST",
        this.aType,
        this.$store.state.memberIdDocument,
        this.version,
        this.$route.query.channel
      );
      if (response.code === 0) {
        this.$router.push({
          path: "/pdfVue",
          query: {
            url: "pdf/web/viewer.html?file=" + pdf_url,
            title: title,
            id: id,
            channel: this.channel,
            memberId: this.$store.state.memberIdDocument,
          },
        });
        let listScrollTop = document.querySelector(".compass").scrollTop;
        sessionStorage.setItem("scrollTopCollect", listScrollTop);
        console.log(listScrollTop);
      }
    },
    onClickLeft() {
      this.$router.back();
    },
    cancel(list) {
      Dialog.confirm({
        title: "确定取消收藏?",
      })
        .then(() => {
          delCollection("DOCUMENT", list.content_id).then((res) => {
            if (res.code == 0) {
              Toast.success("取消成功");
            }
            this.page_no = 1;
            this.lists = [];
            this.getList();
          });
        })
        .catch(() => {
          Toast.fail("取消失败");
        });
    },
    // load() {
    //   if (this.page_no * 10 >= this.count) {
    //     this.finished = true;
    //     return;
    //   } else {
    //     this.page_no++;
    //     this.getList();
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.compass {
  height: 100vh;
  background-color: #f5f5f8;
  overflow: scroll;
}
.document-compass {
  width: 95%;
  background-color: #ffffff;
  margin: 0 auto;
  border-radius: 5px;
  margin-top: 10px;
}
.document-word {
  width: 100%;

  .document-word-title {
    padding-top: 10px;
    width: 95%;
    margin: auto;
    font-family: PingFang-SC-Bold;
    color: #333333;
    font-size: 16px;
    font-weight: 550;
  }
  .document-word-content {
    letter-spacing: 1px;
    position: relative;
    width: 95%;
    margin: auto;
    padding-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #565555;
    display: -webkit-box;
    text-overflow: ellipsis; /*超出内容用省略号*/
    overflow: hidden; /*内容超出后隐藏*/
    -webkit-box-orient: vertical;
  }
  .expand-btn {
    font-size: 12px;
    cursor: pointer;
    color: #66a6d4;
    text-align: right;
    padding-right: 16px;
  }
  .document-word-expert {
    width: 95%;
    margin: auto;
    padding-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #565555;
  }
  .document-word-date {
    width: 95%;
    margin: auto;
    font-size: 12px;
    padding-top: 10px;
    font-weight: 400;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    color: #565555;
  }
}
::v-deep .van-nav-bar .van-icon {
  color: black;
}
.black {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.title1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: 550;
  color: rgb(77, 74, 74);
  background-color:rgb(241, 241, 241);
  padding: 5px 10px;
  border-radius: 15px;
}
.icon {
  position: absolute;
  right: 15px;
  bottom: 10px;
  font-size: 25px;
  color: rgb(94, 90, 90);
}
</style>
