<template>
  <div class="memberInfo">
    <div class="memberImg">
      <img style="border-radius: 7px;width: 100%;height: 260px"  @click="()=>this.picShow =true" v-if="editForm.employee_card_url" :src="editForm.employee_card_url" alt="" >
      <img style="border-radius: 7px;width: 100%;height: 260px;margin-top: 10px" @click="()=>this.picShow =true" v-if="editForm.practice_license_url" :src="editForm.practice_license_url" alt="">
      <img style="border-radius: 7px;width: 100%;height: 260px;margin-top: 10px" @click="()=>this.picShow =true" v-if="editForm.nvq_url" :src="editForm.nvq_url" alt="">
    </div>
    <div class="member_form" style="padding-bottom:40px;">
      <van-form   ref='form'>
        <van-field v-model="editForm.name" label="姓名"  input-align="right" :rules="[{ required: true, message: '请填写姓名' }]"/>
        <van-field label-width="120px" label="医师资格证书编码" v-model ="editForm.doctor_num" input-align="right" />
        <van-field  label="医院" v-model ="editForm.hospital_name" input-align="right" :rules="[{ required: true, message: '请填写医院' }]"/>
        <van-field
            readonly
            clickable
            name="picker"
            :value="sex"
            label="性别"
            placeholder="请选择"
            :is-link="true"
            input-align="right"
            @click="showPicker = true"

        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
              show-toolbar
              :columns="sexColumns"
              @confirm="onSex"
              value-key="label"
              @cancel="showPicker = false"
          />
        </van-popup>
        <van-field
            readonly
            clickable
            name="datetime"
            :value="editForm.birthday"
            label="出生年月"
            :is-link="true"
            placeholder="请选择"
            input-align="right"

            @click="showDate = true"
        />
        <van-popup v-model="showDate" position="bottom">
          <van-datetime-picker
              @confirm="onDate"
              @cancel="showDate = false"
              type="date"
              title="选择年月日"
              :min-date="minDate"
          />
        </van-popup>
        <van-field
            readonly
            clickable
            name="datetime"
            :value="editForm.education"
            label="学历"
            placeholder="请选择"
            :is-link="true"
            input-align="right"

            @click="showBA = true"
        />
        <van-popup v-model="showBA" position="bottom">
          <van-picker
              show-toolbar
              :columns="BA"
              @confirm="onBA"
              @cancel="showBA = false"
          />
        </van-popup>
        <van-field v-model="editForm.school" label="毕业学校"  input-align="right" />
        <van-field v-model="editForm.specialty" label="专业"  input-align="right" />
        <van-field
            readonly
            clickable
            name="datetime"
            :value="status"
            label="身份（类别）"
            placeholder="请选择"
            :is-link="true"
            input-align="right"
            :rules="[{ required: true, message: '请选择身份' }]"
            @click="showStatus = true"
        />
        <van-popup v-model="showStatus" position="bottom">
          <van-picker
              show-toolbar
              :columns="statusColumns"
              value-key="label"
              @confirm="onStatus"
              @cancel="showStatus = false"
          />
        </van-popup>
      </van-form>
    </div>
    <div class="footer" >
      <van-button class="footBtn" @click="()=>this.rejectShow=true" style="color: red" type="default" >驳回</van-button>
      <van-button class="footBtn"  type="info"  @click="editOk" >保存</van-button>
      <van-button class="footBtn" type="default" style="color: dodgerblue" @click="memberOk">通过</van-button>
    </div>


    <van-dialog v-model="rejectShow" title="驳回原因"  show-cancel-button confirmButtonText="提交" @confirm="rejectOk" confirmButtonColor="dodgerblue">
      <div style="display: flex;justify-content: center">
        <textarea cols="30" rows="3" style="border: 1px solid #ccc;border-radius: 3px;"
                  v-model="message" />
      </div>

    </van-dialog>
    <van-image-preview v-model="picShow" :images="pics" >

    </van-image-preview>
  </div>
</template>

<script>
import moment from "moment/moment";
import {getMemberInfo, putMemberInfo, putMemberInfoOK, putMemberRejected} from "../../service/memberAudit_api";
import { Toast} from "vant";
export default {
  name: "memberInfo",
  data(){
    return{
      picShow:false,
      minDate: new Date(1940, 0, 1),
      showDate:false,
      showBA:false,
      rejectShow:false,
      showPicker:false,
      showStatus:false,
      editForm:{

      },
      message:'',
      sexColumns:[
        {
          value:'1',
          label:'男'
        },
        {
          value:'2',
          label:'女'
        },
      ],
      BA:[
        '高中','大专','大学本科','研究生'
      ],
      statusColumns:[
        {
          value:'1001',
          label:'医生'
        },
        {
          value:'1002',
          label:'护士'
        },
        {
          value:'1003',
          label:'医技'
        },
        {
          value:'1004',
          label:'学生'
        },
        {
          value:'1005',
          label:'其他医务从业者'
        },
        {
          value:'1006',
          label:'非医务从业者'
        },
      ],
      sex:null,
      status:'',
      pics:[],
    }
  },
  mounted() {
    this.getMemberInfos(this.$route.query.user_id)
  },
  methods:{
    //获取会员信息
    async getMemberInfos(user_id){
      const Info = await getMemberInfo(user_id)
      if (Info.code ===0){
        this.editForm = Info.data[0]
        switch (Info.data[0].type){
          case 1001:
            this.status = '医生'
            break
          case 1002:
            this.status = '护士'
            break
          case 1003:
            this.status = '医技'
            break
          case 1004:
            this.status = '学生'
            break
          case 1005:
            this.status = '其他医务从业者'
            break
          case 1006:
            this.status = '非医务从业者'
            break
        }
        this.editForm.birthday = moment(Info.data[0].birthday).format('YYYY-MM-DD')
        switch (Info.data[0].sex){
          case 0:
            this.sex = ''
            break
          case 1:
            this.sex = '男'
            break
          case 2:
            this.sex = '女'
            break
        }
      }
    },

    //保存按钮
    editOk() {
      this.$refs.form.validate().then(async () => {
        const user_id =this.$route.query.user_id
        const id = this.$route.query.id
        const edit = this.editForm
        const info = await putMemberInfo(user_id,id,edit)
        if (info.code === 0){
          this.$toast.success('保存成功')
          await this.getMemberInfos(this.$route.query.user_id)
        }
      }).catch(() => {

      })
    },
    //驳回原因提交按钮
    async rejectOk(){
      const data = {
        remark : this.message
      }
      const Info = await putMemberRejected(this.$route.query.id,data)
      if (Info.code === 0){
        Toast.success('已驳回');
        setTimeout(() => {
              this.$router.push({ name: `memberAudit`})}
            , 1000);
      }
    },
    //通过按钮
    async memberOk(){
      console.log(3)
      const Info = await putMemberInfoOK(this.$route.query.id,this.$route.query.user_id)
      if (Info.code === 0){
        Toast.success('已通过');
        setTimeout(() => {
              this.$router.push({ name: `memberAudit`})}
            , 1000);
      }
    },
    //选择出生年月
    onDate(time){
      this.editForm.birthday = moment(time).format("YYYY-MM-DD")
      this.showDate = false
    },
    //学历
    onBA(value){
      this.editForm.education = value
      this.showBA = false
    },
    //选择身份
    onStatus(value){
      this.status = value.label
      this.editForm.type = value.value
      this.showStatus =false
    },
    //性别
    onSex(value) {
      this.sex = value.label
      this.editForm.sex = value.value
      this.showPicker = false;
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .van-field__error-message{
  float: right;
}
.memberInfo{
  position:relative;
  width: 100%;
  .memberImg{
    width: 92%;
    margin: 10px auto;
    border-radius: 7px;
  }
  .footer{
    height:40px;
    width: 100%;
    position:fixed;
    bottom:0;
    left:0;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .footBtn{
      width: 40%;
      height: 40px;
    }
  }
}
</style>
