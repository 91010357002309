<template>
  <div class="medical container" :style="{height:aType === 'android'||aType ==='ios'?heights+'px':''}">
<!--    <div style="position: absolute;top: 0;z-index: 99999;">-->
      <div class="headers">
        <div @click="openSelect('year')">
          <span :class="{selectStyle:selectStyles === 'year'}">{{ search_year ==='' ?'年份':search_year }} <i class="el-icon-caret-bottom"></i></span></div>
        <div @click="openSelect('form')">
          <span :class="{selectStyle:selectStyles === 'form'}">{{ search_form ==='' ?'来源':search_form }} <i class="el-icon-caret-bottom"></i></span></div>
        <div @click="openSelect('sort')">
          <span :class="{selectStyle:selectStyles === 'sort'}">{{ search_sort ==='' ?'综合排序':(search_sort === 'show_date'?'综合排序':'热门排序') }} <i class="el-icon-caret-bottom"></i></span></div>
      </div>
      <div style="display: flex;">
        <van-search
            class="search"
            show-action
            background="#F5F5F8"
            v-model="search_value"
            placeholder="请输入内容快速查找"
            @cancel="cancel"
            @search="onSearch" />
        <!--      <div @click="cancel" class="cancel">取消</div>-->
      </div>
<!--    </div>-->
<!--    <div style="height: 85px"></div>-->
    <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :offset="offset"
        @load="getList">
      <div  class="medical-compass" v-for="count in counts" :key="count.id" @click="MedicalDiv(count,count.id)">
<!--        <router-link :to="{path:'MedicalJournalCon',query:{url:count.url}}">-->
          <!--        列表内容-->
          <div class="list-content">

            <div v-for="(item,index) in imgList"
                 :key="index+new Date().getTime()">
              <img
                  v-if="buleTrue(item.id,count)"
                  class="document-word-img"
                  :src="item.img">
            </div>

            <div v-for="imgs in imgLists"
                 :key="imgs.id+new Date().getTime()">
              <img
                  v-if="orangeTrue(imgs.id,count)"
                  style="width: 37px;right: -1px"
                  class="document-word-img"
                  :src="imgs.img">
            </div>

            <div class="medical-word">
              <div class="medical-word-title">
                <span ><van-tag v-if="count.is_top" type="danger" size="large">置顶</van-tag> {{count.title}}</span>
              </div>
              <!--            <div v-if="count.describe" class="medical-word-content">来源：{{count.describe}}</div>-->
              <div class="medical-word-content">
                <div style="display: flex;justify-content: space-between">
<!--                  <div><span style="color: #545454">时间：</span>{{count.show_date}}刊</div>-->
                  <div><span style="color: #545454">时间：</span>{{count.show_date}}</div>
                  <div>
                    <van-icon name="eye-o" /> {{count.readCount ? count.readCount :0}}
                  </div>
                </div>
              </div>
              <div v-if="count.author" class="medical-word-content"><span style="color: #545454">来源：</span>
                <!--              {{count.author}}-->
                {{count.name}}
              </div>
              <div v-if="count.name" class="medical-word-content"><span style="color: #545454">作者：</span>
                <!--              {{count.name}}-->
                {{count.author}}
              </div>
              <div v-if="count.describe" class="medical-word-describe">
                {{count.describe}}
              </div>
            </div>
          </div>
<!--        </router-link>-->
      </div>
    </van-list>
    <!----------------------------------------对话框----------------------->
    <van-popup
        v-model="show"
        position="bottom"
        :style="{ height: '40%',width:'100%',position:aType === 'android'||aType ==='ios'?'':'absolute' }"
        @close="()=>{this.selectStyles = ''}">
      <div style="display: flex;margin-top: 10px;justify-content: space-around">
        <div @click="confirmFalse" style="font-size: 12px;color: #4e4e4e">取消</div>
        <div style="font-size: 14px;">选择{{popupTitle}}</div>
        <div @click="confirmTrue" style="font-size: 12px;color: #45A5E6">确定</div>
      </div>
      <div class="popupList">
        <div
            @click="selectPopup(item)"
            class="popupList-list"
            v-for="(item,index) in popupList"
            :key="index">
          {{item}}
        </div>
      </div>
    </van-popup>
  </div>
</template>


<script>
import {getMedicalJournal,magazineYearGrouping,magazineNameGrouping} from '../service/api'
import {setById} from "@/service/base";
import {getAdmins} from "@/utils/adminDate";
import {recordOperation} from "@/utils/myUtils";
// eslint-disable-next-line no-unused-vars
import moment from "moment";
export default {
  name: 'MedicalJournal',
  data() {
    return {
      imgList:[
        {
          id:'01',
          img:require("../assets/mothIcon/bule1.png")
        }, {
          id:'02',
          img:require("../assets/mothIcon/bule2.png")
        }, {
          id:'03',
          img:require("../assets/mothIcon/bule3.png")
        }, {
          id:'04',
          img:require("../assets/mothIcon/bule4.png")
        }, {
          id:'05',
          img:require("../assets/mothIcon/bule5.png")
        }, {
          id:'06',
          img:require("../assets/mothIcon/bule6.png")
        }, {
          id:'07',
          img:require("../assets/mothIcon/bule7.png")
        }, {
          id:'08',
          img:require("../assets/mothIcon/bule8.png")
        }, {
          id:'09',
          img:require("../assets/mothIcon/bule9.png")
        }, {
          id:'10',
          img:require("../assets/mothIcon/bule10.png")
        }, {
          id:'11',
          img:require("../assets/mothIcon/bule11.png")
        }, {
          id:'12',
          img:require("../assets/mothIcon/bule12.png")
        },
      ],
      imgLists:[
        {
          id:'01',
          img:require("../assets/mothIcon/orange1.png")
        }, {
          id:'02',
          img:require("../assets/mothIcon/orange2.png")
        }, {
          id:'03',
          img:require("../assets/mothIcon/orange3.png")
        }, {
          id:'04',
          img:require("../assets/mothIcon/orange4.png")
        }, {
          id:'05',
          img:require("../assets/mothIcon/orange5.png")
        }, {
          id:'06',
          img:require("../assets/mothIcon/orange6.png")
        }, {
          id:'07',
          img:require("../assets/mothIcon/orange7.png")
        }, {
          id:'08',
          img:require("../assets/mothIcon/orange8.png")
        }, {
          id:'09',
          img:require("../assets/mothIcon/orange9.png")
        }, {
          id:'10',
          img:require("../assets/mothIcon/orange10.png")
        }, {
          id:'11',
          img:require("../assets/mothIcon/orange11.png")
        }, {
          id:'12',
          img:require("../assets/mothIcon/orange12.png")
        },
      ],
      show:false,
      selectStyles:'',
      popupList:[],
      counts: [],
      search_value:'',
      search_year:'',
      search_form:'',
      search_sort:'',

      title_year:'',
      title_form:'',
      title_sort:'',
      page_no:1,
      page_size:10,
      title_value:[],
      channel:null,
      version:null,
      ip:null,
      popupTitle:null,
      persisted:false,

      loading: false,
      finished: false,
      offset:60,
      aType:'',
      heights:0,
    }
  },
  created() {
    //  获取本地存储的系统
    this.aType = localStorage.getItem('helpCenterType')
    if(this.aType === 'android'||this.aType ==='ios'){
      this.heights = window.innerHeight
    }

    var browserRule = /^.*((iPhone)|(iPad)|(Safari))+.*$/;
    if (browserRule.test(navigator.userAgent)) {
      window.onpageshow = function(event) {
        if (event.persisted) {
          console.log(event.persisted)
          window.location.reload()
        }
      }
    }
  },
  async mounted() {

    // const handleReappear = async (event) => {
    //   const { performance } = window
    //   const { navigation } = performance || {}
    //   const { type } = navigation || {}
    //   const historyTraversal = event.persisted || type === 2
    //
    //   if (historyTraversal) {
    //     this.persisted = historyTraversal
    //     location.reload()
    //   }
    // }
    // window.addEventListener('pageshow', handleReappear)

    // await this.test()
    // if(!this.persisted){
    //   this.getList()
    // }
    // addEventListener('scroll', this.handleScroll)//监听函数
  },
  // watch:{
  //
  // },
  methods:{
    async test(){
      //像接口头部传用户信息
      let arrays = await getAdmins(this.$route.query.memberId,this.$route.query.channel)
      this.channel = arrays.os
      this.version = arrays.version
      this.ip = arrays.ip
      await setById(arrays)
    },
    async getList() {
      await this.test()
      if(this.search_sort === '综合排序'){
        this.search_sort = 'show_date'
      }else if(this.search_sort === '热门排序'){
        this.search_sort = 'browse_count'
      }
      let data = {
        page_no:this.page_no,
        page_size:this.page_size,
        title:this.search_value,
        show_date:this.search_year,
        name:this.search_form,
        judgment:this.search_sort,
      }
      const result = await getMedicalJournal(data)
      if (result.code === 0) {
        this.loading = false;
        let list = result.data;
        this.counts = this.counts.concat(list);

        if (list.length <= this.page_size) {
          this.finished = true;
        }
        if (this.counts.length >= result.count) {
          this.finished = true;
        } else {
          this.finished = false;
          this.page_no += 1;
        }
      }
    },

    async MedicalDiv(row,id){
      await recordOperation(id,'MAGAZINE','MAGAZINE_LIST',this.channel,this.$route.query.memberId,this.version,this.$route.query.channel)
      //判断路径中是否有http
      let url = row.url;
      if(url.substr(0,7).toLowerCase() == "http://" || url.substr(0,8).toLowerCase() == "https://"){
        // eslint-disable-next-line no-self-assign
        url = url;
      }else{
        url = "http://" + url;
      }
      console.log(url)
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid = userAI.indexOf('Android') > -1 || userAI.indexOf('Linux') > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(url)
        this.getList()
      }
      else if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = url
        this.counts = []
      }
      else {
        window.open(url)
      }
    },
    onSearch(value) {
      this.search_value = value
      //清空列表，否则会出现重复
      this.counts = []
      this.page_no = 1
      this.getList()
    },
    cancel(){
      this.counts = []
      this.page_no = 1
      this.search_value = ''
      this.search_year = ''
      this.search_form = ''
      this.search_sort = ''
      this.getList()
    },
    // 判断滚动条是否滚动到底部
    async handleScroll() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;

      const scrollHeights = scrollHeight - scrollTop //滑动总高度
      const clientHeight = windowHeight //滑动最低高度
      if(scrollHeights < clientHeight + 2){
        this.page_no++
        await this.getList()
      }
    },
    openSelect(value){
      if(value === 'year'){
        this.selectStyles = 'year'
        this.popupTitle = '年份'
        this.magazineYear()
      }else if(value === 'form'){
        this.selectStyles = 'form'
        this.popupTitle = '来源'
        this.magazineName()
      }else if(value === 'sort'){
        this.popupTitle = '综合排序'
        this.selectStyles = 'sort'
        this.popupList = ['综合排序','热门排序']
      }
      this.show = true
    },
    //杂志年份列表
    async magazineYear(){
      const response = await magazineYearGrouping()
      if(response.code === 0) {
        this.popupList = response.data
      }
    },
    //杂志来源列表
    async magazineName(){
      const response = await magazineNameGrouping()
      if(response.code === 0) {
        this.popupList = response.data
      }
    },
    selectPopup(item) {
      let selectStyles = this.selectStyles
      if (selectStyles === 'year') {
        this.search_year = item
      } else if (selectStyles === 'form') {
        this.search_form = item
      } else if (selectStyles === 'sort') {
        this.search_sort = item
      }
      // this.show = false
    },
    async confirmFalse() {
      let value = this.selectStyles
      if (value === 'year') {
        this.search_year = ''
      } else if (value === 'form') {
        this.search_form = ''
      } else if (value === 'sort') {
        this.search_sort = ''
      }
      this.counts = []
      this.page_no = 1
      await this.getList()
      this.show = false
    },
    async confirmTrue() {
      this.counts = []
      this.page_no = 1
      await this.getList()
      this.show = false
    },
    //截取月份字符串
    getMouthString(date){
      if(date.length === 7){ // 2023年2月
        return '0'+date.slice(5,6)
      }else if(date.length === 8){  //2023年12月
        return date.slice(5,7)
      }
      // return data.slice(5,7)
    },
    //获取当前月份
    getMouth(){
      const timeOne = new Date()
      let month = timeOne.getMonth() + 1
      month = month < 10 ? '0' + month : month + ''
      return month
    },
    //  判断当前日期是否为本月
    mouthTrue(data){
      /*判断当前选择日期是否为本月代码*/
      let now=new Date()
      let today=now.toISOString().substring(0,7)
      today = moment(today).format('YYYY年M月')+'刊'
      if(today === data){
        return true
      }else{
        return false
      }
    },
    buleTrue(data,list){ //蓝色图标，
      //1，当前月份是不是和图片月份相同   2，列表月份是否和图片月份相同
      // console.log( data , this.getMouthString(list.show_date))
      return data === this.getMouthString(list.show_date.slice(0,-1))

    },
    // eslint-disable-next-line no-unused-vars
    orangeTrue(data,list){ //橙色图标 只有当前年份的当前月份才可以展示
      //data :图片月份  list列表数据
      //  1.当前月份是否和图片月份相同，2判断列表月份是否为当前月份，3.当前月份是否和列表月份相同
      return this.getMouth() === data && this.mouthTrue(list.show_date)
      // return this.getMouth() === data && this.mouthTrue(list.show_date) && this.getMouth() === this.getMouthString(list.show_date)
    }
  }
};
</script>
<style lang="scss">
.medical{
  width: 100%;
  //min-height: 900px;
  background-color: #F5F5F8;
  position: absolute;
  .headers{
    width: 100%;
    display: flex;
    height: 35px;
    line-height: 35px;
    justify-content: space-around;
    background-color: #fff;
    font-size: 12px;
    color: #878686;
    .selectStyle{
      color: #45A5E6;
    }
  }
  .van-search__content{
    background-color: #fff !important;
  }
  .search{
    width: 100%;
    background-color: #F8F4F8;
  }
  .medical-compass{
    background-color: #ffffff;
    //margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 10px solid #F5F5F8;
    .list-content{
      //padding: 15px;
      position: relative;
      .document-word-img{
        width: 35px;
        height: auto;
        position: absolute;
        right: 0;
        z-index: 1;
      }
    }
  }

  .medical-img{
  }
  .medical-word{
    width: 95%;
    margin: auto;
    //.document-word-img{
    //  width: 30px;
    //  height: auto;
    //  position: absolute;
    //  right: 0;
    //  z-index: 1;
    //}
    .medical-word-title{
      width: 100%;
      display: -webkit-box;
      text-overflow: ellipsis;  /*超出内容用省略号*/
      overflow: hidden; /*内容超出后隐藏*/
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3; /*3行*/
      font-family: PingFang-SC-Bold;
      color:#333333;
      font-size: 18px;
      font-weight: 540;
      padding-top: 20px;
    }
    .medical-word-content{
      width: 99%;
      display: -webkit-box;
      text-overflow: ellipsis;  /*超出内容用省略号*/
      overflow: hidden; /*内容超出后隐藏*/
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1; /*1行*/
      font-family: Adobe Heiti Std R;
      font-size: 14px;
      //margin-left: 5px;
      margin-top: 10px;
      color: #B2B2B2;
    }
    .medical-word-describe{
      width: 99%;
      display: -webkit-box;
      text-overflow: ellipsis;  /*超出内容用省略号*/
      overflow: hidden; /*内容超出后隐藏*/
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3; /*3行*/
      font-family: Adobe Heiti Std R;
      font-size: 14px;
      margin-top: 10px;
      color: #333333;
    }
  }
}
.popupList{
  width: 100%;
  height: 300px;
  overflow-y: scroll;
  margin-top: 5px;
  .popupList-list{
    height: 50px;
    line-height: 50px;
    width: 100%;
    text-align: center;
    border-bottom:1px solid #f5f5f5;
  }
  .popupList-list:hover{
    background-color: #eef8fd;
  }

}
</style>
