<template>
  <div class="registerCollect">
    <div class="register">
      <!-- 注册总览-->
      <div class="register-top">
        <div class="register-top-font">总览统计</div>
        <!--4个圈  -->
        <div class="register-top-content">
<!--          圈-->
          <div class="register-top-content-one">
            <div class="quin" style="background-color: #a06ed1;">{{ sumPen.count }}</div>
            <div class="quin" style="background-color: #f3ab44;">{{ todayYearRegister.count }}</div>
            <div class="quin" style="background-color: #f34491;">{{todayMonthRegister.count}}</div>
            <div class="quin" style="background-color: #419bfc;">{{dayRegister.count}}</div>
          </div>
<!--          字-->
          <div class="register-top-content-two">
            <div class="font">总人数</div>
            <div class="font">今年注册量</div>
            <div class="font">本月注册量</div>
            <div class="font">今日注册量</div>
          </div>
        </div>
      </div>
      <!-- 内容切换-->
      <div class="register-content">
        <div style="height: 15px"></div>
          <div style="width: 98%">
            <van-tabs  title-active-color="#45a5e6"   color="#419bfc" :line-width="100"  animated>
              <van-tab title="时 间" name="a">
                  <timeChild></timeChild>
              </van-tab>
              <van-tab title="地 区" name="b">
                <area-child></area-child>
              </van-tab>
              <van-tab title="身 份" name="c">
                <statusChild></statusChild>
              </van-tab>
            </van-tabs>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getRegisterSum, getTodayMonthRegister, getTodayRegister, getTodayYearRegister} from "../service/api";
import timeChild from "../ramousVue/timeChild";
import statusChild from "../ramousVue/statusChild";
import AreaChild from "../ramousVue/areaChild";
export default {
  components:{
    AreaChild,
    timeChild,
    statusChild
  },
  data(){
    return{
      //总人数
      sumPen:[],
      //今年注册量
      todayYearRegister:[],
      //本月注册量
      todayMonthRegister:[],
      //今日注册量
      dayRegister:[],
    }
  },
  methods:{
  //总人数
    async sumPens(){
      const sumPens = await getRegisterSum()
      this.sumPen = sumPens.data[0]
    },
    //今年注册量
    async YearRegister(){
      const todayYearRegisters = await getTodayYearRegister()
      this.todayYearRegister = todayYearRegisters.data[0]
    },
    //本月注册量
    async monthRegister(){
      const todayMonthRegisters = await getTodayMonthRegister()
      this.todayMonthRegister = todayMonthRegisters.data[0]
    },
    //今日注册量
    async registerToday(){
      const dayRegisters = await getTodayRegister()
      this.dayRegister = dayRegisters.data[0]
    }
  },
  mounted() {
    this.sumPens()
    this.YearRegister()
    this.monthRegister()
    this.registerToday()
  }
}
</script>

<style lang="scss" scoped >

  .registerCollect{
    width: 100%;
    height: auto;
    .register{
      width: 94%;
      height: auto;
      margin: 15px auto;
      border-radius: 20px;
    }
    .register-top{
      width: 100%;
      height: 160px;
      border-radius: 20px;
      box-shadow: 0 0 10px #0a84ff;
      .register-top-font{
        width: 100%;
        height: 50px;
        line-height: 50px;
        margin-left: 20px;
        color: #45A5E6;
        font-size: 14px;
      }
      .register-top-content{
        margin-top: 10px;
        .register-top-content-one{
          display: flex;
          justify-content: space-around;
          .quin{
            width: 60px;
            height: 60px;
            border-radius: 50px;
            text-align: center;
            line-height: 60px;
            color: white;
            font-size: 14px;
          }
        }
        .register-top-content-two{
          display: flex;
          .font{
            width: 25%;
            text-align: center;
            margin-top: 10px;
            font-size: 12px;
          }
        }
      }
    }
    .register-content{
      width: 100%;
      height: auto;
      margin-top: 10px;
      border-radius: 20px;
      box-shadow: 0 0 10px #0a84ff;
      //.tabs-title{
      //  border-radius: 20px;
      //  margin-top: 10px;
      //}
    }
  }
</style>