<template>
  <div class="professional">
    <van-row v-for="row in rows" :key="row.id" class="professional-compass" @click="professionalDiv(row.url,row.id)">
      <van-col class="myList">
        <!--    我的列表内容       -->
        <van-row class="list-content" >
          <van-col span="1">
            <div></div>
          </van-col>
          <van-col class="professional-img" span="5">
            <van-image
                fit="contain"
                :src="row.cover[0]"
                lazy-load
            />
          </van-col>
          <van-col span="1">
            <div></div>
          </van-col>
          <van-col class="professional-word" span="17">
            <div class="professional-word-title">{{row.title}}</div>
            <div class="professional-word-intro">{{row.intro}}</div>
            <div class="professional-word-time">{{row.release_time}}</div>
          </van-col>
        </van-row>
        <!--    下划线       -->
        <van-row class="underline">
          <van-divider />
        </van-row>

      </van-col>

    </van-row>
  </div>
</template>


<script>
import {getProfessionalExam} from '../service/examApi'
import {postCommunityView} from "../service/api";

export default {
  name: 'professionalExam',
  async created(){
    //获取URL地址中member_id的值
    const member_id = this.$route.query.memberId;
    //调取接口获文献列表
    const result = await getProfessionalExam(member_id)
    if (result.code === 0 ){
      //将文献列表赋值
      this.rows = result.data.rows

      //post要在获取接口之后写,判断member_id不为空
      if(member_id !== null && member_id !== undefined  && member_id !== ""){
        //获取职称考试列表:TOOL_EXAMS

        //调接口后都判断一下接口中的code是否为0，否时显示提示错误信息
        const response = await postCommunityView("TOOL_EXAMS",member_id);
        if (response.code === 0){
          console.log("行为获取失败")
        }
      }else {
        console.log('文献详情没有post:type和member_id')
      }
    }else {
      console.log(1)
    }


  },
  data() {
    return {
      rows:'',
      url:'',
      cover:''
    }
  },
  methods:{
    async professionalDiv(u,row_id){
      const member_id = this.$route.query.memberId;
      //将row_id强制转换为int类型
      let row_id_int = parseInt(row_id)
      if(member_id !== null && member_id !== undefined  && member_id !== ""){

        //获取职称考试详情:TOOL_EXAM_DETAIL

        //调接口后都判断一下接口中的code是否为0，否时显示提示错误信息
        const answer = await postCommunityView("TOOL_EXAM_DETAIL",member_id,row_id_int);
        if (answer.code === 0){
          console.log("行为获取成功")
        }
      }else {
        console.log('职称考试详情没有post:type和member_id')
      }
      // window.open(u)
      window.location.href = u
    }
  }

};

</script>

<style lang="scss">
.professional{
  padding-top:10px ;
  width: 100%;
  .professional-compass{
    height: 100px;
    .list-content{
      position: relative;
    }
  }
  .professional-word{
    .professional-word-title{
      width: 98%;
      display: -webkit-box;
      text-overflow: ellipsis;  /*超出内容用省略号*/
      overflow: hidden; /*内容超出后隐藏*/
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /*2行*/
      font-family: PingFang-SC-Bold;
      color:#474747;
      font-size: 14px;
      font-weight: bold;
    }
    .professional-word-intro{
      margin-top: 5px;
      font-family: Adobe Heiti Std R;
      font-size: 14px;
      color: #888888;
      width: 100%;
      display: -webkit-box;
      text-overflow: ellipsis;  /*超出内容用省略号*/
      overflow: hidden; /*内容超出后隐藏*/
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1; /*1行*/
    }
    .professional-word-time{
      position: absolute;
      bottom:4px;
      margin-top: 10px;
      font-size: 14px;
      color: #888888;
    }
  }
  .underline{
    margin-bottom: 8px;
    height: 1px;
  }
}
</style>
