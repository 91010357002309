<template>
  <div class="member" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="select_type">
      <van-tabs  color="#0099FF" title-active-color="#0099FF"  v-model="activeName" @click="callback">
        <van-tab name="PENDING" title="待审批"></van-tab>
        <van-tab name="AGREED" title="已通过"></van-tab>
        <van-tab name="REJECT" title="已驳回"></van-tab>
      </van-tabs>
    </div>
    <div class="member_list" v-for="item in memberItem" :key="item.id">
      <div class="member_info">
        <div class="Info">
          姓名 ：{{item.name}}
        </div>
        <div class="Info">
          职称 ：{{item.job_title}}
        </div>
        <div class="Info">
          医院 ：{{item.hospital_name}}
        </div>
        <div class="Info">
          科室 ：{{item.hospital_offices}}
        </div>
      </div>
      <van-divider :style="{  borderColor: '#DDDDDD',margin:'-16px auto'}" />
      <div class="auditBtn">
        <span  style="color: #AAAAAA" v-text="moment(item.created_time).format('YYYY-MM-DD HH:mm:ss')"></span>
        <button  style="border: 0; background-color: #0099FF;color: white;border-radius: 3px" v-if="item.status === 'PENDING'"  @click="auditBtn(item.user_id,item.id)">审核</button>
        <span v-if="item.status === 'AGREED'"  style="color: #00CC66;font-weight: bolder"> 已通过</span>
        <span v-if="item.status === 'REJECT'" style="color: #CC0000;font-weight: bolder"> 已驳回</span>
      </div>
      <van-divider v-if="item.status === 'REJECT'" :style="{  borderColor: '#DDDDDD',margin:'-16px auto'}"/>
      <div v-if="item.status === 'REJECT'" class="remarkBtn">
        <span >驳回原因：&nbsp;{{item.remark}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {getMemberList} from "../../service/memberAudit_api";
import moment from "moment/moment";
export default {
  name: "memberAudit",
  data(){
    return{
      moment,
      activeName: 'PENDING',
      fullscreenLoading: false,
      memberItem:[
      ],
    }
  },
  mounted() {
    this.memberList('PENDING')
  },
  methods:{
    async memberList(status){
      this.fullscreenLoading =true
      const member = await getMemberList(status)
      if (member.code === 0){
        this.memberItem = member.data
      }
      this.fullscreenLoading = false
    },
    //tab选择审核状态
    callback(key){
      this.memberList(key)
    },
    //审核跳转按钮
    auditBtn(ids,id){
      this.$router.push({ path: `memberInfo`,query:{user_id:ids,id:id}})
    },
  }
}
</script>

<style lang="scss" scoped>
body{
  width: 100%;
  background: rgba(245, 245, 245, 1);
}
.member{
  width: 100%;
  height: 100%;
  position:relative;
  background-color: rgba(245, 245, 245, 1);
  .select_type{
    width: 100%;
    z-index: 1;
    background-color: white;
    position:fixed;
    top:0;
    ::v-deep .van-tab__text--ellipsis{
      font-size: 16px;
    }
  }
  .member_list{

    width: 94%;
    height: auto;
    background-color: white;
    margin: 55px auto -45px;
    border-radius: 7px;
    box-shadow:#CCCCCC 0 0 10px;
    .member_info{
      display: flex;
      width: 94%;
      flex-wrap: wrap;
      padding: 15px;
      .Info{
        width: 50%;
        margin: 5px auto;
        text-align: left;
      }
    }
    .auditBtn{
      display: flex;
      margin: 20px auto;
      height: 30px;
      padding-left: 15px;
      padding-right: 20px;
      align-items: center;
      justify-content: space-between;
    }
    .remarkBtn{
      display: flex;
      color: #aaaaaa;
      height: auto;
      margin: 16px auto 20px;
      padding: 5px 20px 10px 15px;
      align-items: center;
    }
  }
}


</style>
