<template>
  <div class="box">
    <div class="box-header">
      <el-select style="width: 9rem" v-model="select" placeholder="请选择">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </div>

    <p style="color: #AEAEAE;font-size: 15px">
      数据总览
    </p>

    <div class="box-body">
      <div class="box-body-div">
        <span>观看总人次</span>
        <span>139843</span>
      </div>
      <div class="box-body-div">
        <span>观看总时长(28小时)</span>
        <span>139843</span>
      </div>
      <div class="box-body-div">
        <span>观看总人数(53)</span>
        <span>139843</span>
      </div>
      <div class="box-body-div">
        <span>平均观看时长(2分钟)</span>
        <span>139843</span>
      </div>
      <div class="box-body-div">
        <span>峰值人数(32)</span>
        <span>139843</span>
      </div>
      <div class="box-body-div">
        <span>直播总时长(90分钟)</span>
        <span>139843</span>
      </div>
    </div>

    <p style="color: #AEAEAE;width:9rem;font-size: 13px">
      注: 新增的人数会自动平均分配到各省份、城市、医院等级、科室、职称
    </p>

    <div style="width: 9rem;margin-top: 8rem">
      <el-button style="width: 100%;border-radius: 10px;background-color: #0097E3;color: white">查看报告</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataReporting",
  data () {
    return {
      select: '',
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
    }//dataReturn
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  padding: 0.4rem;

  .box-header {

  }

  .box-body {
    width: 9rem;
    height: auto;
    box-shadow: 2px 1px 8px rgb(0 0 0 / 15%);
    border-radius: 10px;
    .box-body-div {
      padding: 15px 10px 10px 10px;
      border-bottom: 1px solid  #EDEDED;
      height: 0.7rem;
      display: flex;
      justify-content: space-between;
      font-size: 15px;
    }
  }
}
</style>