<template>
  <div class="communityContentData">
    <!--    总览统计-->
    <div class="communityModule-one">
<!--      总览统计顶部-->
      <div class="communityModule-one-top">
        <div class="communityModule-one-top-left">总览统计</div>
      </div>
      <div class="communityModule-one-second">
        <div class="second-module">
          <div class="second-module-top1">{{neiNum.count}}</div>
          <div class="second-module-bottom">本月总访问人数</div>
        </div>
        <div class="second-module">
          <div class="second-module-top2">{{neiCount.count}}</div>
          <div class="second-module-bottom">本月总访问人次</div>
        </div>
        <div class="second-module">
          <div class="second-module-top3">{{neiVisit.count}}</div>
          <div class="second-module-bottom">今日访问人数</div>
        </div>
      </div>
    </div>
<!--    Top10-->
    <div class="communityModule-two">
<!--      stretch   使tabs选择器居中显示-->
      <el-tabs v-model="activeName"  stretch>
        <el-tab-pane label="内容访问人数Top10" name="first">
          <el-table
              :data="neiVisitPenTop"
              stripe
              style="width: 100%;border-radius: 20px">
            <el-table-column
                prop="title"
                label="标题"
                align="center"
            >
              <template slot-scope="scope">
                <div class="table-style" v-if="scope.row.title === undefined || scope.row.title === null || scope.row.title === '' ">{{scope.row.val}}</div>
                <div class="table-style" v-if="scope.row.val === undefined || scope.row.val === null || scope.row.val === '' ">{{scope.row.title}}</div>
              </template>
            </el-table-column>
            <el-table-column
                prop="num"
                label="人数"
                width="90%"
                align="center"
            ></el-table-column>
            <el-table-column
                label="排行"
                width="90%"
                align="center"
            >
              <template slot-scope="scope">
                <div v-if="scope.$index === 0" >
                  <div style="width: 20px; height: 20px; background-color: #f3364e;margin: 0 auto;color: white" >{{ scope.$index +1}}</div>
                </div>

                <div v-if="scope.$index === 1" >
                  <div style="width: 20px; height: 20px;background-color: #ff6e32;margin: 0 auto;color: white" >{{ scope.$index +1}}</div>
                </div>

                <div v-if="scope.$index === 2" >
                  <div style="width: 20px; height: 20px;background-color: #ffc600;margin: 0 auto;color: white" >{{ scope.$index +1}}</div>
                </div>

                <div v-if="scope.$index>2">
                  <div style="width: 20px; height: 20px;background-color: #cecece;margin: 0 auto;color: white">{{ scope.$index + 1 }}</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>

        <el-tab-pane label="上周内容访问人数排行" name="second">
          <el-table
              :data="neiWeekVisitPenTop"
              stripe
              style="width: 100%;border-radius: 20px"
          >
            <el-table-column
                prop="title"
                label="标题"
                align="center"
            >
              <template slot-scope="scope">
                <div class="table-style" v-if="scope.row.title === undefined || scope.row.title === null || scope.row.title === '' ">{{scope.row.val}}</div>
                <div class="table-style" v-if="scope.row.val === undefined || scope.row.val === null || scope.row.val === '' ">{{scope.row.title}}</div>
              </template>
            </el-table-column>
            <el-table-column
                prop="num"
                label="人数"
                width="90%"
                align="center"
            ></el-table-column>
            <el-table-column
                label="排行"
                align="center"
                width="90%"
            >
              <template slot-scope="scope">
                <div v-if="scope.$index === 0" >
                  <div style="width: 20px; height: 20px;background-color: #f3364e;margin: 0 auto;color: white" >{{ scope.$index +1}}</div>
                </div>

                <div v-if="scope.$index === 1" >
                  <div style="width: 20px; height: 20px;background-color: #ff6e32;margin: 0 auto;color: white" >{{ scope.$index +1}}</div>
                </div>

                <div v-if="scope.$index === 2" >
                  <div style="width: 20px; height: 20px;background-color: #ffc600;margin: 0 auto;color: white" >{{ scope.$index +1}}</div>
                </div>

                <div v-if="scope.$index>2">
                  <div style="width: 20px; height: 20px;background-color: #cecece;margin: 0 auto;color: white">{{ scope.$index + 1 }}</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>


      </el-tabs>
    </div>
<!--    访问人数-->
    <div class="communityModule-three">
      <div class="communityModule-three-font">访问量(天)</div>
<!--      时间选择器-->
      <div class="communityModule-three-top">

        <div class="communityModule-three-top-time" style="margin-left: 10px">
          <el-date-picker
              style="width: 90%"
              v-model="value1"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="选择开始时间"
              @change="getTime1"
              @blur="lose1">
          </el-date-picker>
        </div>
        <div class="communityModule-three-top-time-heng">-</div>
        <div class="communityModule-three-top-time">
          <el-date-picker
              style="width: 90%"
              v-model="value2"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="选择结束时间"
              @change="getTime2"
              @blur="lose2">
          </el-date-picker>
        </div>
      </div>
<!--      数据折线图-->
      <div class="communityModule-three-middle">
        <div id="visitPensions" style="width: 100%;height: 300px"></div>
      </div>
<!--      表格-->
      <div style="display: flex">
        <div class="communityModule-three-bottom" style="width: 70%;">
          <el-table
              :data="neiChangePen"
              stripe
              style="width: 100%;border-radius: 20px">
            <el-table-column
                prop="date_format"
                label="日期"
                align="center"
            ></el-table-column>
            <el-table-column
                prop="count"
                label="访问人数"
                align="center"
            ></el-table-column>
          </el-table>
        </div>
        <div class="communityModule-three-bottom" style="width: 30%;">
          <el-table
              :data="neiChangePenCount"
              stripe
              style="width: 100%;border-radius: 20px">
            <el-table-column
                prop="count"
                label="访问人次"
                align="center"
            ></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!--    访问人次（月） -->
    <div class="communityModule-five">

      <div class="five-font">访问量(月)</div>
      <!--      时间选择器-->
      <div class="communityModule-five-top">
        <div class="communityModule-five-top-time">
          <el-date-picker
              style="width: 90%"
              v-model="value5"
              type="year"
              format="yyyy"
              value-format="yyyy"
              placeholder="请选择时间"
              @change="getTime5"
              @blur="lose5">
          </el-date-picker>
        </div>
      </div>
      <!--     数据折线图 -->
      <div class="communityModule-five-middle">
        <div id="visitCountWeek" style="width: 100%;height: 300px"></div>
      </div>
       <!--    表格  -->
      <div style="display: flex">
        <div class="communityModule-five-bottom" style="width: 70%;">
          <el-table
              :data="neiMounthVisitPen"
              stripe
              style="width: 100%;border-radius: 20px">
            <el-table-column
                prop="year"
                label="时间"
                align="center"
            ></el-table-column>
            <el-table-column
                prop="count"
                label="访问人数"
                align="center"
            ></el-table-column>
          </el-table>
        </div>
        <div class="communityModule-five-bottom" style="width: 30%;">
          <el-table
              :data="neiMonthVisitCunt"
              stripe
              style="width: 100%;border-radius: 20px">
            <el-table-column
                prop="count"
                label="访问人次"
                align="center"
            ></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCommunityContentDay, getComWeekMonth,
  getConChangeVisitCountDay,
  getConChangeVisitPon,
  getContentVisitPenTop, getneiMonthVisitCunts, getWeekContentVisitTop
} from '../service/api'
import {getcontentVisitPensions,getcontentVisitCount,getcontentDayVisitPension} from '../service/api'
import moment from "moment";
export default {
  data(){
    return{
      activeName: 'first',
      lists:[],
      value1:'',
      value2:'',
      value3:'',
      value4:'',
      value5:'',
      value6:'',
      neiNum:'',
      neiCount:'',
      neiVisit:'',
      neiChangePen:[],
      neiChangePenCount:[],
      neiVisitPenTop:[],
      neiWeekVisitPenTop:[],
      nowData:'' , //当天时间
      afterData:'' ,  //前七天

      //内容社区(每周)访问人次
      neiWeekVisitCount:[],
      startTime: '',
      //内容每月人数
      neiMounthVisitPen:[],
      //内容每月人次
      neiMonthVisitCunt:[]
    }
  },
  watch:{
    async value1(newVal){
      //1.判断前后日期都存   在并且后面大于前面
      //2.重新加载echart

      if(newVal !== '' && newVal !== undefined && newVal !== null &&
          this.value2 !== '' && this.value2 !== undefined && this.value2 !== null &&
          this.value2 > newVal){

        const start_time = newVal
        const end_time = this.value2

        //内容(选择日期访问)访问人数
        const changeVisitPens = await getConChangeVisitPon(start_time,end_time)
        if(changeVisitPens.code === 0){
          this.neiChangePen = changeVisitPens.data
        }else {
          this.$message.warning(changeVisitPens.message + ",当前没有数据")
        }

        const changeVisitPensCountDay = await getConChangeVisitCountDay(start_time,end_time)
        if(changeVisitPensCountDay.code === 0){
          this.neiChangePenCount = changeVisitPensCountDay.data
          this.rotation()
        }else {
          this.$message.warning(changeVisitPensCountDay.message + ",当前没有数据")
        }
        await this.rotation()
      }
    },
    async value2(newVal){
      //1.判断前后日期都存在并且后面大于前面
      //2.重新加载echart
      if(this.value1 !== '' && this.value1 !== undefined && this.value1 !== null &&
          newVal !== ''&& newVal !== undefined && newVal !== null &&
          newVal > this.value1){

        const start_time = this.value1
        const end_time = newVal

        //内容(选择日期访问)访问人数
        const changeVisitPens = await getConChangeVisitPon(start_time,end_time)
        if(changeVisitPens.code === 0){
          this.neiChangePen = changeVisitPens.data
          this.rotationMonth()
        }else {
          this.$message.warning(changeVisitPens.message + ",当前没有数据")
        }

        const changeVisitPensCountDay = await getConChangeVisitCountDay(start_time,end_time)
        if(changeVisitPensCountDay.code === 0){
          this.neiChangePenCount = changeVisitPensCountDay.data
          this.rotation()
        }else {
          this.$message.warning(changeVisitPensCountDay.message + ",当前没有数据")
        }
      }

    },
    async value5(newVal){
      //1.判断日期存在
      //2.重新加载echart

      if(newVal !== undefined && newVal !== null && newVal !== ''){

        const year = newVal
        //内容(每月)访问人数
        const neiMonthVisitPens= await getComWeekMonth(year)
        if(neiMonthVisitPens.code === 0){
          this.neiMounthVisitPen = neiMonthVisitPens.data
          this.rotationMonth()
        }else {
          this.$message.warning(neiMonthVisitPens.message + ",当前没有数据")
        }
        //内容(每月)访问人次
        const neiMonthVisitCunts= await getneiMonthVisitCunts(year)
        if(neiMonthVisitCunts.code === 0){
          this.neiMonthVisitCunt = neiMonthVisitCunts.data
          this.rotationMonth()
        }else {
          this.$message.warning(neiMonthVisitCunts.message + ",当前没有数据")
        }

      }
    },

  },
  async mounted() {
    const result = await getCommunityContentDay()
    if(result.code === 0){
      this.lists = result.data
    }else {
      this.$message.warning(result.message)
    }

    //内容访问总人数
    const pensions = await getcontentVisitPensions()
    if(pensions.code === 0){
      this.neiNum = pensions.data[0]
    }else {
      this.$message.warning(pensions.message)
    }

    //内容访问总人次
    const pensionCounts = await getcontentVisitCount()
    if(pensionCounts.code === 0){
      this.neiCount = pensionCounts.data[0]
    }else {
      this.$message.warning(pensionCounts.message)
    }

    //内容今日访问人数
    const dayVisits = await getcontentDayVisitPension()
    if(dayVisits.code === 0){
      this.neiVisit = dayVisits.data[0]
    }else {
      this.$message.warning(dayVisits.message)
    }

    await this.formData()
    // 初始值,获取到的当前时间和七天前时间
    const start_time = this.afterData
    const end_time = this.nowData
    //内容(选择日期访问)访问人数(天)
    const changeVisitPens = await getConChangeVisitPon(start_time,end_time)
    if(changeVisitPens.code === 0){
      this.neiChangePen = changeVisitPens.data
    }else {
      this.$message.warning(changeVisitPens.message)
    }


    //内容(选择日期访问)访问人次(天)
    //新增人次与人数在一个折线图上显示
    const changeVisitPensCountDay = await getConChangeVisitCountDay(start_time,end_time)
    if(changeVisitPensCountDay.code === 0){
      this.neiChangePenCount = changeVisitPensCountDay.data
    }else {
      this.$message.warning(changeVisitPensCountDay.message)
    }


    //内容访问人数前10
    const neiVisitPenTops = await getContentVisitPenTop()
    if(neiVisitPenTops.code === 0){
      this.neiVisitPenTop = neiVisitPenTops.data
    }else {
      this.$message.warning(neiVisitPenTops.message)
    }

    //上周发布内容访问人数排行
    const neiWeekVisitPenTops = await getWeekContentVisitTop()
    if(neiWeekVisitPenTops.code === 0){
      this.neiWeekVisitPenTop = neiWeekVisitPenTops.data
    }else {
      this.$message.warning(neiWeekVisitPenTops.message)
    }

    this.getLastMonthDays()
    //传参数selectWeek
    //内容(每月)访问人数
    const year = this.startTime
    const neiMonthVisitPens= await getComWeekMonth(year)
    if(neiMonthVisitPens.code === 0){
      this.neiMounthVisitPen = neiMonthVisitPens.data
    }else {
      this.$message.warning(neiMonthVisitPens.message)
    }
    //内容(每月)访问人次
    const neiMonthVisitCunts= await getneiMonthVisitCunts(year)
    if(neiMonthVisitCunts.code === 0){
      this.neiMonthVisitCunt = neiMonthVisitCunts.data
    }else {
      this.$message.warning(neiMonthVisitCunts.message)
    }

    this.rotation()
    this.rotationMonth()
  },
  methods: {
    // handleClick(tab, event) {
    //   // console.log(tab, event);
    // },
    rotation(){
      let drawLine = this.$echarts.init(document.getElementById('visitPensions'))
      let option = {
        legend: {},
        tooltip: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        dataset: [
          {
            source: this.neiChangePen
          },
          {
            source: this.neiChangePenCount
          }
        ],
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate:60,
          }
        },
        yAxis: {
          type: 'value',
        },
        series:[
          {
            datasetIndex:0,
            name:'访问人数',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:1,
            name: '访问人次',
            type: "line",
            smooth: true
          }
        ]
      };

      drawLine.setOption(option);
    },
    //折线图访问人次（月）
    rotationMonth(){
      let drawLine = this.$echarts.init(document.getElementById('visitCountWeek'))
      let option = {
        legend: {},
        tooltip: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        dataset: [
          {
            source: this.neiMounthVisitPen
          },
          {
            source: this.neiMonthVisitCunt
          },
        ],
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate:60,
          }
        },
        yAxis: {
          type: 'value',
        },
        series:[
          {
            datasetIndex:0,
            name:'访问人数',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:1,
            name: '访问人次',
            type: "line",
            smooth: true
          }
        ]
      };

      drawLine.setOption(option);
    },

    //得到开始时间
    getTime1(){
      // console.log(this.value1)
    },
    //得到结束时间
    getTime2(){
      // console.log(this.value2)
    },
    //判断输入的开始时间是否为有效
    lose1(){
      // if(this.value1 === null || this.value1 === undefined || this.value1 === ''){
      //   alert('请选择开始时间')
      // }
    },
    //判断输入的结束时间是否为有效
    async lose2(){
      // if(this.value2 === null || this.value2 === undefined || this.value2 === ''){
      //   alert('请选择结束时间')
      // }
      if(this.value2 < this.value1){
        alert('请选择有效时间')
        this.value2 = null
      }
    },

    //得到开始时间
    getTime5(){
      // console.log(this.value5)
    },
    //得到结束时间
    lose5(){
      // if(this.value5 === null || this.value5 === undefined || this.value5 === ''){
      //   alert('请选择开始时间')
      // }
    },
    //判断输入的结束时间是否为有效

   // 获取当前时间和七天前时间
      formData(){
        const t = moment().format('YYYY-MM-DD')
        this.nowData = t
        const t2 = moment()
        const ago = t2.subtract(7,'days').format('YYYY-MM-DD')
        this.afterData = ago

    },
    // 获取上一个月的时间
    getLastMonthDays() {
      this.startTime = moment().format('YYYY')
    },
  },

}
</script>

<style lang="scss" scoped>
.communityContentData{
  width: 100%;
  height: auto;
  .table-style{
    width: 200px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .communityModule-three-top-time-heng{
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .communityModule-five-top-time-heng{
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .communityModule-one{
    width: 94%;
    height: 180px;
    margin: 15px auto;
    border-radius: 20px;
    box-shadow: 0 0 10px #0a84ff;
    .communityModule-one-top{
      width: 100%;
      height: 30px;
      display: flex;
      .communityModule-one-top-left{
        width: 40%;
        height: 30px;
        line-height: 30px;
        margin-top: 5px;
        margin-left: 10px;
        font-size: 16px;
        color: #0a84ff;
      }
    }
    .communityModule-one-second{
      width: 100%;
      height: 140px;
      margin-top: 30px;
      display: flex;
      .second-module{
        width: 33%;
        height: 100%;
        .second-module-top1{
          height: 70px;
          line-height:70px;
          width: 70px;
          background-color:#FF6161;
          border-radius: 50px;
          margin: 0 auto;
          text-align: center;
          color: white ;
          font-size: 18px;
        }
        .second-module-top2{
          height: 70px;
          line-height:70px;
          width: 70px;
          background-color:#58D0FF;
          border-radius: 50px;
          margin: 0 auto;
          text-align: center;
          color: white ;
          font-size: 18px;
        }
        .second-module-top3{
          height: 70px;
          line-height:70px;
          width: 70px;
          background-color:#FFC935;
          border-radius: 50px;
          margin: 0 auto;
          text-align: center;
          color: white ;
          font-size: 18px;
        }
        .second-module-bottom{
          font-size: 12px;
          text-align: center;
          height: 40px;
          line-height: 40px;
        }
      }
    }
  }
  .communityModule-two{
    width: 94%;
    height:auto;
    margin: 15px auto;
    border-radius: 20px;
    box-shadow: 0 0 10px #0a84ff;
  }
  .communityModule-three{
    width: 94%;
    height: auto;
    margin: 15px auto;
    border-radius: 20px;
    box-shadow: 0 0 10px #0a84ff;
    .communityModule-three-font{
      padding: 10px;
      color: #0a84ff;
    }
    .communityModule-three-top{
      width: 100%;
      display: flex;
      .communityModule-three-top-time{

      }
    }
    .communityModule-three-middle{
      width: 100%;
      height: 300px;
    }
    .communityModule-three-bottom{
      width: 100%;
      height: auto;
    }


  }
  .communityModule-four{
    width: 94%;
    height: auto;
    margin: 15px auto;
    border-radius: 20px;
    box-shadow: 0 0 10px #0a84ff;
    .communityModule-four-font{
      padding: 10px;
      color: #0a84ff;
    }
    .communityModule-four-top{
      width: 100%;
      display: flex;
      padding: 10px;
      .communityModule-four-top-time{

      }
    }
    .communityModule-four-middle{
      width: 100%;
      height: 300px;
    }
    .communityModule-four-bottom{
      width: 100%;
      height: auto;
    }
  }
  .communityModule-five{
    width: 94%;
    height:auto;
    margin: 15px auto;
    border-radius: 20px;
    box-shadow: 0 0 10px #0a84ff;
    .five-font{
      color: #0a84ff;
      padding: 10px;
    }
    .communityModule-five-top{
      width: 100%;
      display: flex;
      padding: 10px;
      .communityModule-five-top-time{

      }
    }
    .communityModule-five-middle{
      width: 100%;
      height: 300px;
    }
    .communityModule-five-bottom{
      width: 100%;
      height: auto;
    }
  }
}
</style>