//职称考试
import {get,post,postToken} from "./exam";
// import qs from "qs";

export function getProfessionalExam(id) {
    return get('/api/v1/app/exams_h5?member_id='+id+'&page_size=100')
}
export function getVisitWatchSumPens(id) {
    return get('/api2/v1/admin/live-meet/'+ id +'/online_count')
}

//修改直播间观看人数
export function postRoomCount(data) {
    return post('/api2/v1/admin/live-meet/setRoomCount',data)
}

//社区点击记录操作
export function browseOperation(data) {
    return postToken('/api2/v1/app/community/addBrowse',data)
}


