<template>
  <div class="status">
    <div class="status-top">
      <div class="status-font">注册人数统计(省份)</div>
      <div class="watch-three-btn">
        <select v-model="list1" style="width: 70px;height: 25px;border: #0a84ff solid 1px;color: #888888">
          <option>5</option>
          <option>10</option>
        </select>
      </div>
    </div>
    <!--    地图-->
    <div id="diTu" style="width: 100%;height: 300px"></div>
    <!--    表格-->
    <div class="tableDiv">
      <el-table
          :data="registerPro"
          stripe
          style="height: auto;width: 95%;border-radius: 20px;margin: 0 auto"
      >
        <el-table-column
            label="省份"
            prop="name"
            align="center"
        >
          <template slot-scope="scope">
            {{scope.row.name === ''?'未知':scope.row.name}}
          </template>
        </el-table-column>
        <el-table-column
            label="人数"
            prop="value"
            align="center"
        ></el-table-column>
        <el-table-column
            label="占比"
            align="center"
        >
          <template slot-scope="scope">
            {{Math.round((scope.row.value/sumPen2.count)*100) + '%'}}
          </template>
        </el-table-column>

      </el-table>
    </div>
  </div>
</template>
<script>
import {getRegisterPro, getRegisterProTu, getRegisterSum} from "../service/api";
import chinaJson from "../json/china.json";
import {ListPushPro} from "../utils/myUtils";
export default {
  data(){
    return{
      registerPro:[],
      registerProTu:[],
      list1:'5',
      sumPen2:[]
    }
  },
  watch:{
    async list1(newVal){
      const limit = newVal
      const registerPros =await  getRegisterPro(limit)
      if(registerPros.code === 0){
        // this.registerPro = registerPros.data
        let registerPro = registerPros.data
        //ListPushQiTa重复利用，直接封装了遍历数组
        let sum = ListPushPro(registerPro);
        //向集合中添加其他一行
        sum = this.sumPen2.count - sum
        if (sum>0){
          //向集合中添加其他一行
          registerPro.push({name:"其他",value:sum})
        }
        this.registerPro = registerPro
      }
    }
  },
  mounted() {
    this.provinceTu()
    this.province1()
    this.sumPens()
  },
  methods:{
    //控制表格
    async province1(){
      const limit = 5
      const registerPros =await  getRegisterPro(limit)
      if(registerPros.code === 0){
        // this.registerPro = registerPros.data
        let registerPro = registerPros.data
        //ListPushQiTa重复利用，直接封装了遍历数组
        let sum = ListPushPro(registerPro);
        //向集合中添加其他一行
        sum = this.sumPen2.count - sum
        if (sum>0){
          //向集合中添加其他一行
          registerPro.push({name:"其他",value:sum})
        }
        this.registerPro = registerPro
      }
    },
    //控制图
    async provinceTu(){
      const registerProTus =await  getRegisterProTu()
      if(registerProTus.code !== 0){
        console.log('code不为零！')
      }
      this.registerProTu = registerProTus.data
      this.rotationProsTu()
    },
    rotationProsTu(){
      let drawLine = this.$echarts.init(document.getElementById('diTu'))
      this.$echarts.registerMap('china',chinaJson)
      let option = {
        tooltip: {
          formatter:function(params){
            return params.seriesName+'<br />'+params.name+'：'+params.value
          }//数据格式化
        },
        visualMap: {
          min: 0,
          max: 1500,
          left: 'left',
          top: 'bottom',
          text: ['H','L'],

          inRange: {
            color: ['#e2fafc', '#0c73dc']
          },
          show:true
        },
        geo: {
          map: 'china',
          roam: true,
          zoom:1.23,
          label: {
            normal: {
              show: false,
              fontSize:'8',
              color: 'rgba(0,0,0,0.7)'
            }
          },
          itemStyle: {
            normal:{
              borderColor: 'rgba(0, 0, 0, 0.2)',
              areaColor: '#fff'
            },
            emphasis:{
              areaColor: '#F3B329',
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        },
        series : [
          {
            name: '访问人数',
            type: 'map',
            geoIndex: 0,
            data:this.registerProTu,
          }
        ]
      };
      drawLine.setOption(option);
      // drawLine.on('click', function (params) {
      //   alert(params.name);
      // });

    },

    //总注册量
    async sumPens(){
      const sumPens = await getRegisterSum()
      this.sumPen2 = sumPens.data[0]
    },
  }

}
</script>

<style lang="scss" scoped>
.status-top{
  display: flex;
  height: auto;
  .status-font{
    width: 100%;
    height: 50px;
    line-height: 50px;
    margin-left: 20px;
    color: #45A5E6;
    font-size: 14px;
  }
  .watch-three-btn{
    margin: 15px;
  }
}
.tableDiv{
  border-radius: 20px;
}
</style>