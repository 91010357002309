import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import DocumentCompass from "../components/documentCompassCopy";
import DocumentCompassCollect from "../components/documentCompassCollect";
import SendToEmail from "../components/SendToEmail";
import MedicalJournal from "../components/MedicalJournal";
import documentPdf from "../components/documentPdf";
import professionalExam from "../components/professionalExam";
import communityContentData from "../components/communityContentData";
import communityData from "../components/communityData";
import watchBroadcast from "../components/watchBroadcast";
import conferenceBroadcast from "../components/conferenceBroadcast";
import televiseLive from "../components/televiseLive";
import boardRoom from "../components/boardRoom";
import registerCollect from "../allVue/registerCollect";
import areaChild from "../ramousVue/areaChild";
import statusChild from "../ramousVue/statusChild";
import timeChild from "../ramousVue/timeChild";
import Tool from "../components/Tool";
import test from "../views/test";
import takeInVue from "../views/takeInVue";
import ToolContent from "../components/ToolContent";
import magazineAnalysis from "../views/columnAnalyse/magazineAnalysis";
import documentAnalysis from "../views/columnAnalyse/documentAnalysis";
import examAnalysis from "../views/columnAnalyse/examAnalysis";
import memberInfo from "../views/memberAudit/memberInfo";
import memberAudit from "../views/memberAudit/memberAudit";
import Staging from "../views/newView/Staging";
import DataStatistics from "../views/newView/DataStatistics";
import LiveConference from "../views/newView/LiveConference";
import Toolbox from "../views/newView/Toolbox";
import DataReporting from "../views/newView/DataReporting";
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/staging",
    name: "Staging",
    component: Staging,
    meta: {
      title: "运营工作台",
      keepAlive: false, //此组件不需要被缓存
    },
  },
  {
    path: "/staging/dataStatistics",
    name: "DataStatistics",
    component: DataStatistics,
    meta: {
      title: "数据统计",
      keepAlive: false, //此组件不需要被缓存
    },
  },
  {
    path: "/staging/liveConference",
    name: "LiveConference",
    component: LiveConference,
    meta: {
      title: "直播会议",
      keepAlive: false, //此组件不需要被缓存
    },
  },
  {
    path: "/staging/toolbox",
    name: "Toolbox",
    component: Toolbox,
    meta: {
      title: "工具箱",
      keepAlive: false, //此组件不需要被缓存
    },
  },
  {
    path: "/staging/dataReporting",
    name: "DataReporting",
    component: DataReporting,
    meta: {
      title: "数据报告",
      keepAlive: false, //此组件不需要被缓存
    },
  },
  {
    path: "/Tool",
    name: "Tool",
    component: Tool,
    meta: {
      title: "进修招聘",
      keepAlive: false, //此组件不需要被缓存
    },
  },
  {
    path: "/testVue",
    name: "test",
    component: test,
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "测试公众号界面",
    },
  },
  {
    path: "/takeInVue",
    name: "takeInVue",
    component: takeInVue,
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "测试接收数据",
    },
  },
  {
    path: "/ToolContent",
    name: "ToolContent",
    component: ToolContent,
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "招聘内容",
    },
  },
  {
    path: "/watchBroadcast",
    name: "watchBroadcast",
    component: watchBroadcast,
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "观看直播数据统计",
    },
  },
  {
    path: "/televiseLive",
    name: "televiseLive",
    component: televiseLive,
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "直播间数据统计",
    },
  },
  {
    path: "/conferenceBroadcast",
    name: "conferenceBroadcast",
    component: conferenceBroadcast,
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "会议直播数据统计",
    },
  },
  {
    path: "/boardRoom",
    name: "boardRoom",
    component: boardRoom,
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "会议数据统计",
    },
  },
  {
    path: "/documentCompass",
    name: "documentCompass",
    component: DocumentCompass,
    meta: {
      keepAlive: true, //此组件需要被缓存
      title: "文献指南",
    },
  },
  {
    path: "/documentCompassCollect",
    name: "documentCompassCollect",
    component: DocumentCompassCollect,
    meta: {
      keepAlive: false, //此组件需要被缓存
    },
  },
  {
    path: "/MedicalJournal",
    name: "MedicalJournal",
    component: MedicalJournal,
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "医学杂志",
    },
  },
  {
    path: "/pdfVue",
    name: "PdfVue",
    component: () => import("../components/PdfVue"),
  },
  {
    path: "/SendToEmail",
    name: "SendToEmail",
    component: SendToEmail,
  },
  {
    path: "/documentPdf",
    name: "documentPdf",
    component: documentPdf,
  },
  {
    path: "/professionalExam",
    name: "professionalExam",
    component: professionalExam,
    meta: {
      title: "职称考试",
      keepAlive: false, //此组件不需要被缓存
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/communityContentData",
    name: "communityContentData",
    component: communityContentData,
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "社区内容访问记录",
    },
  },
  {
    path: "/communityData",
    name: "communityData",
    component: communityData,
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "社区访问记录",
    },
  },
  {
    path: "/registerCollect",
    name: "registerCollect",
    component: registerCollect,
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "注册数据统计",
    },
  },
  {
    path: "/areaChild",
    name: "areaChild",
    component: areaChild,
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "地区",
    },
  },
  {
    path: "/statusChild",
    name: "statusChild",
    component: statusChild,
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "身份",
    },
  },
  {
    path: "/timeChild",
    name: "timeChild",
    component: timeChild,
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "时间",
    },
  },
  //  修改直播观看人次
  {
    path: "/modifyViewers",
    name: "ModifyViewers",
    component: () => import("../components/ModifyViewers"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "直播观看人次",
    },
  }, //  企业微信工具首页
  {
    path: "/myYunicu",
    name: "myYunicu",
    component: () => import("../components/MyYunicu"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "我的yunicu",
    },
  },
  {
    path: "/liveShare",
    name: "LiveDetailsShare",
    component: () => import("../views/forwardShare/LiveDetailsShare"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "点播",
    },
  },
  {
    path: "/videoShare",
    name: "VideoDetailsShare",
    component: () => import("../views/forwardShare/VideoDetailsShare"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "专题",
    },
  },
  {
    path: "/magazineAnalysis",
    name: "MagazineAnalysis",
    component: magazineAnalysis,
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "医学杂志数据分析",
    },
  },
  {
    path: "/documentAnalysis",
    name: "DocumentAnalysis",
    component: documentAnalysis,
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "文献指南数据分析",
    },
  },
  {
    path: "/examAnalysis",
    name: "ExamAnalysis",
    component: examAnalysis,
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "职场考试数据分析",
    },
  },
  {
    path: "/memberAudit",
    name: "memberAudit",
    component: memberAudit,
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "认证审批",
    },
  },
  {
    path: "/memberInfo",
    name: "memberInfo",
    component: memberInfo,
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "审批",
    },
  },
  {
    path: "/subscribeExperts",
    name: "subscribeExperts",
    component: () => import("../views/subscribeShare/subscribeExperts"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "预约专家",
    },
  },
  {
    path: "/communityShare",
    name: "communityShare",
    component: () => import("../views/subscribeShare/communityShare"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "社区",
    },
  },
  {
    path: "/helpCenter",
    name: "helpCenter",
    component: () => import("../views/helpCenter/helpCenter"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "帮助中心",
    },
  },
  {
    path: "/feedBack",
    name: "feedBack",
    component: () => import("../views/helpCenter/feedBack"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "意见反馈",
    },
  },
  {
    path: "/myFeedBack",
    name: "myFeedBack",
    component: () => import("../views/helpCenter/myFeedBack"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "我的反馈",
    },
  },
  {
    path: "/feedBackDetails",
    name: "feedBackDetails",
    component: () => import("../views/helpCenter/feedBackDetails"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "反馈详情",
    },
  },
  {
    path: "/aboutIcu",
    name: "aboutIcu",
    component: () => import("../views/helpCenter/aboutIcu"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "关于云ICU",
    },
  },
  {
    path: "/userAgreement",
    name: "userAgreement",
    component: () => import("../views/helpCenter/userAgreement"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "用户协议",
    },
  },
  {
    path: "/medicalHome",
    name: "medicalHome",
    component: () => import("../views/medicalFormula/medicalIndex.vue"),
    meta: {
      keepAlive: true,
      title: "医学公式",
    },
  },

  {
    path: "/medicalSearch",
    name: "medicalSearch",
    component: () => import("../views/medicalFormula/medicalSearch.vue"),
    meta: {
      keepAlive: true, //此组件不需要被缓存
      title: "医学公式",
    },
  },
  {
    path: "/patientLog", //患者记录
    name: "patientLog",
    component: () => import("../views/medicalFormula/patientLog.vue"),
    meta: {
      keepAlive: true, //此组件不需要被缓存
      title: "医学公式",
    },
  },
  {
    path: "/medicalErrorLog", //纠错记录
    name: "medicalErrorLog",
    component: () => import("../components/medicalErrorLog.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "医学公式",
    },
  },
  {
    path: "/formulaTest",
    name: "FormulaTest",
    component: () => import("@/views/Formula/FormulaTest.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "医学公式",
    },
  },
  {
    path: "/formula/XLODESWK",
    name: "ShockIndex",
    component: () => import("../views/Formula/Template1/ShockIndex.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "休克指数(SI)",
    },
  },
  {
    path: "/formula/ARMXUZYX",
    name: "TIMIRiskIndex",
    component: () => import("../views/Formula/Template1/TIMIRiskIndex.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "TIMI风险指数",
    },
  },
  {
    path: "/formula/HNAPCLFA",
    name: "AtrialFibrillationSymptomScore",
    component: () =>
      import("@/views/Formula/Template3/AtrialFibrillationSymptomScore.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "EHRA症状评分",
    },
  },
  {
    path: "/formula/TOCXROHL",
    name: "重症疼痛观察工具(CPOT)",
    component: () => import("@/views/Formula/Template5/TOCXROHL.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "重症疼痛观察工具(CPOT)",
    },
  },
  {
    path: "/formula/PRPYOZLC",
    name: "营养风险筛查 (NRS-2002)",
    component: () => import("@/views/Formula/Template5/PRPYOZLC.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "营养风险筛查 (NRS-2002)",
    },
  },
  {
    path: "/formula/BUCPCFAA",
    name: "KDIGO分期标准(AKI)",
    component: () => import("@/views/Formula/Template5/BUCPCFAA.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "KDIGO分期标准(AKI)",
    },
  },
  {
    path: "/formula/VQLANCTP",
    name: "RIFLE 分级标准(AKI)",
    component: () => import("@/views/Formula/Template5/VQLANCTP.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "RIFLE 分级标准(AKI)",
    },
  },
  {
    path: "/formula/VHEWABOY",
    name: "AKIN 分期标准(AKI)",
    component: () => import("@/views/Formula/Template5/VHEWABOY.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "AKIN 分期标准(AKI)",
    },
  },
  {
    path: "/formula/SBSYCBSK",
    name: "Richmond 躁动-镇静评分(RASS)",
    component: () => import("@/views/Formula/Template5/SBSYCBSK.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "Richmond 躁动-镇静评分(RASS)",
    },
  },
  {
    path: "/formula/NRSJYKMZ",
    name: "IAH严重程度分级",
    component: () => import("@/views/Formula/Template5/NRSJYKMZ.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "IAH严重程度分级",
    },
  },
  {
    path: "/formula/LWRNINXT",
    name: "脑功能分级(CPC)评分量表",
    component: () => import("@/views/Formula/Template5/LWRNINXT.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "脑功能分级(CPC)评分量表",
    },
  },
  {
    path: "/formula/TSDVDCAL",
    name: "MidRiskScoreForAtrialFibrillation",
    component: () =>
      import("@/views/Formula/Template3/MidRiskScoreForAtrialFibrillation.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "CHA2DS2-VASc评分",
    },
  },
  {
    path: "/formula/FHZGNHFW",
    name: "killipClassification",
    component: () =>
      import("@/views/Formula/Template3/killipClassification.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "Killip心功能分级",
    },
  },
  {
    path: "/formula/QKYUUVRV",
    name: "CalmAndRestless",
    component: () => import("@/views/Formula/Template3/CalmAndRestless.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "镇静-躁动评分(SAS)",
    },
  },
  {
    path: "/formula/ATKWUWRF",
    name: "CalmAndRestless",
    component: () => import("@/views/Formula/Template5/ATKWUWRF.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "格拉斯哥-布拉奇福德出血评分(GBS)",
    },
  },
  {
    path: "/formula/UZHTTXQF",
    name: "OttawaKneeJointFracture",
    component: () =>
      import("@/views/Formula/Template3/OttawaKneeJointFracture.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "Ottawa膝关节骨折诊断规则",
    },
  },
  {
    path: "/formula/LXSZYTOS",
    name: "GlasgowOutcomeGrading",
    component: () =>
      import("@/views/Formula/Template3/GlasgowOutcomeGrading.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "GOS预后分级",
    },
  },
  {
    path: "/formula/MFIDYTFP",
    name: "nonSTMyocardialInfarction",
    component: () =>
      import("@/views/Formula/Template3/nonSTMyocardialInfarction.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "非ST段抬高型心肌梗死TIMI评分",
    },
  },
  {
    path: "/formula/EPYZXLGS",
    name: "pulmonaryEmbolism",
    component: () => import("@/views/Formula/Template3/pulmonaryEmbolism.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "肺栓塞排除标准(PERC 法)",
    },
  },
  {
    path: "/formula/EBZYKKZX",
    name: "NUTRIC",
    component: () => import("@/views/Formula/Template5/NUTRIC.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "危重症营养风险评分(NUTRIC/mNUTRIC)",
    },
  },
  {
    path: "/formula/YWPSFOGQ",
    name: "BehavioralPainGrading",
    component: () =>
      import("@/views/Formula/Template5/BehavioralPainGrading.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "行为疼痛量表（BPS）",
    },
  },
  {
    path: "/formula/VIGFGENK",
    name: "SOFA",
    component: () => import("@/views/Formula/Template5/SOFA.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "序贯器官衰竭评估（SOFA）评分",
    },
  },
  {
    path: "/formula/MCYOHSJR",
    name: "CCS-SAFAtrialFibrillationScore",
    component: () =>
      import("@/views/Formula/Template3/CCS-SAFAtrialFibrillationScore.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "房颤症状评分(CCS-SAF分级)",
    },
  },
  {
    path: "/formula/OEQMLNTA",
    name: "HEART",
    component: () => import("@/views/Formula/Template5/HEART.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "HEART评分",
    },
  },
  {
    path: "/formula/TUAEINSY",
    name: "OxygenationIndex",
    component: () => import("../views/Formula/Template1/OxygenationIndex.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "氧合分数(PaO2/FiO2)",
    },
  },
  {
    path: "/formula/DZXFNOPN",
    name: "DZXFNOPN",
    component: () => import("../views/Formula/Template1/DZXFNOPN.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "肾衰竭指数(RFI)",
    },
  },
  {
    path: "/formula/HIXJFHLG",
    name: "HIXJFHLG",
    component: () => import("../views/Formula/Template1/HIXJFHLG.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "残余肾功能（RRF）",
    },
  },
  {
    path: "/formula/GSKITUHG",
    name: "OxygenationIndexOI",
    component: () =>
      import("../views/Formula/Template1/OxygenationIndexOI.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "氧合指数(OI)",
    },
  },
  {
    path: "/formula/KPLNWHLB",
    name: "ChildMaintenanceFluid",
    component: () =>
      import("../views/Formula/Template1/ChildMaintenanceFluid.vue"),
    meta: {
      keepAlive: false,
      title: "儿童维持液体计算",
    },
  },
  {
    path: "/formula/MWHKUTZM", // 补液公式
    name: "FluidReplacementFormula",
    component: () =>
      import("../views/Formula/Template1/FluidReplacementFormula.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "补液公式(成人)",
    },
  },
  {
    path: "/formula/RXXFTYRS", // 补钾公式
    name: "PotassiumKFormula",
    component: () => import("../views/Formula/Template1/PotassiumKFormula.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "补钾公式",
    },
  },
  {
    path: "/formula/BCKZUZGG", // 治疗干预评分系统(TISS-28)
    name: "PotassiumKFormula",
    component: () => import("../views/Formula/Template5/BCKZUZGG.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "治疗干预评分系统(TISS-28)",
    },
  },
  {
    path: "/formula/YGOIDJUX", // 治疗干预评分系统(TISS-28)
    name: "PotassiumKFormula",
    component: () => import("../views/Formula/Template5/YGOIDJUX.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "多器官功能障碍综合征(MODS)评分",
    },
  },
  {
    path: "/formula/ZLBMYSDP", //体表面积
    name: "BodySurfaceArea",
    component: () => import("../views/Formula/Template1/BodySurfaceArea.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "体表面积(BSA)",
    },
  },
  {
    path: "/formula/TZZSACGF", //体质指数
    name: "BodyMassIndex",
    component: () => import("../views/Formula/Template1/BodyMassIndex.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "体质指数(BMI)",
    },
  },
  {
    path: "/formula/YEGQKRWL", //创伤时能耗估计
    name: "EnergyConsumption",
    component: () => import("@/views/Formula/Template1/EnergyConsumption.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "创伤时基础能耗估计",
    },
  },
  {
    path: "/formula/PBPOADIB", //低钠血症时补钠值(自定义目标值)
    name: "DuringHyponatremia",
    component: () => import("@/views/Formula/Template1/DuringHyponatremia.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "低钠血症时补钠值(自定义目标值)",
    },
  },
  {
    path: "/formula/YUOACLAB",
    name: "BasalMetabolicRate",
    component: () => import("@/views/Formula/Template1/BasalMetabolicRate.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "基础代谢率(BMR)",
    },
  },
  {
    path: "/formula/CBAVJKIK",
    name: "CBAVJKIK",
    component: () => import("@/views/Formula/Template1/CBAVJKIK.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "肾小球滤过率GFR(MDRD公式)",
    },
  },
  {
    path: "/formula/BOCPTLHM",
    name: "BOCPTLHM",
    component: () => import("@/views/Formula/Template1/BOCPTLHM.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "肾小球滤过率GFR(Salazar-Corcoran公式)",
    },
  },
  {
    path: "/formula/DTZZDQBS",
    name: "BasicEnergyConsumption",
    component: () =>
      import("@/views/Formula/Template1/BasicEnergyConsumption.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "基础能量消耗(BEE)",
    },
  },
  {
    path: "/formula/QDGJYKMU",
    name: "QDGJYKMU",
    component: () =>
      import("@/views/Formula/Template1/QDGJYKMU.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "肌酐清除率(Ccr)",
    },
  },
  {
    path: "/formula/FUIEJDXU",
    name: "FUIEJDXU",
    component: () =>
      import("@/views/Formula/Template1/FUIEJDXU.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "肾小球滤过率GFR(Schwartz公式)",
    },
  },
  {
    path: "/formula/DLBNQDBC",
    name: "DLBNQDBC",
    component: () =>
      import("@/views/Formula/Template5/DLBNQDBC.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "JSTH-DIC诊断标准",
    },
  },
  {
    path: "/formula/ZJXNSDMM",
    name: "ZJXNSDMM",
    component: () =>
      import("@/views/Formula/Template5/ZJXNSDMM.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "简化JSTH-DIC诊断标准",
    },
  },
  {
    path: "/formula/HPKRXVYZ",
    name: "HPKRXVYZ",
    component: () =>
      import("@/views/Formula/Template1/HPKRXVYZ.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "肾小球滤过率GFR(Cockcroft-Gault公式)",
    },
  },
  {
    path: "/formula/YBKDCNFV",
    name: "UreaExcretionFraction",
    component: () =>
      import("@/views/Formula/Template1/UreaExcretionFraction.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "尿素排泄分数",
    },
  },
  {
    path: "/formula/XQPALDLA",
    name: "MeanArterialPressure",
    component: () =>
      import("@/views/Formula/Template1/MeanArterialPressure.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "平均动脉压",
    },
  },
  {
    path: "/formula/TNMUWODI",
    name: "BurnFluidResuscitation",
    component: () =>
      import("@/views/Formula/Template1/BurnFluidResuscitation.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "烧伤液体复苏(Parkland)公式",
    },
  },
  {
    path: "/formula/AXPDMLMD",
    name: "BicarbonateDeficiency",
    component: () =>
      import("@/views/Formula/Template1/BicarbonateDeficiency.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "碳酸氢盐缺乏量",
    },
  },
  {
    path: "/formula/OXZOFEBK",
    name: "untilAlb",
    component: () => import("@/views/Formula/Template2/ALB.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "白蛋白",
    },
  },
  {
    path: "/formula/AESQYKBS",
    name: "untilWbc",
    component: () => import("@/views/Formula/Template2/WBC.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "白细胞计数(WBC)",
    },
  },
  {
    path: "/formula/IFVPCZKG",
    name: "untilGlu",
    component: () => import("@/views/Formula/Template2/GLU.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "葡萄糖(GLU)",
    },
  },
  {
    path: "/formula/BPSKRUIU",
    name: "untilPT",
    component: () => import("@/views/Formula/Template2/PT.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "凝血酶原时间(PT)",
    },
  },
  {
    path: "/formula/IRIKYUCR",
    name: "untilRBC",
    component: () => import("@/views/Formula/Template2/RBC.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "红细胞计数(RBC)",
    },
  },
  {
    path: "/formula/GEMAIKUD",
    name: "untilRDW",
    component: () => import("@/views/Formula/Template2/RDW.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "红细胞分布宽度变异系数(RDW-CV)",
    },
  },
  {
    path: "/formula/WCIPXEKA",
    name: "untilRDWSD",
    component: () => import("@/views/Formula/Template2/RDWSD.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "红细胞分布宽度标准差(RDW-SD)",
    },
  },
  {
    path: "/formula/AHINZESV",
    name: "untilTnl",
    component: () => import("@/views/Formula/Template2/Tnl.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "肌钙蛋白I",
    },
  },
  {
    path: "/formula/DLIJGJAY",
    name: "untilTNT",
    component: () => import("@/views/Formula/Template2/TNT.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "肌钙蛋白T",
    },
  },
  {
    path: "/formula/SRZPLFDW",
    name: "untilCT",
    component: () => import("@/views/Formula/Template2/CT.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "降钙素(CT)",
    },
  },
  {
    path: "/formula/ZXYKMOGK",
    name: "untilBUN",
    component: () => import("@/views/Formula/Template2/BUN.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "尿素氮(BUN)",
    },
  },
  {
    path: "/formula/XNJJOBSI",
    name: "untilHCT",
    component: () => import("@/views/Formula/Template2/HCT.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "血细胞比容(HCT)",
    },
  },
  {
    path: "/formula/UIMAMXUQ",
    name: "untilTG",
    component: () => import("@/views/Formula/Template2/TG.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "甘油三酯(TG)",
    },
  },
  {
    path: "/formula/CLFDLBUG",
    name: "untilLYMPH",
    component: () => import("@/views/Formula/Template2/LYMPH.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "淋巴细胞(LYMPH)",
    },
  },
  {
    path: "/formula/XZBQCVWY",
    name: "untilTT",
    component: () => import("@/views/Formula/Template2/TT.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "凝血酶时间(TT)",
    },
  },
  {
    path: "/formula/RHSJNDHT",
    name: "untilLYC",
    component: () => import("@/views/Formula/Template2/LYC.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "淋巴细胞绝对计数(LYC)",
    },
  },
  {
    path: "/formula/GHETNNJE",
    name: "untilDimer",
    component: () => import("@/views/Formula/Template2/D-Dimer.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "D-二聚体",
    },
  },
  {
    path: "/formula/BEGGKPPE",
    name: "untilANC",
    component: () => import("@/views/Formula/Template2/ANC.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "中性粒细胞绝对值",
    },
  },
  {
    path: "/formula/FKMZRGHZ",
    name: "untilNEUT",
    component: () => import("@/views/Formula/Template2/NEUT.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "中性粒细胞(NEUT)",
    },
  },
  {
    path: "/formula/KETJSKTU",
    name: "untilPa",
    component: () => import("@/views/Formula/Template2/Pa.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "压力",
    },
  },
  {
    path: "/formula/BYMNEUYM",
    name: "untilPLt",
    component: () => import("@/views/Formula/Template2/PLT.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "血小板计数(PLT)",
    },
  },
  {
    path: "/formula/DFIYTWMR",
    name: "untilPLt",
    component: () => import("@/views/Formula/Template2/HGB.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "血红蛋白(HGB)",
    },
  },
  {
    path: "/formula/EOOLKTKT",
    name: "untilPLt",
    component: () => import("@/views/Formula/Template2/FIB.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "纤维蛋白原(FIB)",
    },
  },
  {
    path: "/formula/LHZGKACH",
    name: "PreventingAtrialFibrillation",
    component: () =>
      import("@/views/Formula/Template3/PreventingAtrialFibrillation.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "CHADS2评分",
    },
  },
  {
    path: "/formula/EJEDCCEP",
    name: "CHADS2ScoreForStrokeRisk",
    component: () =>
      import("@/views/Formula/Template3/CHADS2ScoreForStrokeRisk.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "心房纤颤卒中风险的CHADS2评分",
    },
  },
  {
    path: "/formula/KLCSEKGS",
    name: "untilPLt",
    component: () => import("@/views/Formula/Template2/HCO3.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "碳酸氢盐(HCO3-)",
    },
  },
  {
    path: "/formula/OMWZKALO",
    name: "untilPLt",
    component: () => import("@/views/Formula/Template2/PAB.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "前白蛋白(PA)",
    },
  },
  {
    path: "/formula/UZNWZBHP",
    name: "untilPLt",
    component: () => import("@/views/Formula/Template2/LAC.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "乳酸(LAC)",
    },
  },
  {
    path: "/formula/ZVLRIFNX",
    name: "ASAClassification",
    component: () => import("@/views/Formula/Template3/ASAClassification.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "ASA分级(术前风险评估)",
    },
  },
  {
    path: "/formula/NLAVVEYG",
    name: "CormackClassification",
    component: () =>
      import("@/views/Formula/Template3/CormackClassification.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "Cormack-Lehane分级",
    },
  },
  {
    path: "/formula/UXUZWJYR",
    name: "CRB65Score",
    component: () => import("@/views/Formula/Template3/CRB65Score.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "CRB-65评分",
    },
  },
  {
    path: "/formula/NQTIURQU",
    name: "CURB65Score",
    component: () => import("@/views/Formula/Template3/CURB65Score.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "社区获得性肺炎CURB-65评分",
    },
  },
  {
    path: "/formula/OOWCARQK",
    name: "TransienceCerebralIschemia",
    component: () =>
      import("@/views/Formula/Template3/TransienceCerebralIschemia.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "ABCD3-I评分",
    },
  },
  {
    path: "/formula/HEJOGHXK",
    name: "TransienceCerebralIschemiaSystem",
    component: () =>
      import("@/views/Formula/Template3/TransienceCerebralIschemiaSystem.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "ABCD评分系统",
    },
  },
  {
    path: "/formula/RZATEWRN",
    name: "RudkinCalmScore",
    component: () => import("@/views/Formula/Template3/RudkinCalmScore.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "Rudkin镇静评分",
    },
  },
  {
    path: "/formula/JIXMAFIQ",
    name: "RespiratorPneumonia",
    component: () =>
      import("@/views/Formula/Template3/RespiratorPneumonia.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "VAP PIRO评分",
    },
  },
  {
    path: "/formula/HYPPBCYO",
    name: "AcutePancreatiti",
    component: () => import("@/views/Formula/Template3/AcutePancreatiti.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "BISAP评分",
    },
  },
  {
    path: "/formula/WUNWQUTK",
    name: "GenevaScore",
    component: () => import("@/views/Formula/Template5/GenevaScore.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "Geneva肺栓塞评分",
    },
  },
  {
    path: "/formula/CSTOUZCX",
    name: "SpeedinessApparatusFailure",
    component: () =>
      import("@/views/Formula/Template3/SpeedinessApparatusFailure.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "快速序贯器官衰竭评估（qSOFA）评分",
    },
  },
  {
    path: "/formula/JLONMZWL",
    name: "STTIMIRiskScore",
    component: () => import("@/views/Formula/Template3/STTIMIRiskScore.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "ST段抬高型心肌梗死的TIMI危险评分",
    },
  },
  {
    path: "/formula/IWLJMAKQ",
    name: "GRACEIschemiaAssess",
    component: () =>
      import("@/views/Formula/Template5/GRACEIschemiaAssess.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "GRACE缺血风险评估",
    },
  },
  {
    path: "/formula/NWGOSNLV",
    name: "ShouldCTScansBePerformed",
    component: () =>
      import("@/views/Formula/Template3/ShouldCTScansBePerformed.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "CATCH规则",
    },
  },
  {
    path: "/formula/HIPNKNEJ",
    name: "untilPCT",
    component: () => import("@/views/Formula/Template2/PCT.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "降钙素原(PCT)	",
    },
  },
  {
    path: "/formula/IAAKUGLA",
    name: "untilMB",
    component: () => import("@/views/Formula/Template2/MB.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "肌红蛋白",
    },
  },
  {
    path: "/formula/UTHMITNL",
    name: "untilCR",
    component: () => import("@/views/Formula/Template2/Cr.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "肌酐(Cr)",
    },
  },
  {
    path: "/formula/NHWVDBCS",
    name: "untilAPTT",
    component: () => import("@/views/Formula/Template2/APTT.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "活化部分凝血活酶时间(APTT)",
    },
  },
  {
    path: "/formula/HSUATTAS",
    name: "AdultCommunityAcquired",
    component: () =>
      import("@/views/Formula/Template3/AdultCommunityAcquired.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "成人社区获得性肺炎严重性评分(PSI)",
    },
  },
  {
    path: "/formula/BBVHJCUT",
    name: "TraumaSeverityScore",
    component: () =>
      import("@/views/Formula/Template4/TraumaSeverityScore.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "创伤严重程度评分(ISS)",
    },
  },
  {
    path: "/formula/YYKNMHLO",
    name: "TraumaSeverityScore",
    component: () => import("@/views/Formula/Template3/HestiaStandard.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "Hestia标准",
    },
  },
  {
    path: "/formula/VPGLGVAD",
    name: "SimplifiedPESI",
    component: () => import("@/views/Formula/Template3/SimplifiedPESI.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "简化PESI评分",
    },
  },
  {
    path: "/formula/FCMLNYSN",
    name: "AcuteAppendicitisScore",
    component: () =>
      import("@/views/Formula/Template3/AcuteAppendicitisScore.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "急性阑尾炎Alvarado评分",
    },
  },
  {
    path: "/formula/WPFFGHJZ",
    name: "SanFranciscoSyncopeSevere",
    component: () =>
      import("@/views/Formula/Template3/SanFranciscoSyncopeSevere.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "旧金山晕厥规则(SFSR)",
    },
  },
  {
    path: "/formula/MASWEVJC",
    name: "NewOrleansHeadTraumaRules",
    component: () =>
      import("@/views/Formula/Template3/NewOrleansHeadTraumaRules.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "新奥尔良头部创伤规则(NOC)",
    },
  },
  {
    path: "/formula/FNGUHRJY",
    name: "WELLSDVT",
    component: () => import("@/views/Formula/Template3/WELLSDVT.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "Wells评分(DVT)",
    },
  },
  {
    path: "/formula/KKSIPXDU",
    name: "WELLDPE",
    component: () => import("@/views/Formula/Template3/WELLDPE.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "Wells评分(PE)",
    },
  },
  {
    path: "/formula/LENJSXJH",
    name: "CRMASRating",
    component: () => import("@/views/Formula/Template5/CRMASRating.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "CRMAS评分",
    },
  },
  {
    path: "/formula/EVGQUQQZ",
    name: "DiagnosticRulesForOttawa",
    component: () =>
      import("@/views/Formula/Template5/DiagnosticRulesForOttawa.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "Ottawa踝关节骨折诊断规则",
    },
  },
  {
    path: "/formula/NFWBBUSU",
    name: "PreventingAtrialFibrillation",
    component: () => import("@/views/Formula/Template3/SIRS.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "SIRS诊断标准",
    },
  },
  {
    path: "/formula/WBZKCTMM",
    name: "PreventingAtrialFibrillation",
    component: () => import("@/views/Formula/Template3/ROSIER.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "ROSIER分级",
    },
  },
  {
    path: "/formula/RMOLGNFO",
    name: "FLACCBehaviorRating",
    component: () =>
      import("@/views/Formula/Template5/FLACCBehaviorRating.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "FLACC疼痛评估量表",
    },
  },
  {
    path: "/formula/XEFTAJRQ",
    name: "GlasgowComaSCore",
    component: () => import("@/views/Formula/Template5/GlasgowComaScore.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "Glasgow昏迷评分(GCS)",
    },
  },
  {
    path: "/formula/UFPMRMOD",
    name: "FOURComaScore",
    component: () => import("@/views/Formula/Template5/FOURComaScore.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "FOUR昏迷评分",
    },
  },
  {
    path: "/formula/MBIYDNNO",
    name: "RAPSComaSCore",
    component: () => import("@/views/Formula/Template5/RAPSComaScore.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "快速急诊内科评分(REMS)",
    },
  },
  {
    path: "/formula/IDWMEXVT",
    name: "FENa",
    component: () => import("../views/Formula/Template1/FENa.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "钠排泄分数",
    },
  },
  {
    path: "/formula/QZOQTSJZ", // 补液公式
    name: "WVUH",
    component: () => import("../views/Formula/Template5/WVUH.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "美国西弗吉尼亚大学医学院（WVUH）深部真菌感染评分",
    },
  },
  {
    path: "/formula/GFEMRFMJ", // 补液公式
    name: "GFEMRFMJ",
    component: () => import("../views/Formula/Template5/GFEMRFMJ.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "中国DIC诊断评分系统",
    },
  },
  {
    path: "/formula/QBFMIBRU",
    name: "PainAssessmentInPediatricPatients",
    component: () =>
      import(
        "../views/Formula/Template6/PainAssessmentInPediatricPatients.vue"
      ),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "Comfort疼痛评分",
    },
  },
  {
    path: "/formula/HIAZJJOO",
    name: "AcuteLowerLimbIschemiaGrading",
    component: () =>
      import("../views/Formula/Template7/AcuteLowerLimbIschemiaGrading.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "急性下肢缺血分级",
    },
  },
  {
    path: "/formula/RJGSZAJL",
    name: "AcuteBotanyHost",
    component: () => import("../views/Formula/Template7/AcuteBotanyHost.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "急性移植物抗宿主反应(GVHD)分级",
    },
  },
  {
    path: "/formula/QKBUAUYN",
    name: "MEWS",
    component: () => import("../views/Formula/Template5/MEWS.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "早期预警评分(MEWS)",
    },
  },
  {
    path: "/formula/RTHWDQTU",
    name: "MEWS",
    component: () => import("../views/Formula/Template5/CPIS.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "临床肺部感染(CPIS)评分",
    },
  },
  {
    path: "/formula/FOZUFDSB",
    name: "MEWS",
    component: () => import("../views/Formula/Template5/SAPS.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "简化急性生理评分（SAPS）Ⅱ",
    },
  },
  {
    path: "/formula/UOHVWIGJ",
    name: "MorseFallRiskAssessmentScale",
    component: () =>
      import("../views/Formula/Template5/MorseFallRiskAssessmentScale.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "Morse 跌倒风险评估量表",
    },
  },
  {
    path: "/formula/NJJGSZOQ",
    name: "PECARNAlgorithm",
    component: () => import("../views/Formula/Template3/PECARNAlgorithm.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "PECARN算法",
    },
  },
  {
    path: "/formula/ZXMAIHOK", // 补液公式
    name: "Rockall",
    component: () => import("../views/Formula/Template5/Rockall.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "急性上消化道出血Rockall评分",
    },
  },
  {
    path: "/formula/POFACADK", // 补液公式
    name: "Ranson",
    component: () => import("../views/Formula/Template5/Ranson.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "急性胰腺炎评分Ranson标准",
    },
  },
  {
    path: "/formula/USFWVKBU", // 急性胰腺炎的CT严重程度评分(CTSI)
    name: "USFWVKBU",
    component: () => import("../views/Formula/Template5/USFWVKBU.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "急性胰腺炎的CT严重程度评分(CTSI)",
    },
  },
  {
    path: "/formula/TTLRBUNA", // 创伤CRAMS计分法
    name: "TTLRBUNA",
    component: () => import("../views/Formula/Template5/TTLRBUNA.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "创伤CRAMS计分法",
    },
  },
  {
    path: "/formula/OGDHLPWN", // WHO肺动脉高压功能分级
    name: "OGDHLPWN",
    component: () => import("../views/Formula/Template5/OGDHLPWN.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "WHO肺动脉高压功能分级",
    },
  },
  {
    path: "/formula/YSJVOFYC", // Goldman 多因素心脏危险指数(CRI)
    name: "YSJVOFYC",
    component: () => import("../views/Formula/Template5/YSJVOFYC.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "Goldman 多因素心脏危险指数(CRI)",
    },
  },
  {
    path: "/formula/GPJSSZLR", // NYHA心功能分级	
    name: "GPJSSZLR",
    component: () => import("../views/Formula/Template5/GPJSSZLR.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "NYHA心功能分级",
    },
  },
  {
    path: "/formula/IRSSNFEF",
    name: "EnteralNutrition",
    component: () => import("../views/Formula/Template5/EnteralNutrition.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "肠内营养耐受性评分表",
    },
  },
  {
    path: "/formula/BDUPGHDR",
    name: "BDUPGHDR",
    component: () => import("../views/Formula/Template5/BDUPGHDR.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "伯奇-沃托斯基点量表(BWPS)",
    },
  },
  {
    path: "/formula/QRQEADUK",
    name: "QRQEADUK",
    component: () => import("../views/Formula/Template5/QRQEADUK.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "APACHE Ⅱ评分",
    },
  },
  {
    path: "/formula/JJTPQLXO",
    name: "JJTPQLXO",
    component: () => import("../views/Formula/Template5/JJTPQLXO.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "ISTH评分标准",
    },
  },
  {
    path: "/formula/ZQXCUBXQ",
    name: "PatientPressureUlcerAssessment",
    component: () =>
      import("../views/Formula/Template5/PatientPressureUlcerAssessment.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "Braden压疮评分表",
    },
  },
  {
    path: "/formula/DGYGKPNN",
    name: "GOLD",
    component: () => import("../views/Formula/Template3/GOLD.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "GOLD肺功能分级",
    },
  },
  {
    path: "/formula/CKMRFMQI",
    name: "PHI",
    component: () => import("@/views/Formula/Template5/PHI.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "院前指数评估(PHI)",
    },
  },
  {
    path: "/formula/QKYRQIJF",
    name: "IntensiveCareDeliriumScreeningScale",
    component: () =>
      import(
        "@/views/Formula/Template5/IntensiveCareDeliriumScreeningScale.vue"
      ),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "重症监护谵妄筛查量表（ICDSC）",
    },
  },
  {
    path: "/formula/OEYYMGFA",
    name: "untilCRP",
    component: () => import("@/views/Formula/Template2/CRP.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "C-反应蛋白(CRP)",
    },
  },
  {
    path: "/formula/AKZZIMPE",
    name: "untilDFM",
    component: () => import("@/views/Formula/Template2/DFM.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "淀粉酶",
    },
  },
  {
    path: "/formula/DBJYTSIO",
    name: "untilZFM",
    component: () => import("@/views/Formula/Template2/ZFM.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "脂肪酶",
    },
  },
  {
    path: "/formula/ZRGOTKGA",
    name: "untilZJDHS",
    component: () => import("@/views/Formula/Template2/ZJDHS.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "直接胆红素",
    },
  },
  {
    path: "/formula/LUIJZCBU",
    name: "untilZDHS",
    component: () => import("@/views/Formula/Template2/ZDHS.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "总胆红素",
    },
  },
  {
    path: "/formula/HUKNGXWR",
    name: "untilBDB",
    component: () => import("@/views/Formula/Template2/BDB.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "白蛋白",
    },
  },
  {
    path: "/formula/NGJHYDWO",
    name: "untilYDFM",
    component: () => import("@/views/Formula/Template2/YDFM.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "胰淀粉酶",
    },
  },
  {
    path: "/formula/NHHNFHVI",
    name: "untilBXBJS",
    component: () => import("@/views/Formula/Template2/BXBJS.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "白细胞介素-6(IL-6)",
    },
  },
  {
    path: "/formula/EBWLTCEC",
    name: "untilDHXB",
    component: () => import("@/views/Formula/Template2/DHXB.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "单核细胞",
    },
  },
  {
    path: "/formula/UIRYDBSM",
    name: "untilDHXBJS",
    component: () => import("@/views/Formula/Template2/DHXBJS.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
      title: "单核细胞计数",
    },
  },

  //进修招聘-首页
  {
    path: "/recruitmentIndex",
    name: "RecruitmentIndex",
    component: () => import("@/views/ContinuingEducationRecruitment/Index.vue"),
    meta: {
      keepAlive: true, //此组件不需要被缓存
      title: "进修招聘",
    },
  },
  //进修招聘-内容详情首页
  {
    path: "/recruitmentDetails",
    name: "recruitmentDetails",
    component: () =>
      import("@/views/ContinuingEducationRecruitment/Details.vue"),
    meta: {
      keepAlive: false, //此组件不需要被缓存
    },
  },
  //进修招聘-搜索页
  {
    path: "/recruitmentSearch",
    name: "recruitmentSearch",
    component: () =>
      import("@/views/ContinuingEducationRecruitment/Search.vue"),
    meta: {
      keepAlive: true, //此组件不需要被缓存
    },
  },
];

const router = new VueRouter({
  
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

export default router;

// router.beforeEach((to,from,next)=>{
//   next(-1);
// })
