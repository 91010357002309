<template>
  <div class="area">
    <div class="area-all">

      <!--    注册量日-->
      <div class="area-day">
        <div class="area-day-font">注册量日</div>
        <!--      时间选择器-->
        <div class="area-day-time">
          <div class="area-day-time-one">
            <el-date-picker
                style="width: 95%"
                v-model="value1"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="开始时间"
            >
            </el-date-picker>
          </div>
          <div class="area-day-time-one-heng">-</div>
          <div class="area-day-time-one">
            <el-date-picker
                style="width: 95%"
                v-model="value2"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="结束时间"
                @blur="lose2">
            </el-date-picker>
          </div>
        </div>
        <!--      折线图-->
        <div class="area-day-zhe" id="dayZhe"></div>
      </div>
      <!--    注册量月-->
      <div class="area-month">
        <div class="area-month-font">注册量月</div>
        <!--      时间选择器-->
        <div class="area-month-time">
          <div class="area-month-time-one">
            <el-date-picker
                style="width: 95%"
                v-model="value3"
                type="year"
                format="yyyy"
                value-format="yyyy"
                placeholder="请选择年"
            >
            </el-date-picker>
          </div>
        </div>
        <!--      折线图-->
        <div  id="monthZhe" style="height: 300px;width: 100%"></div>
      </div>
      <!--    注册量年-->
      <div class="area-year">
        <div class="area-year-font">注册量年</div>

        <!--        &lt;!&ndash;      时间选择器&ndash;&gt;-->
        <!--        <div class="area-year-time">-->
        <!--          <div class="area-year-time-one">-->
        <!--            <el-date-picker-->
        <!--                style="width: 90%"-->
        <!--                v-model="value4"-->
        <!--                type="date"-->
        <!--                format="yyyy-MM-dd"-->
        <!--                value-format="yyyy-MM-dd"-->
        <!--                placeholder="请选择时间"-->
        <!--            >-->
        <!--            </el-date-picker>-->
        <!--          </div>-->
        <!--        </div>-->


        <!--      折线图-->
        <div  id="yearZhe" style="height: 300px;width: 100%"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {getSurveyDay, getSurveyMonth, getSurveyYear} from "../service/api";
import moment from "moment";

export default {
  data(){
    return{
      value1:'',
      value2:'',
      value3:'',
      value4:'',
      //折线图日
      surveyDay:[],
      //折线图月
      surveyMonth:[],
      surveyYear:[],
      ago:'',
      ago1:'',
      year1:''
    }
  },
  watch:{
    async value1(newVal){
      if(newVal !== '' && newVal !== undefined && newVal !== null &&
          this.value2 !== '' && this.value2 !== undefined && this.value2 !== null &&
          this.value2 > newVal){
        const start_time = newVal
        const end_time = this.value2
        const surveyDays = await getSurveyDay(start_time,end_time)
        if(surveyDays.code !== 0){
          console.log('code不为零')
        }
        this.surveyDay = surveyDays.data
        //折线图写在接口下面，若写在mounted中，数据还没有加载出来，无法获取到数据
        this.rotationDay()
      }
    },
    async value2(newVal){
      if(newVal !== '' && newVal !== undefined && newVal !== null &&
          this.value1 !== '' && this.value1 !== undefined && this.value1 !== null &&
          this.value1 < newVal){
        const start_time = this.value1
        const end_time = newVal
        const surveyDays = await getSurveyDay(start_time,end_time)
        if(surveyDays.code !== 0){
          console.log('code不为零')
        }
        this.surveyDay = surveyDays.data
        //折线图写在接口下面，若写在mounted中，数据还没有加载出来，无法获取到数据
        this.rotationDay()
      }
    },
    async value3(newVal){
      if(newVal !== '' && newVal !== undefined && newVal !== null){
        const year = newVal
        const surveyMonths = await getSurveyMonth(year)
        if(surveyMonths.code !== 0){
          console.log('code不为零')
        }
        this.surveyMonth = surveyMonths.data
        //折线图写在接口下面，若写在mounted中，数据还没有加载出来，无法获取到数据
        this.rotations()
      }
    }
  },
  mounted() {
    this.day()
    this.month()
    this.year()
  },
  methods:{
    //折线图日
    async day(){
      this.getTime()
      const start_time = this.ago
      const end_time = this.ago1
      const surveyDays = await getSurveyDay(start_time,end_time)
      if(surveyDays.code !== 0){
        console.log('code不为零')
      }
      this.surveyDay = surveyDays.data
      //折线图写在接口下面，若写在mounted中，数据还没有加载出来，无法获取到数据
      this.rotationDay()
    },
    rotationDay(){
      let drawLine = this.$echarts.init(document.getElementById('dayZhe'))
      let option = {
        legend: {
        },
        tooltip: {},
        dataset: {
          source:this.surveyDay
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate:60,
          }
        },
        yAxis: {
          type: 'value',
        },
        series:[
          {
            name:'注册量',
            type:'line',
            smooth: true,
          }
        ]
      };

      drawLine.setOption(option);
    },
    //折线图月
    async month(){
      this.getTime()
      const year = this.year1
      const surveyMonths = await getSurveyMonth(year)
      if(surveyMonths.code !== 0){
        console.log('code不为零')
      }
      this.surveyMonth = surveyMonths.data
      //折线图写在接口下面，若写在mounted中，数据还没有加载出来，无法获取到数据
      this.rotations()
    },
    rotations() {
      let drawLine = this.$echarts.init(document.getElementById('monthZhe'))
      let option = {
        legend: {
        },
        tooltip: {},
        dataset: {
          source:this.surveyMonth
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate:60,
          }
        },
        yAxis: {
          type: 'value',
        },
        //设置y轴的文字显示完整
        grid:{
          left:'1%',
          right:'1%',
          bottom:'5%',
          containLabel:true,
        },
        series:[
          {
            name:'注册量',
            type:'line',
            smooth: true,
          }
        ]
      };

      drawLine.setOption(option);
    },
    //折线图年
    async year(){
      const surveyYears = await getSurveyYear()
      if(surveyYears.code !== 0){
        console.log('code不为零')
      }
      this.surveyYear = surveyYears.data
      //折线图写在接口下面，若写在mounted中，数据还没有加载出来，无法获取到数据
      this.rotation()
    },
    rotation(){
      let drawLine = this.$echarts.init(document.getElementById('yearZhe'))
      let option = {
        legend: {
        },
        tooltip: {},
        dataset: {
          //surveyYear第一个里面year没有，所以显示不出来
          source:this.surveyYear
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate:60,
          }
        },
        yAxis: {
          type: 'value',
        },
        grid:{
          left:'2%',
          right:'2%',
          bottom:'5%',
          containLabel:true,
        },
        series:[
          {
            name:'注册量',
            type:'line',
            smooth: true,
          }
        ]
      };

      drawLine.setOption(option);
    },
    lose2(){
      if(this.value2 < this.value1){
        alert('请选择有效日期！')
        this.value2 = ''
      }
    },
    //  获取时间
    getTime(){
      //过去七天
      this.ago = moment().subtract(7,'days').format('YYYY-MM-DD')
      //过去一天
      this.ago1 = moment().subtract(1,'days').format('YYYY-MM-DD')
      //获取今年
      this.year1 = moment().format('YYYY')
    }
  },

}
</script>
<style lang="scss" scoped>
.area{
  width: 100%;
  height: auto;
  .area-all{
    width: 94%;
    height: auto;
    margin: 15px auto;
    .area-day{
      width: 100%;
      height: auto;
      .area-day-font{
        width: 100%;
        color: #45A5E6;
        font-size: 14px;
      }
      .area-day-time{
        width: 100%;
        height: auto;
        display: flex;
        margin-top: 10px;
        .area-day-time-one{
          width: 48%;
        }
        .area-day-time-one-heng{
          width: 4%;
          margin-right: 3px;
        }
      }
      .area-day-zhe{
        width: 100%;
        height: 300px;
      }
    }
    .area-month{
      width: 100%;
      height: auto;
      margin-top: 10px;
      .area-month-font{
        width: 100%;
        color: #45A5E6;
        font-size: 14px;
      }
      .area-month-time{
        width: 100%;
        height: auto;
        display: flex;
        margin-top: 10px;
        .area-month-time-one{
          width: 48%;
        }
      }
      .area-month-zhe{
        width: 100%;
        height: 300px;
      }
    }
    .area-year{
      width: 100%;
      height: auto;
      .area-year-font{
        width: 100%;
        color: #45A5E6;
        font-size: 14px;
      }
      .area-year-time{
        width: 100%;
        height: auto;
        display: flex;
        margin-top: 10px;
        .area-year-time-one{
          width: 48%;
        }
      }
    }
  }
}
</style>