<template>
  <div class="body1">
    <!-- tab栏 -->
    <div class="tab">
      <van-icon name="arrow-left" style="float: left;" @click="onClickLeft()"/> 
      <div style="">
        <div>
            发送到邮箱
        </div>
      </div>
      <van-icon name="arrow-left" class="font"/>
    </div>
    <!-- 大盒子 -->
    <div class="EmailBox">
        <!-- 我的邮箱 -->
      <div class="myEmail" style="position: relative;">
        <!-- <van-loading size="24px" vertical style="position: absolute; left:42%;top:0;z-index:999">加载中...</van-loading> -->
        <span class="emailspan">我的邮箱</span>
        <div style="width:100%;display: flex;justify-content: center;margin-bottom:7px">
          <div class="littleEmailBox">
            <van-cell-group style="border-left: 0.5px solid #ebedf0;;">
              <van-form @submit="onSubmit" style="display: flex;">
                <van-field :rules="[{ required: true, message: '请填写您的邮箱' },{ pattern: /^[A-Za-z0-9]+([._\\-]*[A-Za-z0-9])*@([A-Za-z0-9]+[-A-Za-z0-9]*[A-Za-z0-9]+.){1,63}[A-Za-z0-9]+$/, message: '邮箱格式错误'}]" v-model="value" placeholder="请输入" />
                <van-button type="info" native-type="submit" :disabled="this.btnType" style="width:120px">
                  <span v-if="this.btnType == true">发送中...</span>
                  <span v-if="this.btnType == false">确认发送</span>
                </van-button>
              </van-form>
            </van-cell-group>
            <!-- <van-button round block type="info" native-type="submit">提交</van-button>  @click="sendEmail()" -->
          </div>
        </div>
        <span  style="font-size:10px;padding:0px 3px 0px 22px;color:">注：同一个邮箱每日最多发送10封邮件</span>
      </div>
      <!-- 发送记录 -->
      <div class="sendRecord">
        <span class="emailspan">发送记录</span>
        <div class="RecordBox" v-for="item in this.recordList" :key="item.id">
          <div class="RecordFont">
            {{ item.title }}
          </div>
          <div class="RecordTime">
            <span>
              {{ item.createdTime }}
            </span>
            <span style="color:#a3e847" v-if="item.isSuccess == 1">
              发送成功
            </span>
            <span style="color:red" v-if="item.isSuccess == 0">
              发送失败
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { recordOperation } from "@/utils/myUtils";
import { setById } from "@/service/baseDocument";
import { getAdmins } from "@/utils/adminDate";
import {
  getEmailRecord,
  postEmail,
  getEmailById,
  putEmail
} from "../service/document_api";
export default {
  data() {
    return {
      value: '',
      recordList:[],
      btnType:false,
      channel:'',
      version:'',
    };
  },
  created() {
    this.getRecord()
    this.getById()
    this.getEmail()
  },
  methods:{
    async getById() {
      //像接口头部传用户信息
      let arrays = await getAdmins(
        this.$route.query.memberId,
        this.$route.query.channel  
      );
      this.channel = arrays.os;  // 系统
      this.version = arrays.version;  //版本
      // this.ip = arrays.ip;
      await setById(arrays);
    },
    // 返回上一页
    onClickLeft() {
      this.$router.go(-1);
    },
    async getRecord(){
      const data={
        memberId: this.$route.query.memberId,//用户id
        pageNo: 1,
        pageSize: 999,//收藏类型，文献指南，DOCUMENT
      }
      const response = await getEmailRecord(data)
      if (response.code === 0) {  
        // this.$message.success('操作成功')
        // this.collectionType = true
        this.recordList = response.data
        // console.log("发送记录",this.recordList)
      } else {
        this.$message.warning(response.message)
      }
    },
    async onSubmit() {
      //校验更改邮箱
      const resEmail = await getEmailById()
      if(resEmail.data.email !== this.value){
        // 如果不一样就更改邮箱
        const data = {
          id:this.$route.query.memberId,
          email:this.value
        }
        const response = await putEmail(data)
        if (response.code === 0) {  
          const emailInfo = await getEmailById()  //修改完刷新一次
          console.log(emailInfo)
        } else {
          this.$message.warning(response.message)
        }
      }

      this.btnType = true
      const data={
        to:this.value,
        subject:"云ICU文献指南",
        text:"文献已经添加到附件中，请注意查收",
        name:this.$route.query.title,
        url: this.$route.query.url.replace("pdf/web/viewer.html?file=","")
      }
      const response = await postEmail(data)
      if (response.code === 0) {  
        this.$message.success('发送成功')
        this.btnType = false
        this.getRecord()
      } else {
        this.$message.warning(response.message)
        this.btnType = false
        this.getRecord()
      }
      // 取消了埋点  
    //   const res = await recordOperation(
    //     // this.$route.query.id,  //收藏的pdf的id 
    //     // "DOCUMENT",
    //     // "DOCUMENT_EMAIL",
    //     // this.$route.query.channel , //渠道（小程序还是app？），
    //     // this.channel, // 接口获取的系统（ios 安卓）
    //     // this.$route.query.memberId,
    //     // this.version,  //版本号
    //     this.$route.query.id,
    //     "DOCUMENT",
    //     "DOCUMENT_LIST",
    //     this.channel,
    //     this.$route.query.memberId,
    //     this.version,
    //     this.$route.query.channel
    //   );
    //   if (res.code === 0) {
    //     // console.log("埋到了")
    //   }
    },
    // 根据id获取邮箱
    async getEmail(){
      const response = await getEmailById()
      if (response.code === 0) {  
        // console.log("邮箱回显",response)
        this.value = response.data.email
      } else {
        this.$message.warning(response.message)
      }
    },
  }
}
</script>

<style>
.tab{
  width:100%;
  height: 40px;
  background-color: #fff;
  display: flex;
  justify-content:space-between;
  align-items: center;
  align-content: center;
  padding:0 15px;
  box-sizing: border-box;
}
.body1{
  background-color: #f6f6f6;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
  width:100%;
}
.font{
    color: #fff;
}
.EmailBox{
  width:100%;
  background-color: #f6f6f6;
  padding:10px 10px 0 10px;
  box-sizing: border-box;
}
.myEmail{
  background-color: #fff;
  height: 105px;
  padding:10px 15px 0 10px
}
.sendRecord{
  margin-top:8px;
  background-color: #fff;
  min-height: 100px;
  padding:10px 15px 20px 10px
}
.RecordBox{
  /* background-color: #fff; */
  padding:8px 10px 8px 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5px 3px 0px;
  border-radius: 3px;
   margin-top:10px;
}
.RecordFont{
  font-size:14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; 
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.RecordTime{
  font-size:12px;
  color:#dbd7d7;
  margin-top:8px;
  display:flex;
  justify-content: space-between;
}
.littleEmailBox{
  display: flex;
  /* margin:auto; */
  margin-top:10px;
}
.emailspan{
  margin-bottom:20px;
}
::v-deep .van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after {
    border-width: 2px 0;
}
.multi-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; 
  -webkit-box-orient: vertical;
  cursor: pointer;
}

 .van-field__error-message{
  position: absolute;
  top:33px
}
 .van-cell{
    position: relative;
    /* display: -webkit-box;
    display: -webkit-flex; */
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 16px;
    /* overflow: hidden; */
    overflow: visible !important;
    color: #323233;
    font-size: 14px;
    line-height: 24px;
    background-color: #fff;
}
</style>