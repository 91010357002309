import { postLogin } from './exam.js'
// 登录使用外包接口
export function passWordLogin(data) {
      return postLogin("/api/v1/app/login",data);
}
export function codeLogin(data) {
       return postLogin("/api/v1/app/phone_code_login",data);
}
export function getCode(data) {
      return postLogin("/api/v1/app/common/yunicu_auth", data);
}