<template>
  <div class="status">
<!--    医院-->
    <div class="Dro">
      <div class="Dro-top">
        <div class="Dro-top-left">注册人数统计(医院)</div>
        <div class="Dro-top-right">
          <select v-model="list2" style="width: 70px;height: 25px;border: #0a84ff solid 1px;color: #888888">
            <option>5</option>
            <option>10</option>
          </select>
        </div>
      </div>
<!--      图-->
      <div style="width: 100%;height: 450px" id="droTu"></div>
<!--      表格-->
      <div class="tableDro">
        <el-table
            :data="droTable"
            stripe
            style="height: auto;width: 95%;border-radius: 20px;margin: 0 auto"
        >
          <el-table-column
              prop="value"
              label="医院"
              align="center"
          ></el-table-column>
          <el-table-column
              prop="count"
              label="人数"
              align="center"
          ></el-table-column>
          <el-table-column
              label="占比"
              align="center"
          >
            <template slot-scope="scope">
              {{(Math.round((scope.row.count/sumPen1.count)*1000))/10 + '%'}}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div><br/>
<!--    科室-->
    <div class="Ke">
      <div class="Ke-top">
        <div class="Ke-top-left">注册人数统计(科室)</div>
        <div class="Ke-top-right">
          <select v-model="list3" style="width: 70px;height: 25px;border: #0a84ff solid 1px;color: #888888">
            <option>5</option>
            <option>10</option>
          </select>
        </div>
      </div>
      <!--      图-->
      <div style="width: 100%;height: 300px" id="keTu"></div>
      <!--      表格-->
      <div class="tableKe">
        <el-table
            :data="registerKe"
            stripe
            style="height: auto;width: 95%;border-radius: 20px;margin: 0 auto"
        >
          <el-table-column
              label="科室"
              prop="value"
              align="center"
          ></el-table-column>
          <el-table-column
              label="人数"
              prop="count"
              align="center"
          ></el-table-column>
          <el-table-column
              label="占比"
              align="center"
          >
            <template slot-scope="scope">
              {{(Math.round((scope.row.count/sumPen1.count)*1000))/10 + '%'}}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div><br/>
<!--    职称-->
    <div class="Zhi">
      <div class="Zhi-top">
        <div class="Zhi-top-left">注册人数统计(职称)</div>
        <div class="Zhi-top-right">
          <select v-model="list4" style="width: 70px;height: 25px;border: #0a84ff solid 1px;color: #888888">
            <option>5</option>
            <option>10</option>
          </select>
        </div>
      </div>
      <!--      图-->
      <div style="width: 100%;height: 300px" id="zhiTu"></div>
      <!--      表格-->
      <div class="tableZhi">
        <el-table
            :data="registerZhi"
            stripe
            style="height: auto;width: 95%;border-radius: 20px;margin: 0 auto"
        >
          <el-table-column
              label="职称"
              prop="value"
              align="center"
          ></el-table-column>
          <el-table-column
              label="人数"
              prop="count"
              align="center"
          ></el-table-column>
          <el-table-column
              label="占比"
              align="center"
          >
            <template slot-scope="scope">
              {{(Math.round((scope.row.count/sumPen1.count)*1000))/10 + '%'}}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
<!--    身份-->
    <div class="Shen">
      <div class="Shen-top">
        <div class="Shen-top-left">注册人数统计(身份)</div>
        <div class="Shen-top-right">
          <select v-model="list5" style="width: 70px;height: 25px;border: #0a84ff solid 1px;color: #888888">
            <option>5</option>
            <option>10</option>
          </select>
        </div>
      </div>
      <!--      图-->
      <div style="width: 100%;height: 300px" id="shenTu"></div>
      <!--      表格-->
      <div class="tableShen">
        <el-table
            :data="registerShen"
            stripe
            style="height: auto;width: 95%;border-radius: 20px;margin: 0 auto"
        >
          <el-table-column
              label="身份"
              prop="value"
              align="center"
          ></el-table-column>
          <el-table-column
              label="人数"
              prop="count"
              align="center"
          ></el-table-column>
          <el-table-column
              label="占比"
              align="center"
          >
            <template slot-scope="scope">
              {{(Math.round((scope.row.count/sumPen1.count)*1000))/10 + '%'}}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getRegisterDro,
  getRegisterDroTu,
  getRegisterKe,
  getRegisterKeTu, getRegisterShen, getRegisterShenTu,
  getRegisterSum, getRegisterZhi,
  getRegisterZhiTu
} from "../service/api";
import {ListPushQiTa} from "../utils/myUtils";

export default {
  data(){
    return{
      sumPen1:[],
      droTable:[],
      droTu:[],
      list2:'5',
      list3:'5',
      list4:'5',
      list5:'5',
      registerKeTu:[],
      registerKe:[],
      registerZhiTu:[],
      registerZhi:[],
      registerShenTu:[],
      registerShen:[],
    }
  },
  watch:{
    async list2(newVal) {
      const limit = newVal
      const droTables = await  getRegisterDro(limit)
      // this.droTables = droTables.data
      if(droTables.code === 0){
        let droTable = droTables.data
        //ListPushQiTa重复利用，直接封装了遍历数组
        let sum2 = ListPushQiTa(droTable);
        //向集合中添加其他一行
        sum2 = this.sumPen1.count - sum2
        if (sum2>0){
          //向集合中添加其他一行
          droTable.push({value:"其他",count:sum2})
        }
        this.droTable = droTable
      }
    },
    async list3(newVal){
      const limit = newVal
      const registerKes = await getRegisterKe(limit)
      // this.registerKe = registerKes.data
      if(registerKes.code === 0){
        let registerKe = registerKes.data
        //ListPushQiTa重复利用，直接封装了遍历数组
        let sum2 = ListPushQiTa(registerKe);
        //向集合中添加其他一行
        sum2 = this.sumPen1.count - sum2
        if (sum2>0){
          //向集合中添加其他一行
          registerKe.push({value:"其他",count:sum2})
        }
        this.registerKe = registerKe
      }
    },
    async list4(newVal){
      const limit = newVal
      const registerZhis = await getRegisterZhi(limit)
      // this.registerZhi = registerZhis.data
      if(registerZhis.code === 0){
        let registerZhi = registerZhis.data
        //ListPushQiTa重复利用，直接封装了遍历数组
        let sum2 = ListPushQiTa(registerZhi);
        //向集合中添加其他一行
        sum2 = this.sumPen1.count - sum2
        if (sum2>0){
          //向集合中添加其他一行
          registerZhi.push({value:"其他",count:sum2})
        }
        this.registerZhi = registerZhi
      }
    },
    async list5(newVal){
      const limit = newVal
      const registerShens = await getRegisterShen(limit)
      // this.registerShen = registerShens.data
      if(registerShens.code === 0){
        let registerShen = registerShens.data
        //ListPushQiTa重复利用，直接封装了遍历数组
        let sum2 = ListPushQiTa(registerShen);
        //向集合中添加其他一行
        sum2 = this.sumPen1.count - sum2
        if (sum2>0){
          //向集合中添加其他一行
          registerShen.push({value:"其他",count:sum2})
        }
        this.registerShen = registerShen
      }
}
  },
  mounted() {
    this.tableDro()
    this.sumPens()
    this.tuDro()
    this.tuKe()
    this.tableKe()
    this.tuZhi()
    this.tablezhi()
    this.tuShen()
    this.tableShen()
  },
  methods:{
    //注册量观看人数统计（医院）表格
    async tableDro(){
      const limit = 5
      const droTables = await  getRegisterDro(limit)
      // this.droTables = droTables.data
      if(droTables.code === 0){
        let droTable = droTables.data
        //ListPushQiTa重复利用，直接封装了遍历数组
        let sum2 = ListPushQiTa(droTable);
        //向集合中添加其他一行
        sum2 = this.sumPen1.count - sum2
        if (sum2>0){
          //向集合中添加其他一行
          droTable.push({value:"其他",count:sum2})
        }
        this.droTable = droTable
      }
    },
    //总注册量
    async sumPens(){
      const sumPens = await getRegisterSum()
      this.sumPen1 = sumPens.data[0]
    },
    //注册量观看人数统计（医院）图
    async tuDro(){
      const limit = 10
      const droTus = await getRegisterDroTu(limit)
      this.droTu = droTus.data
      this.rotationYiTu()
    },
    rotationYiTu(){
      let drawLine = this.$echarts.init(document.getElementById('droTu'))
      let option = {
        dataset:{
          source:this.droTu
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            rotate:60,
          },
        },
        yAxis: {
          type: 'value',
        },

        grid:{
          left:'2%',
          right:'2%',
          bottom:'1%',
          containLabel:true,
        },
        series:{
          type:'bar'
        }
      };

      drawLine.setOption(option);
    },

    ////注册量观看人数统计（科室）图
    async tuKe(){
      const limit = 20
      const registerKeTus = await getRegisterKeTu(limit)
      this.registerKeTu = registerKeTus.data
      this.rotationKeTu()
    },
    rotationKeTu(){
      let drawLine = this.$echarts.init(document.getElementById('keTu'))
      let option = {
        title: {
          left: 'center'
        },
        dataset: {
          source: this.registerKeTu
        },
        series: [
          {
            type: 'pie',
            radius: ['20%', '50%'],
          }
        ]
      };

      drawLine.setOption(option);
    },
    //注册量观看人数统计（科室）表格
    async tableKe(){
      const limit = 5
      const registerKes = await getRegisterKe(limit)
      // this.registerKe = registerKes.data
      if(registerKes.code === 0){
        let registerKe = registerKes.data
        //ListPushQiTa重复利用，直接封装了遍历数组
        let sum2 = ListPushQiTa(registerKe);
        //向集合中添加其他一行
        sum2 = this.sumPen1.count - sum2
        if (sum2>0){
          //向集合中添加其他一行
          registerKe.push({value:"其他",count:sum2})
        }
        this.registerKe = registerKe
      }
    },

    //注册量观看人数统计（职称）图
    async tuZhi(){
      const registerZhiTus = await getRegisterZhiTu()
      this.registerZhiTu = registerZhiTus.data
      this.rotationZhiTu()
    },
    rotationZhiTu(){
      let drawLine = this.$echarts.init(document.getElementById('zhiTu'))
      let option = {
        title: {
          left: 'center'
        },
        dataset: {
          source: this.registerZhiTu
        },
        series: [
          {
            type: 'pie',
            radius: ['20%', '50%'],
          }
        ]
      };

      drawLine.setOption(option);
    },
    //注册量观看人数统计（职称）表格
    async tablezhi(){
      const limit = 5
      const registerZhis = await getRegisterZhi(limit)
      // this.registerZhi = registerZhis.data
      if(registerZhis.code === 0){
        let registerZhi = registerZhis.data
        //ListPushQiTa重复利用，直接封装了遍历数组
        let sum2 = ListPushQiTa(registerZhi);
        //向集合中添加其他一行
        sum2 = this.sumPen1.count - sum2
        if (sum2>0){
          //向集合中添加其他一行
          registerZhi.push({value:"其他",count:sum2})
        }
        this.registerZhi = registerZhi
      }
    },

    //注册量观看人数统计（身份）图
    async tuShen(){
      const registerShenTus = await getRegisterShenTu()
      this.registerShenTu = registerShenTus.data
      this.rotationShenTu()
    },
    rotationShenTu(){
      let drawLine = this.$echarts.init(document.getElementById('shenTu'))
      let option = {
        title: {
          left: 'center'
        },
        dataset: {
          source: this.registerShenTu
        },
        series: [
          {
            type: 'pie',
            radius: ['20%', '50%'],
          }
        ]
      };

      drawLine.setOption(option);
    },
    //注册量观看人数统计（身份）表格
    async tableShen(){
      const limit = 5
      const registerShens = await getRegisterShen(limit)
      // this.registerShen = registerShens.data
      if(registerShens.code === 0){
        let registerShen = registerShens.data
        //ListPushQiTa重复利用，直接封装了遍历数组
        let sum2 = ListPushQiTa(registerShen);
        //向集合中添加其他一行
        sum2 = this.sumPen1.count - sum2
        if (sum2>0){
          //向集合中添加其他一行
          registerShen.push({value:"其他",count:sum2})
        }
        this.registerShen = registerShen
      }
    }

  }
}
</script>

<style lang="scss" scoped>
  .status{
    width: 100%;
    height: auto;
    .Dro{
      width: 100%;
      height: auto;
      .Dro-top{
        display: flex;
        height: auto;
        .Dro-top-left{
          width: 100%;
          height: 50px;
          line-height: 50px;
          margin-left: 20px;
          color: #45A5E6;
          font-size: 14px;
        }
        .Dro-top-right{
          margin: 15px;
        }
      }
    }
    .Ke{
      width: 100%;
      height: auto;
      .Ke-top{
        display: flex;
        height: auto;
        .Ke-top-left{
          width: 100%;
          height: 50px;
          line-height: 50px;
          margin-left: 20px;
          color: #45A5E6;
          font-size: 14px;
        }
        .Ke-top-right{
          margin: 15px;
        }
      }
    }
    .Zhi{
      width: 100%;
      height: auto;
      .Zhi-top{
        display: flex;
        height: auto;
        .Zhi-top-left{
          width: 100%;
          height: 50px;
          line-height: 50px;
          margin-left: 20px;
          color: #45A5E6;
          font-size: 14px;
        }
        .Zhi-top-right{
          margin: 15px;
        }
      }
    }
    .Shen{
      width: 100%;
      height: auto;
      .Shen-top{
        display: flex;
        height: auto;
        .Shen-top-left{
          width: 100%;
          height: 50px;
          line-height: 50px;
          margin-left: 20px;
          color: #45A5E6;
          font-size: 14px;
        }
        .Shen-top-right{
          margin: 15px;
        }
      }
    }
  }
</style>