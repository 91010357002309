import  {get} from './base'

// 医学杂志数据分析

export function getHomeAccess(){
    return get('/v2/queryHomeList')
}
export  function getContentAccess(){
    return get('/v2/queryContentList')
}
export  function getHomeToday(){
    return get('/v2/queryHometodayList')
}
export  function getContentToday(){
    return get('/v2/queryContentTodayList')
}
export  function getContentTopTen(){
    return get('/v2/queryContentTopTen')
}
export  function getDayTime(data){
    return get('/v2/queryDateManTime',data)
}
export function getDayNumberTime(data){
    return get('/v2/queryDateManTimeCount',data)
}
export function getMoonTime(data){
    return get('/v2/queryMouthManTime',data)
}
export function getMoonNumberTime(data){
    return get('/v2/queryMouthCount',data)
}